import React from "react";
import { Badge } from "reactstrap";

const MealPlanStatusBadge = (props) => {
  const { status } = props;

  let badgeColor = "primary";
  switch (status) {
    case "draft":
    case "archived":
    case "deleted":
    case "deep_archive":
      badgeColor = "secondary";
      break;
    case "awaiting_publish":
      badgeColor = "warning";
      break;
    case "error":
      badgeColor = "danger";
      break;
    case "generating":
      badgeColor = "info";
      break;
  }

  return <Badge color={badgeColor}>{status}</Badge>;
};

export default MealPlanStatusBadge;
