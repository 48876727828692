/**
 * Given a accessKey prop, validate the user and eligibility for a sample on the backend to make sure it's valid.
 *
 * Renders a loading icon while loading
 * Renders an error message if user is not valid.
 * Renders children if valid.
 */
import React, { useState, useEffect } from "react";

import { Loading } from "common";

import Backend from "util/Backend";

const ValidateUser = (props) => {
  const { accessKey } = props;

  const [loading, setLoading] = useState(true);
  const [validity, setValidity] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const load = async () => {
      let response;
      try {
        response = await Backend.post("users/validate_access", {
          access_key: accessKey
        });
      } catch (e) {
        setValidity({ user: false });
        setError(true);
        setLoading(false);
        return;
      }
      const obj = {
        user_valid: response.data.user_valid,
        user_id: response.data.user_id,
        email: response.data.email
      };
      setValidity(obj);
      props.onValidate(obj);
      setLoading(false);
    };
    load();
  }, []);

  if (loading) {
    return (
      <center>
        <Loading />
      </center>
    );
  }

  if (!validity.user_valid) {
    return <p>Invalid access link.</p>;
  }

  if (error) {
    return <p>An error has occurred.</p>;
  }

  return props.children({ email: validity.email });
};

export default ValidateUser;
