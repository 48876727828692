import React, { useState } from "react";

import { Alert } from "reactstrap";
import { Loading } from "common";

import User from "api/User";
import Audit from "api/Audit";

const SetDefaultPassword = props => {
  const { user, partner, zendeskClient } = props;

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);

  if (success === true) {
    return (
      <Alert color="success" className="p-1 m-0">
        Password reset success!
      </Alert>
    );
  }

  return (
    <>
      {success === false ? (
        <Alert color="danger" className="p-1 m-0">
          Error resetting password
        </Alert>
      ) : null}
      <small>
        <a
          href="#"
          onClick={async e => {
            e.preventDefault();
            if (loading) {
              return;
            }

            setLoading(true);

            const userObj = new User(user.id);
            try {
              await userObj.resetPasswordToDefault();

              new Audit("client", user.id).event("Password reset to default");

              setSuccess(true);
            } catch (e) {
              setSuccess(false);
            }

            setLoading(false);
          }}
        >
          Reset Password
        </a>
      </small>
      {loading ? (
        <Loading size={12} style={{ display: "inline-block", marginLeft: 5 }} />
      ) : null}
    </>
  );
};

export default SetDefaultPassword;
