/**
 * Allow users to onboard without authentication.
 * They must already have an account, this requires a user_id
 *
 * Query params:
 * - key - the "noauth_access_key" for the user.
 * - action - string - action identifier. used to display different success messages and actions.
 *    - s - sample plan request
 *    - p - profile update
 *    - e - exclusions update
 * - len - string - when action is sample plan, the length of the sample plan to create.
 */

import React, { useState } from "react";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Alert
} from "reactstrap";

import queryString from "qs";

import { Template, Loading } from "common";

import Backend from "util/Backend";

import StartCard from "./StartCard";
import ProfileCard from "./ProfileCard";
import ExclusionsCard from "./ExclusionsCard";

import CompleteCard from "./CompleteCard";

const VALID_ACTION_CODES = ["s", "p", "e"];

const NoAuthOnboarding = (props) => {
  const [step, setStep] = useState(null);
  const [saving, setSaving] = useState(false);
  const [complete, setComplete] = useState(false);

  const [exclusions, setExclusions] = useState([]);
  const [profile, setProfile] = useState({});

  //const [userId, setUserId] = useState(null);

  // accessKey is required.
  let accessKey = null;
  const { search } = props.location;
  if (!search) {
    return <p>Error.</p>;
  }

  let query = queryString.parse(search, { ignoreQueryPrefix: true });
  // Call it "id" rather than user_id.
  if (query.key) {
    accessKey = query.key;
  }

  const actions = query.action.split("");
  let allActionsValid = true;
  actions.forEach((action) => {
    if (VALID_ACTION_CODES.includes(action) === false) {
      allActionsValid = false;
    }
  });

  // Called when a everything is complete.
  const onComplete = async () => {
    setSaving(true);

    /**
     * Create a sample.
     */
    const createSample = async () => {
      let response = await Backend.post("meal_plans/sample/create", {
        access_key: accessKey,
        ...profile,
        exclusions,
        length: query.len
      });
    };

    const updateUser = async () => {
      let updateObj = {};

      if (actions.includes("p")) {
        updateObj = {
          ...updateObj,
          ...profile // {profile, measurements}
        };
      }
      if (actions.includes("e")) {
        updateObj = {
          ...updateObj,
          exclusions
        };
      }

      await Backend.post("users/noauth_update", {
        access_key: accessKey,
        ...updateObj
      });
    };

    await updateUser();

    if (actions.includes("s")) {
      await createSample();
    }

    setComplete(true);
    setSaving(false);
  };

  // Default render is "no user-Id found"
  let render = (
    <>
      <center>
        <p>Access link is invalid.</p>
      </center>
    </>
  );

  if (accessKey && allActionsValid) {
    // Render the onboarding.
    if (step === null) {
      render = (
        <StartCard
          accessKey={accessKey}
          actions={actions}
          onNextStep={() => {
            // Sample plan exclusions > profile > complete
            if (actions.includes("s")) {
              setStep("exclusions");
              return;
            }
            if (actions.includes("p")) {
              setStep("profile");
              return;
            }
            // default exclusions.
            setStep("exclusions");
            return;
          }}
          onValidate={({ user_id }) => {
            //setUserId(user_id);
          }}
        />
      );
    } else if (step === "exclusions") {
      render = (
        <ExclusionsCard
          accessKey={accessKey}
          exclusions={exclusions}
          onChange={setExclusions}
          hasPrevStep={actions.includes("p") && actions.includes("s") === false}
          onPrevStep={() => {
            setStep("profile");
          }}
          onNextStep={() => {
            // Sample plan, go to profile next.
            if (actions.includes("s")) {
              setStep("profile");
              return;
            }
            onComplete();
          }}
        />
      );
    } else if (step === "profile") {
      render = (
        <ProfileCard
          profile={profile}
          onChange={setProfile}
          onNextStep={() => {
            // Collect exclusions, but not a sample plan, go to exclusions.
            if (actions.includes("e") && actions.includes("s") === false) {
              setStep("exclusions");
              return;
            }
            onComplete();
          }}
          hasPrevStep={actions.includes("s")}
          onPrevStep={() => {
            setStep("exclusions");
          }}
        />
      );
    }
  }

  if (saving) {
    render = (
      <center>
        <p>Saving preferences...</p>
        <Loading />
      </center>
    );
  }

  if (complete) {
    render = <CompleteCard actions={actions} />;
  }

  return (
    <div className="app">
      <div className="app-body">
        <main className="main">
          <Container fluid>
            <Template.Page centerFixed={true}>
              <Template.PageBody>
                <Row>
                  <Col sm="12" md="8" className="offset-md-2 offset-sm-0">
                    {render}
                  </Col>
                </Row>
              </Template.PageBody>
            </Template.Page>
          </Container>
        </main>
      </div>
    </div>
  );
};

export default NoAuthOnboarding;
