/**
 * Record a stats event
 */

import Backend from "util/Backend";

class Event {
  constructor() {}

  /**
   * Send an event.
   * Catch any errors so it doesnt crash the whole app if the backend has an issue!
   */
  static async send(event_name, metadata) {
    let result = null;
    try {
      result = await Backend.post("event", { event_name, metadata });
    } catch (e) {
      console.log("Error", e);
    }
    return result;
  }
}

export default Event;
