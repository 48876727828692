import React, { useEffect } from "react";
import { connectPagination } from "react-instantsearch-dom";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { withRouter } from "react-router-dom";

import querySearch from "util/querySearch";

const ConnectedPagination = connectPagination(
  withRouter(
    ({
      currentRefinement,
      nbPages,
      refine,
      createURL,
      updateHistory,
      history
    }) => {
      useEffect(() => {
        refine(querySearch.get().page || 1);
      }, [querySearch.get().page]);

      if (nbPages < 2) {
        return null;
      }
      return (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: 25 }}
        >
          <Pagination>
            <PaginationItem disabled={currentRefinement === 1}>
              <PaginationLink
                previous
                tag="button"
                onClick={() => {
                  const nextPage = currentRefinement - 1;
                  refine(nextPage);
                  if (updateHistory) {
                    history.push({
                      search: querySearch.add({ page: nextPage })
                    });
                  }
                }}
              />
            </PaginationItem>
            <PaginationItem disabled={currentRefinement === nbPages}>
              <PaginationLink
                next
                tag="button"
                onClick={() => {
                  const nextPage = currentRefinement + 1;
                  refine(nextPage);
                  if (updateHistory) {
                    history.push({
                      search: querySearch.add({ page: nextPage })
                    });
                  }
                }}
              />
            </PaginationItem>
          </Pagination>
        </div>
      );
    }
  )
);
export default ConnectedPagination;
