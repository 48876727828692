import React from "react";

import { CurrentUserPartner } from "common";

const PartnerBrandSelector = props => {
  return (
    <CurrentUserPartner>
      {partner => {
        return (
          <select
            onChange={e => {
              props.onChange(e.target.value);
            }}
          >
            <option value={""}>Select brand...</option>
            {Object.entries(partner.brands).map(
              ([brandCode, brandDetails], idx) => {
                return (
                  <option key={idx} value={brandCode}>
                    {brandDetails.brand_name}
                  </option>
                );
              }
            )}
          </select>
        );
      }}
    </CurrentUserPartner>
  );
};

export default PartnerBrandSelector;
