// https://github.com/green-arrow/react-firestore/blob/master/src/FirestoreDocument.js

import React from "react";

import Firestore from "util/Firestore";

class FirestoreDocument extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      data: null,
      error: null,
      isUpdating: false
    };
  }

  componentDidMount() {
    this._setupListener();
  }

  componentWillUnmount() {
    this._handleUnsubscribe();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.id !== this.props.id ||
      nextProps.collection !== this.props.collection
    ) {
      this._handleUnsubscribe();

      this.setState({ isLoading: true, isUpdating: true }, () =>
        this._setupListener()
      );
    }
  }

  _setupListener() {
    const { collection, id } = this.props;
    const docRef = Firestore.db.collection(collection).doc(id);
    this.unsubscribe = docRef.onSnapshot(
      (docSnap) => {
        if (docSnap) {
          const newState = {
            isLoading: false,
            error: null,
            data: null,
            isUpdating: false
          };

          if (docSnap.exists) {
            try {
              const documentData = docSnap.data();
              newState.data = {
                id: docSnap.id,
                ...documentData
              };
            } catch (error) {
              newState.error = error;
            }
          }
          if (this.props.onLoad) {
            this.props.onLoad(newState);
          }

          this.setState(newState);
        }
      },
      (error) => {
        // Error
        console.log("Error", error);
        console.log(this.props);
        const newState = {
          isLoading: false,
          error,
          data: null,
          isUpdating: false
        };
        if (this.props.onLoad) {
          this.props.onLoad(newState);
        }
        this.setState(newState);
      }
    );
  }

  _handleUnsubscribe() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  render() {
    return this.props.children(this.state);
  }
}

export default FirestoreDocument;
