import React, { useState } from "react";

import { Alert } from "reactstrap";
import { Loading } from "common";

import User from "api/User";
import Audit from "api/Audit";

const RemoveAllCredits = (props) => {
  const { user, partner, zendeskClient } = props;

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);

  if (success === true) {
    return (
      <Alert color="success" className="p-1 m-0">
        <small>Remove credits successful!</small>
      </Alert>
    );
  }

  return (
    <>
      {success === false ? (
        <Alert color="danger" className="p-1 m-0">
          <small>Error removing credits</small>
        </Alert>
      ) : null}
      <small>
        <a
          href="#"
          onClick={async (e) => {
            e.preventDefault();
            if (loading) {
              return;
            }
            setLoading(true);

            const client = new User(user.id);
            try {
              await client.update({
                meal_plan_credits: 0
              });

              new Audit("client", user.id).event("All credits removed");

              setSuccess(true);
            } catch (e) {
              setSuccess(false);
            }

            setLoading(false);
          }}
        >
          0 weeks
        </a>
      </small>
      {loading ? (
        <Loading size={12} style={{ display: "inline-block", marginLeft: 5 }} />
      ) : null}
    </>
  );
};

export default RemoveAllCredits;
