import React, { useState } from "react";

import { Row, Col, Card, CardBody, CardHeader, Button } from "reactstrap";

import ProfileForm from "views/manage/clients/ViewClient/ProfileForm";

const ProfileCard = (props) => {
  const { profile, onChange, hasPrevStep, onPrevStep, onNextStep } = props;

  const [showErrors, setShowErrors] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);

  return (
    <Card>
      {hasPrevStep ? (
        <CardBody style={{ borderBottom: "1px solid #ccc" }}>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onPrevStep();
            }}
          >
            &lt; Back to exclusions
          </a>
        </CardBody>
      ) : null}
      <CardHeader>
        <span>Update your profile</span>
      </CardHeader>
      <CardBody>
        <ProfileForm
          user={{}}
          userChanges={profile}
          setUserChanges={(changes) => {
            onChange({
              ...profile,
              ...changes
            });
          }}
          showErrors={showErrors}
          onHasError={(hasErrors) => {
            setHasErrors(hasErrors);
          }}
        />
        <br />
        <center>
          <Button
            color="primary"
            size="lg"
            onClick={() => {
              if (hasErrors) {
                setShowErrors(true);
                return;
              }
              onNextStep();
            }}
          >
            Save & Continue
          </Button>
        </center>
      </CardBody>
    </Card>
  );
};

export default ProfileCard;
