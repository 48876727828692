/*
Algolia is updated for most collections with a cloud function (but it can be delayed, you know how cloud functions are...). These functions will ensure the index is updated immediately.
*/
import Backend from "util/Backend";

class AlogliaIndex {
  /*
    Update Algolia with the object in collection with id.
  */
  static updateObject(collection, id) {
    return Backend.post("algolia/index", {
      collection,
      id
    });
  }

  /*
    Remove object from Algolia.
  */
  static removeObject(collection, id) {
    return Backend.post("algolia/remove", {
      collection,
      id
    });
  }
}
export default AlogliaIndex;
