import React from "react";
import { Container, Row, Col, CardGroup, Card, CardBody } from "reactstrap";

import { CurrentClientPartner } from "common";

const Error = props => {
  const supportEmail = window.location.href.startsWith(
    "https://myplan.ketocustomplan"
  )
    ? "support@ketocustomplan.com"
    : null;

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col sm="6">
            <CardGroup>
              <Card className="p-4">
                <CardBody>
                  <h3>An unexpected error has occurred</h3>

                  {supportEmail ? (
                    <>
                      <p>Please contact support for help</p>
                      <p>Email: {supportEmail}</p>
                    </>
                  ) : null}
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Error;
