/*
Inputs for macronutrients

Props:
- macros - Object {carbohydrates,protein,fat}
- onChange - function - ({carbohydrates,protein,fat})
*/
import React from "react";
import { Row, Col, Alert, FormFeedback } from "reactstrap";
import { Input } from "common";

const MacroTextInputs = props => {
  const errors = props.errors || {};

  let doesntEqual100 = false;

  if (
    props.macros &&
    props.macros.carbohydrates &&
    props.macros.protein &&
    props.macros.fat
  ) {
    if (
      props.macros.carbohydrates + props.macros.protein + props.macros.fat !==
      100
    ) {
      doesntEqual100 = true;
    }
  }

  return (
    <>
      <Row>
        <Col align="center">
          <Input
            type="number"
            style={{ width: 80, textAlign: "center" }}
            value={props.macros && props.macros.carbohydrates}
            onChange={carbohydrates => {
              props.onChange({
                ...props.macros,
                carbohydrates
              });
            }}
            invalid={errors.carbohydrates}
          />
          <FormFeedback>Required</FormFeedback>% carbohydrates
        </Col>
        <Col align="center">
          <Input
            type="number"
            style={{ width: 80, textAlign: "center" }}
            value={props.macros && props.macros.protein}
            onChange={protein => {
              props.onChange({
                ...props.macros,
                protein
              });
            }}
            invalid={errors.protein}
          />
          <FormFeedback>Required</FormFeedback>% protein
        </Col>
        <Col align="center">
          <Input
            type="number"
            style={{ width: 80, textAlign: "center" }}
            value={props.macros && props.macros.fat}
            onChange={fat => {
              props.onChange({
                ...props.macros,
                fat
              });
            }}
            invalid={errors.fat}
          />
          <FormFeedback>Required</FormFeedback>% fat
        </Col>
      </Row>
      {doesntEqual100 ? (
        <Row className="mt-4">
          <Col>
            <Alert color="warning">
              Macronutrient ratios must add up to 100. Total is currently{" "}
              <strong>
                {props.macros.carbohydrates +
                  props.macros.protein +
                  props.macros.fat}
              </strong>
            </Alert>
          </Col>
        </Row>
      ) : null}
    </>
  );
};

export default MacroTextInputs;
