import React, { useRef, useState, useEffect } from "react";

import { Loading, CurrentUser } from "common";

import * as Chat from "./index";

import useWindowDimensions from "hooks/useWindowDimensions";

import moment from "moment";

const ScrollableMessagesList = (props) => {
  const {
    chatId,
    threadId,
    defaultMessageLimit,
    newMessageCount,
    renderMessage,

    clientLastViewedAt,
    scrollToBottomKey, // Change this to re-scroll to the bottom
    disableLoadingIndicator, // true and the loading icon will not show.
    fillHeight,
    heightOffset, // Reduce the height by this much. only when fillHeight=true
    height, // set the height of the scroller. used when fillHeight=false.
    style, // Object{container, scroller } - style to apply
    className // string - applied to the scroller container and the scroller.
  } = props;

  return (
    <Chat.MessagesList
      chatId={chatId}
      threadId={threadId}
      defaultMessageLimit={defaultMessageLimit}
      newMessageCount={newMessageCount}
    >
      {({ isLoaded, isLoadingMore, hasMoreMessages, onLoadMore, messages }) => {
        // Make sure to always render, even if empty, to prevent empty jitters

        let messagesList = messages.map((m, idx) => {
          return (
            <CurrentUser key={m.id}>
              {(currentUser) => {
                const currentUserIsStaff =
                  currentUser.account_type === "partner";

                const addProps = {
                  chatId,
                  threadId,
                  clientHasViewed:
                    clientLastViewedAt &&
                    m.created_at / 1000 < clientLastViewedAt
                      ? true
                      : false,
                  currentUserIsStaff,
                  showAsCurrentUser:
                    (currentUserIsStaff && m.user?.is_staff) ||
                    (!currentUserIsStaff && !m.user?.is_staff),
                  message: m
                };

                return (
                  <>
                    {renderMessage ? (
                      renderMessage(addProps)
                    ) : (
                      <Chat.MessageBubble {...addProps} />
                    )}
                  </>
                );
              }}
            </CurrentUser>
          );
        });

        return (
          <NewMessageBottomScroller
            fillHeight={fillHeight}
            heightOffset={heightOffset}
            height={height}
            scrollToBottomKey={scrollToBottomKey}
            messages={messages}
            isLoaded={isLoaded}
            disableLoadingIndicator={disableLoadingIndicator}
            className={className}
            onScroll={(e) => {
              const scrollTop = e.currentTarget.scrollTop;

              if (!isLoaded || isLoadingMore || !hasMoreMessages) {
                return;
              }

              if (scrollTop < 250) {
                onLoadMore();
              }
            }}
            style={style}
          >
            {isLoadingMore ? <span>Loading...</span> : null}
            {messagesList}
          </NewMessageBottomScroller>
        );
      }}
    </Chat.MessagesList>
  );
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const NewMessageBottomScroller = (props) => {
  const {
    messages,
    isLoaded,
    fillHeight, // when true, the scroller will fill the browser height
    heightOffset, // when fillHeight is true, reduce the browser height by this amount.
    height, // when not filling the height, the height to use.
    scrollToBottomKey,
    disableLoadingIndicator,
    style, // { container, scroller }
    className
  } = props;

  const scrollRef = useRef(null);

  const [visible, setVisible] = useState(false);

  //const [height, setHeight] = useState(null);

  const prevMessages = usePrevious(messages);

  const { height: windowHeight, width: windowWidth } = useWindowDimensions();

  // Update the height.
  /*useEffect(() => {
    const doFillHeight = () => {
      if (scrollRef.current.getBoundingClientRect().top < 1) {
        return;
      }
      setHeight(
        windowHeight -
          scrollRef.current.getBoundingClientRect().top -
          50 -
          heightOffset
      );
    };

    if (!fillHeight) {
      return;
    }
    doFillHeight();

    const int = setInterval(doFillHeight, 500);
    return () => {
      clearInterval(int);
    };
  }, [prevMessages, messages]);*/

  // Scroll when there are new messages.
  useEffect(() => {
    if (!messages?.length || !prevMessages?.length) {
      return;
    }

    const nMessages = [].concat(messages);
    const nPrevMessages = [].concat(prevMessages);

    if (nMessages.pop().created_at > nPrevMessages.pop().created_at) {
      scrollRef.current?.scroll(0, scrollRef.current.scrollHeight);
    }
  }, [prevMessages, messages]);

  // Scroll to the bottom when the initial set of messages is loaded.
  useEffect(() => {
    if (isLoaded) {
      scrollRef.current?.scroll(0, scrollRef.current.scrollHeight);
      setVisible(true);
    }
  }, [isLoaded]);

  useEffect(() => {
    setTimeout(() => {
      scrollRef.current?.scroll(0, scrollRef.current.scrollHeight);
    }, 50);
  }, [scrollToBottomKey]);

  const modifiedProps = { ...props };

  delete modifiedProps.fillHeight;
  delete modifiedProps.scrollToBottomKey;
  delete modifiedProps.isLoaded;
  delete modifiedProps.disableLoadingIndicator;
  delete modifiedProps.heightOffset;
  delete modifiedProps.height;

  /*
  if (fillHeight) {
    delete modifiedProps.style.maxHeight;
  }
 */

  let addScrollerStyle = {};
  if (fillHeight) {
    addScrollerStyle = {
      maxHeight: `calc(100vh - ${heightOffset}px)`,
      minHeight: `calc(100vh - ${heightOffset}px)`
    };
  } else {
    addScrollerStyle = { height };
  }

  return (
    <div
      style={{
        //borderBottom: "1px solid #ccc",
        background: "#FFF",
        position: "relative",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        ...style?.container,
        ...addScrollerStyle
      }}
      className={className}
    >
      {!disableLoadingIndicator ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            alignItems: "center",
            justifyContent: "center",
            display: visible ? "none" : "flex",
            ...addScrollerStyle,
            ...style?.scroller
          }}
          className={className}
        >
          <span style={{ opacity: 0.33 }}>loading</span>
        </div>
      ) : null}
      {isLoaded && messages.length < 1 ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            ...addScrollerStyle,
            ...style?.scroller
          }}
          className={className}
        >
          <p style={{ opacity: 0.33 }}>How can we help with this meal?</p>
        </div>
      ) : (
        <div
          ref={scrollRef}
          {...modifiedProps}
          style={{
            overflowY: "auto",
            //maxHeight: 300,
            //minHeight: 150,
            padding: 15,
            //height: height,
            visibility: visible ? "visible" : "hidden",
            flex: 1,
            ...addScrollerStyle,
            //borderBottom: "1px solid #ccc",
            ...style?.scroller
          }}
          className={className}
        >
          {props.children}
        </div>
      )}
    </div>
  );
};

export default ScrollableMessagesList;
