import React, { useState } from "react";

import { Alert } from "reactstrap";
import { Loading } from "common";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import User from "api/User";
import Audit from "api/Audit";

const AddSingleCredit = (props) => {
  const { user, partner, zendeskClient } = props;

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);

  return (
    <>
      {success === false ? (
        <Alert color="danger" className="p-1 m-0">
          <small>Error adding credit</small>
        </Alert>
      ) : null}
      <small>
        {success === true ? (
          <Alert color="success" className="p-1 m-0">
            <small>Add credit successful!</small>
          </Alert>
        ) : null}
        <a
          href="#"
          onClick={async (e) => {
            e.preventDefault();
            if (loading) {
              return;
            }
            setLoading(true);

            const client = new User(user.id);
            try {
              await client.update({
                meal_plan_credits: firebase.firestore.FieldValue.increment(1)
              });

              new Audit("client", user.id).event("Credit added");

              setSuccess(true);
            } catch (e) {
              setSuccess(false);
            }

            setLoading(false);
          }}
        >
          +1 week
        </a>
      </small>
      {loading ? (
        <Loading size={12} style={{ display: "inline-block", marginLeft: 5 }} />
      ) : null}
    </>
  );
};

export default AddSingleCredit;
