/*
Render results from algolia

renderHit - function(hit) - returns a react element to render as a hit.
onEnterKey - function(selectedHit, allHits) - call prop function with the selected hit. First hit is the default, others if arrowSelect is enabled.
arrowSelect - boolean - enable selecting of results by arrow keys. each hit will get a __isSelected=boolean added to the object.
*/
import React, { useState, useEffect } from "react";

import { connectHits, connectStateResults } from "react-instantsearch-dom";

import { KeyDetector } from "common";

const Results = connectHits(
  connectStateResults(props => {
    const [selectedItem, setSelectedItem] = useState(0);

    useEffect(() => {
      setSelectedItem(0);
    }, [props.searchState.query]);

    if (props.hits.length < 1) {
      return null;
    }

    if (
      props.emptyQueryHideResults &&
      (!props.searchState || !props.searchState.query)
    ) {
      return null;
    }

    return (
      <>
        <props.container>
          {props.hits.map((hit, idx) => {
            if (props.arrowSelect) {
              hit.__isSelected = selectedItem === idx;
            }

            return props.renderHit(hit, idx);
          })}
        </props.container>
        {props.onEnterKey ? (
          <KeyDetector.Component
            useKey={KeyDetector.ENTER}
            onPress={() => {
              if (!props.hits || props.hits.length < 1) {
                return;
              }

              if (props.arrowSelect) {
                return props.onEnterKey(props.hits[selectedItem], props.hits);
              }
              // First item in the list.
              props.onEnterKey(props.hits[0], props.hits);
            }}
          />
        ) : null}
        {props.arrowSelect ? (
          <>
            <KeyDetector.Component
              useKey={KeyDetector.ARROW_UP}
              onPress={() => {
                if (selectedItem < 1) {
                  return;
                }
                setSelectedItem(selectedItem - 1);
              }}
            />
            <KeyDetector.Component
              useKey={KeyDetector.ARROW_DOWN}
              onPress={() => {
                if (selectedItem + 1 >= props.hits.length) {
                  return;
                }
                setSelectedItem(selectedItem + 1);
              }}
            />
          </>
        ) : null}
      </>
    );
  })
);

export default Results;
