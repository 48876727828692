/*
Drop a file and upload to google storage.

In development, files are prefixed with DEV- when stored in GS.
A non-prefixed file name is always returned.

File will be given a random name and prefixed with the provided folder.

Props:
- folder - folder prefix to place the image in.
- onComplete - function(path) - callback with the full google storage path.
- resize - boolean - set metadata so a cloud function knows to resize this image.
- allowExtensions - Array<string> - allowed extensions.
*/
import React, { useState, useCallback } from "react";
import { DEV } from "config";
import { Loading } from "common";

import randomstring from "randomstring";

import firebase from "firebase/compat/app";
import "firebase/compat/storage";

import { useDropzone } from "react-dropzone";

const DragDropUploader = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ error: false, message: null });

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setIsLoading(true);

      const file = acceptedFiles[0];

      const extension = file.name.split(".").pop().toLowerCase();

      if (
        props.allowExtensions &&
        props.allowExtensions.includes(extension) === false
      ) {
        //if (extension !== "png" && extension !== "jpg" && extension !== "jpeg") {
        setIsLoading(false);
        setError({
          error: true,
          message: `Invalid extension [${extension}] - props only allow [${props.allowExtensions.join(
            ", "
          )}]`
        });
        return;
      }

      let folder = props.folder;
      // Folder needs to be "folder/subfolder/subfolder" - no prefix or trailing slash.
      if (folder.substr(0, 1) === "/") {
        folder = folder.substr(1);
      }
      if (folder.substr(0, folder.length - 1) === "/") {
        folder = folder.substr(0, folder.length - 1);
      }

      const fileName = `${folder}/${randomstring.generate({
        length: 12
      })}.${extension}`;

      const storageService = firebase.storage();
      const storageRef = storageService.ref();

      let devPrefix = "";

      if (DEV) {
        devPrefix = "DEV-";
      }
      // Don't add the devPrefix to the fileName because the GSImage component will also prefix, when dev.
      // This lets us clone the live data and it still works in development.
      const fileRef = storageRef.child(`${devPrefix}${fileName}`);

      // Add metadata for resizing.
      // This works along with a cloud function to resize if it sees this metadata.
      let metadata = {};
      /*
      if (props.resize) {
        metadata = {
          customMetadata: {
            resize: "yes"
          }
        };
      }
      */

      // Upload the file
      try {
        await fileRef.put(file, metadata);
      } catch (e) {
        console.error(e);
        setError({ error: true, message: e });
        return;
      }

      setIsLoading(false);
      // Return non-dev file name.
      props.onComplete(fileName);
    },
    [props.onComplete]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  if (isLoading) {
    return <Loading />;
  }

  let renderChildren = (
    <p>Drag 'n' drop some files here, or click to select files</p>
  );

  if (props.children) {
    renderChildren = props.children;
  }

  if (isDragActive) {
    renderChildren = <p>Drop the files here ...</p>;
  }

  if (isDragActive && props.activeDragChild) {
    renderChildren = props.activeDragChild;
  }

  return (
    <div {...getRootProps()} style={props.style}>
      {error.error ? <span>{error.message}</span> : null}
      <input {...getInputProps()} />
      {renderChildren}
    </div>
  );
};
export default DragDropUploader;
