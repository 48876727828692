/**
 * Same as FirestoreCollection, but with hasMore and loadMore(numToLoad).
 */
import React, { useState } from "react";

import FirestoreCollection from "./FirestoreCollection";

const FirestoreLoadableCollection = (props) => {
  const { initialLimit } = props;

  const [limit, setLimit] = useState(initialLimit);

  return (
    <FirestoreCollection {...props} limit={limit + 1}>
      {({ isLoading, data }) => {
        if (isLoading) {
          return props.children({ isLoading, data: null });
        }

        let hasMore = false;
        if (data.length > limit) {
          // We loaded one extra to know if there's more entries.
          hasMore = true;
        }

        let newData = [].concat(data);
        // remove the last item from the list if it's longer than what we wanted.
        if (data.length >= limit + 1) {
          newData.pop();
        }

        return props.children({
          isLoading,
          hasMore,
          loadMore: (numToLoad) => {
            let newAmount = numToLoad;
            if (!newAmount) {
              newAmount = 1;
            }
            setLimit((limit) => limit + newAmount);
          },
          data: newData
        });
      }}
    </FirestoreCollection>
  );
};

export default FirestoreLoadableCollection;
