import Backend from "util/Backend";
import Firestore from "util/Firestore";

import KeyedObjectArray from "util/KeyedObjectArray";

const deepClone = require("clone-deep");
const randomstring = require("randomstring");
const getAltKey = () => {
  return Math.round(Math.random() * 100000);
};

class MealPlanDay {
  constructor(id) {
    this.id = id;
  }

  docRef() {
    return Firestore.db.collection("meal_plan_days").doc(this.id);
  }

  update(doc) {
    return this.docRef().update(doc);
  }

  /**
   *
   */
  mergeMealChanges({ meals, mealIndex, mealChanges }) {
    console.error("mergeMealChanges no good");
    return;
    const newMeals = [].concat(meals);
    newMeals[mealIndex] = {
      ...newMeals[mealIndex],
      ...mealChanges
    };
    return this.docRef().update({ meals: newMeals });
  }

  /**
   * Toggle the pinning of the given mealIndex. Pinning also changes the day's alt_key.
   * meals - array of all meals in the day.
   * mealIndex - index of the meal to pin.
   */
  toggleMealPinned({ meals, mealIndex }) {
    //const newMeals = [].concat(meals);
    //newMeals[mealIndex].is_pinned = !newMeals[mealIndex].is_pinned;

    return this.docRef().update({
      alt_key: getAltKey(),
      [`meals.${mealIndex}.is_pinned`]: !meals[mealIndex].is_pinned
    });
  }

  async deleteMeal({ mealIndex, meals }) {
    const newMeals = KeyedObjectArray.toArray(meals);
    newMeals.splice(mealIndex, 1);
    const updatedNutrition = this.getUpdatedNutrition(
      KeyedObjectArray.toObject(newMeals)
    );
    this.docRef().update({
      nutrition: updatedNutrition.nutrition,
      meals: updatedNutrition.meals
    });
  }

  async deleteMealFoodItem({ mealIndex, foodIndex, meals }) {
    const newMeals = { ...meals };
    const newFood = KeyedObjectArray.toArray(meals[mealIndex].food);
    newFood.splice(foodIndex, 1);

    newMeals[mealIndex].food = KeyedObjectArray.toObject(newFood);

    const updatedNutrition = this.getUpdatedNutrition(newMeals);

    this.docRef().update({
      nutrition: updatedNutrition.nutrition,
      [`meals.${mealIndex}.nutrition`]: updatedNutrition.meals[mealIndex]
        .nutrition,
      [`meals.${mealIndex}.food`]: updatedNutrition.meals[mealIndex].food
    });

    //this.refreshNutritionForMeal({ meal, food: newMealFood });
  }

  async updateMealFoodItem({
    mealIndex,
    foodIndex,
    meals,
    foodItemChanges // changes to the food item.
  }) {
    const newMeals = { ...meals };
    newMeals[mealIndex].food = { ...meals[mealIndex].food };

    newMeals[mealIndex].food[foodIndex] = {
      ...newMeals[mealIndex].food[foodIndex],
      ...foodItemChanges
    };

    const updatedNutrition = this.getUpdatedNutrition(newMeals);

    // Build the food item update object.
    // We only want to apply changes, not update the whole thing.
    // So build keys and set the values.
    // meals.0.food.0.nutrition and all other changes.
    const updateObj = {};
    Object.entries(foodItemChanges).forEach(([key, value]) => {
      updateObj[`meals.${mealIndex}.food.${foodIndex}.${key}`] = value;
    });

    await this.docRef().update({
      nutrition: updatedNutrition.nutrition, // main day nutrition
      [`meals.${mealIndex}.nutrition`]: updatedNutrition.meals[mealIndex]
        .nutrition, // meal nutrition.
      [`meals.${mealIndex}.food.${foodIndex}.is_edited`]: true,
      ...updateObj
    });
  }

  async moveMeal({
    sourceDayId,
    sourceMealIndex,
    destinationDayId,
    destinationMealIndex,
    dayData
  }) {
    // Mutate the data so we don't have a rerender flash.
    const newDayData = dayData;

    if (sourceDayId === destinationDayId) {
      // Moving within the same day.
      const sourceMeal = deepClone(
        newDayData[sourceDayId].meals[sourceMealIndex]
      );
      const destinationMeal = deepClone(
        newDayData[sourceDayId].meals[destinationMealIndex]
      );

      // Convert to an array so we can manipulate.
      const newMeals = KeyedObjectArray.toArray(newDayData[sourceDayId].meals);
      // Remove item from the source.
      newMeals.splice(sourceMealIndex, 1);
      newMeals.splice(destinationMealIndex, 0, sourceMeal);

      // Mutate each meal so we dont get a re-render flash.
      newMeals.forEach((meal, idx) => {
        newDayData[sourceDayId].meals[idx] = meal;
      });
    } else {
      // Moving between different meals.
      if (destinationMealIndex > newDayData[destinationDayId].meals.length) {
        // Need to add to the end of the array.
        newDayData[destinationDayId].meals[
          Object.values(newDayData[destinationDayId].meals).length
        ] = { ...newDayData[sourceDayId].meals[sourceMealIndex] };
      } else {
        // Insert at index.
        const newDestinationMeals = KeyedObjectArray.toArray(
          newDayData[destinationDayId].meals
        );
        newDestinationMeals.splice(destinationMealIndex, 0, {
          ...newDayData[sourceDayId].meals[sourceMealIndex]
        });
        newDestinationMeals.forEach((meal, idx) => {
          newDayData[destinationDayId].meals[idx] = meal;
        });
        /*newDayData[destinationDayId].meals.splice(
          destinationMealIndex,
          0,
          newDayData[sourceDayId].meals[sourceMealIndex]
        );*/
      }

      // Remove item from the source.
      // Mutate to avoid rerender
      const newSourceMeals = KeyedObjectArray.toArray(
        newDayData[sourceDayId].meals
      );
      newSourceMeals.splice(sourceMealIndex, 1);

      newSourceMeals.forEach((meal, idx) => {
        newDayData[sourceDayId].meals[idx] = meal;
      });

      // Remove the last item in the source to clean up the old removed element.
      delete newDayData[sourceDayId].meals[
        Object.values(newDayData[sourceDayId].meals).length - 1
      ];
    }

    const sourceNutrition = this.getUpdatedNutrition(
      newDayData[sourceDayId].meals
    );
    const destinationNutrition = this.getUpdatedNutrition(
      newDayData[destinationDayId].meals
    );

    // Need to update alt_keys on source and destination since pre-cached items no longer work.

    // Object is created with the source day id.
    this.docRef().update({
      nutrition: sourceNutrition.nutrition,
      meals: sourceNutrition.meals,
      alt_key: getAltKey()
    });

    if (sourceDayId !== destinationDayId) {
      const newMealPlanDay = new MealPlanDay(destinationDayId);
      newMealPlanDay.docRef().update({
        nutrition: destinationNutrition.nutrition,
        meals: destinationNutrition.meals,
        alt_key: getAltKey()
      });
    }
  }

  async duplicateMeal({ mealIndex, meals }) {
    const newMeals = KeyedObjectArray.toArray(meals);

    // Add at the index of mealIndex+1. Better than adding to the end of the array which shows it in the UI as the bottom, which feels unnatural.
    newMeals.splice(mealIndex, 0, {
      ...newMeals[mealIndex],
      id: randomstring.generate(5),
      alt_key: getAltKey()
    });

    const updatedNutrition = this.getUpdatedNutrition(
      KeyedObjectArray.toObject(newMeals)
    );

    this.docRef().update({
      nutrition: updatedNutrition.nutrition,
      meals: updatedNutrition.meals
    });
  }

  async moveMealFoodItem({
    sourceDayId,
    sourceMealIndex,
    sourceFoodIndex,
    destinationDayId,
    destinationMealIndex,
    destinationFoodIndex,
    dayData
  }) {
    // Mutate the data so we don't have a rerender flash.
    const newDayData = dayData;

    if (
      sourceDayId === destinationDayId &&
      sourceMealIndex === destinationMealIndex
    ) {
      // Moving within the same day and meal.

      const sourceFood = deepClone(
        newDayData[sourceDayId].meals[sourceMealIndex].food[sourceFoodIndex]
      );

      /*
      // Remove item from the source.
      newDayData[sourceDayId].meals[sourceMealIndex].food.splice(
        sourceFoodIndex,
        1
      );

      // Add at the destination.
      newDayData[destinationDayId].meals[destinationMealIndex].food.splice(
        destinationFoodIndex,
        0,
        sourceFood
      );
      */

      // Convert to an array so we can manipulate.
      const newSourceFood = KeyedObjectArray.toArray(
        newDayData[sourceDayId].meals[sourceMealIndex].food
      );

      // Remove item
      newSourceFood.splice(sourceFoodIndex, 1);

      // Add to the array
      newSourceFood.splice(destinationFoodIndex, 0, sourceFood);

      // Mutate each meal so we dont get a re-render flash.
      newSourceFood.forEach((food, idx) => {
        newDayData[sourceDayId].meals[sourceMealIndex].food[idx] = food;
      });
    } else {
      // Moving between different meals.
      if (
        destinationFoodIndex >
        newDayData[destinationDayId].meals[destinationMealIndex].food.length
      ) {
        // Need to add to the object with a proper key index
        const food =
          newDayData[destinationDayId].meals[destinationMealIndex].food;
        food[Object.values(food).length] =
          newDayData[sourceDayId].meals[sourceMealIndex].food[sourceFoodIndex];
        /*
        newDayData[destinationDayId].meals[destinationMealIndex].food.push(
          newDayData[sourceDayId].meals[sourceMealIndex].food[sourceFoodIndex]
        );
        */
      } else {
        // Insert at index.
        const newDestinationFood = KeyedObjectArray.toArray(
          newDayData[destinationDayId].meals[destinationMealIndex].food
        );

        newDestinationFood.splice(
          destinationFoodIndex,
          0,
          newDayData[sourceDayId].meals[sourceMealIndex].food[sourceFoodIndex]
        );

        newDestinationFood.forEach((food, idx) => {
          newDayData[destinationDayId].meals[destinationMealIndex].food[
            idx
          ] = food;
        });

        /*newDayData[destinationDayId].meals[destinationMealIndex].food.splice(
          destinationFoodIndex,
          0,
          newDayData[sourceDayId].meals[sourceMealIndex].food[sourceFoodIndex]
        );*/
      }

      // Remove item from the source.
      /*newDayData[sourceDayId].meals[sourceMealIndex].food.splice(
        sourceFoodIndex,
        1
      );*/

      const newSourceFood = KeyedObjectArray.toArray(
        newDayData[sourceDayId].meals[sourceMealIndex].food
      );
      newSourceFood.splice(sourceFoodIndex, 1);

      newSourceFood.forEach((food, idx) => {
        newDayData[sourceDayId].meals[sourceMealIndex].food[idx] = food;
      });

      // Remove the last item in the source to clean up the old removed element.
      delete newDayData[sourceDayId].meals[sourceMealIndex].food[
        Object.values(newDayData[sourceDayId].meals[sourceMealIndex].food)
          .length - 1
      ];
    }

    const sourceNutrition = this.getUpdatedNutrition(
      newDayData[sourceDayId].meals
    );
    const destinationNutrition = this.getUpdatedNutrition(
      newDayData[destinationDayId].meals
    );

    // Update the alt_key if any meals in the source or destination is pinned.
    let sourceAltKey = {};
    let destinationAltKey = {};

    if (sourceNutrition.meals[sourceMealIndex].is_pinned) {
      sourceAltKey = {
        alt_key: getAltKey()
      };
    }
    if (destinationNutrition.meals[destinationMealIndex].is_pinned) {
      destinationAltKey = {
        alt_key: getAltKey()
      };
    }

    // Object is created with the source day id.
    this.docRef().update({
      nutrition: sourceNutrition.nutrition,
      meals: sourceNutrition.meals,
      ...sourceAltKey
    });

    if (sourceDayId !== destinationDayId) {
      const newMealPlanDay = new MealPlanDay(destinationDayId);
      newMealPlanDay.docRef().update({
        nutrition: destinationNutrition.nutrition,
        meals: destinationNutrition.meals,
        ...destinationAltKey
      });
    }
  }

  async duplicateMealFoodItem({ mealIndex, foodIndex, meals }) {
    const newFood = KeyedObjectArray.toArray(meals[mealIndex].food);

    newFood.splice(foodIndex, 0, {
      ...meals[mealIndex].food[foodIndex]
    });

    newFood.forEach((food, idx) => {
      meals[mealIndex].food[idx] = food;
    });

    /*
    newMeals[mealIndex].food.splice(foodIndex, 0, {
      ...newMeals[mealIndex].food[foodIndex],
      // Add a unique ID to make the item unique (non-unique items cant be added to the same array - for firestore)
      uniqueId: Math.round(Math.random() * 100000)
    });
    */

    const updatedNutrition = this.getUpdatedNutrition(meals);

    this.docRef().update({
      nutrition: updatedNutrition.nutrition,
      [`meals.${mealIndex}.nutrition`]: updatedNutrition.meals[mealIndex]
        .nutrition,
      [`meals.${mealIndex}.food`]: updatedNutrition.meals[mealIndex].food
    });

    //this.refreshNutritionForMeal({ meal, food: newMealFood });
  }

  async addFoodToMeal({ mealIndex, meals, newMeal }) {
    const newMeals = [].concat(meals);
    newMeals[mealIndex].food = [].concat(
      newMeals[mealIndex].food,
      newMeal.food
    );

    const updatedNutrition = this.getUpdatedNutrition(newMeals);

    this.docRef().update({
      nutrition: updatedNutrition.nutrition,
      meals: updatedNutrition.meals
    });
  }

  /**
   * Recalculate the nutrition and return Object with updated nutrition.
   * {
        nutrition,
        meals: [
          {nutrition, ...other data}
        ]
      }
   */
  getUpdatedNutrition(meals) {
    let dayNutrition = {};
    let newMeals = [];
    KeyedObjectArray.toArray(meals).forEach((meal) => {
      let mealNutrition = {};
      KeyedObjectArray.toArray(meal.food).forEach((foodItem) => {
        // Sum each nutrition key.
        Object.entries(foodItem.nutrition).forEach(([key, value]) => {
          if (!mealNutrition[key]) {
            mealNutrition[key] = 0;
          }
          mealNutrition[key] += value;
        });
      });

      newMeals.push({
        ...meal,
        nutrition: mealNutrition
      });

      // Add to the total day.
      Object.entries(mealNutrition).forEach(([key, value]) => {
        if (!dayNutrition[key]) {
          dayNutrition[key] = 0;
        }
        dayNutrition[key] += value;
      });
    });

    return {
      nutrition: dayNutrition,
      meals: KeyedObjectArray.toObject(newMeals)
    };
  }

  /**
   * Queue alternative days to be generated.
   */
  queueAlternativeDays(numDaysToQueue) {
    return Backend.post(`meal_plan_days/${this.id}/queue_alternative_days`, {
      numDaysToQueue
    });
  }

  /**
   * Queue alternative days to be generated.
   */
  queueAlternativeMeals(mealIndex, numMealsToQueue) {
    return Backend.post(`meal_plan_days/${this.id}/queue_alternative_meals`, {
      mealIndex,
      numMealsToQueue
    });
  }

  /**
   * Determine if meals in a day have calorie_ranges that are valid.
   * A day can't refresh if the max calorie range sum of meals doesnt = 100%
   *
   * meals - Object{mealIndex: meal} - the meals key from meal_plan_days
   *
   * Returns boolean - true if valid
   */
  static getMealCalorieUpperLimitSum(meals) {
    let highCalSum = 0;

    Object.entries(meals).forEach(([mealIndex, meal]) => {
      const [lowCal, highCal] = meal.calorie_range;

      highCalSum += highCal;
    });

    return highCalSum;
  }

  /**
  * Get the meals key for a day, corrected to the newest version.
  * Can be:
  * Object {breakfast,lunch,dinner,snack} - meal plans v1
  * Array<Object{...meal}> - meal plans v2
  *
  * Returns:
  Object{
    mealIndex: {...meal, food: {foodIndex: {...food}}}
  }
  */
  static getCorrectedMeals(meals) {
    if (meals.breakfast || meals.lunch || meals.dinner || meals.snack) {
      // v1 format.
      const nextMeals = {
        0: {
          display_name: "Breakfast",
          ...meals.breakfast
        },
        1: {
          display_name: "Lunch",
          ...meals.lunch
        },
        2: {
          display_name: "Dinner",
          ...meals.dinner
        }
      };

      if (meals.snack) {
        nextMeals[3] = {
          display_name: "Snack",
          ...meals.snack
        };
      }
      return nextMeals;
    }

    if (Array.isArray(meals)) {
      const newMeals = {};
      meals.forEach((meal, idx) => {
        if (Array.isArray(meal.food)) {
          const newFood = {};
          meal.food.forEach((foodItem, foodIndex) => {
            newFood[foodIndex] = foodItem;
          });
          meal.food = newFood;
        }
        newMeals[idx] = meal;
      });
      return newMeals;
    }

    return meals;
  }

  /**
   * Given a meals object { mealIndex: { ...meal, id }} and id to find:
   * Return object {meal, mealIndex}
   * Returns null if not found.
   */
  static getMealDetailsWithId(meals, id) {
    for (let [mealIndex, meal] of Object.entries(meals)) {
      if (meal.id === id) {
        return { mealIndex, meal };
      }
    }
    return null;
  }
}

export default MealPlanDay;
