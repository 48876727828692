import React, { useState, useEffect } from "react";
import { Button, Alert, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { DEV } from "config";
import { FirestoreDocument, Loading } from "common";

import MarketingUnsubscribe from "./MarketingUnsubscribe";
import AddSingleCredit from "./AddSingleCredit";
import RemoveAllCredits from "./RemoveAllCredits";
import SetDefaultPassword from "./SetDefaultPassword";
import MealPlanStatus from "./MealPlanStatus";
import SendAccountAccess from "./SendAccountAccess";
import RefundSubmitted from "./RefundSubmitted";
import BillingSummary from "./BillingSummary";

import NavHeader from "views/pages/Zendesk/NavHeader";

import CurrencyCodeSelector from "./CurrencyCodeSelector";

import { BRANDS } from "const";

const ZendeskUser = (props) => {
  const [emailMatch, setEmailMatch] = useState(null);
  const [nameMatch, setNameMatch] = useState(null);

  const { partner, zendeskClient, brandId } = props;

  const {
    match: {
      params: { userId }
    },
    requester
  } = props;

  let viewClientLink = `https://${partner.brand.domain_name}/manage/clients/${userId}`;

  if (DEV) {
    viewClientLink = `http://localhost:3000/manage/clients/${userId}`;
  }

  return (
    <FirestoreDocument collection="users" id={userId}>
      {({ isLoading, data: user }) => {
        if (isLoading) {
          return (
            <center>
              <Loading />
            </center>
          );
        }

        let credits = 0;
        let creditsLabel = "week";

        if (user.meal_plan_credits) {
          credits = user.meal_plan_credits;
        }

        if (credits !== 1 && creditsLabel !== "") {
          creditsLabel += "s";
        }

        let mismatchAlertMessage = null;

        const noWhiteSpace = (str) => {
          return str ? str.replace(/ /g, "") : "";
        };

        // Compare names with all whitespace removed.
        if (
          noWhiteSpace(`${user.first_name} ${user.last_name}`).toLowerCase() !==
          noWhiteSpace(requester.name).toLowerCase()
        ) {
          // Name doesnt match.
          if (
            user.email &&
            requester.email &&
            user.email.toLowerCase() !== requester.email.toLowerCase()
          ) {
            mismatchAlertMessage = "Ticket name and email doesn't match";
          } else if (user.first_name && user.last_name) {
            mismatchAlertMessage = "Ticket name doesn't match";
          }
        } else if (
          user.email &&
          requester.email &&
          user.email.toLowerCase() !== requester.email.toLowerCase()
        ) {
          // Email doesnt match.
          mismatchAlertMessage = "Ticket email doesn't match";
        }

        let paypalMatchMessage = false;

        // If the ticket email doesn't match the user email, but the paypal email matches the ticket email, show an alert.
        if (
          user.paypal_email &&
          requester.email &&
          user.email &&
          requester.email.toLowerCase() === user.paypal_email.toLowerCase() &&
          requester.email.toLowerCase() !== user.email.toLowerCase()
        ) {
          paypalMatchMessage = true;
          // Disable the mismatch alert.
          mismatchAlertMessage = null;
        }

        const currencyCode = user.currency_code
          ? user.currency_code.toUpperCase()
          : "USD";

        return (
          <>
            <NavHeader zendeskClient={zendeskClient} page="customer" />
            {mismatchAlertMessage ? (
              <Row className="m-0 p-0 mb-1">
                <Col className="m-0 p-0">
                  <Alert color="warning" className="m-0 p-0">
                    <p className="mb-0 px-1">
                      <strong>{mismatchAlertMessage}</strong>
                      <br />
                      <Link to="/zendesk">Search for ticket user</Link>
                    </p>
                  </Alert>
                </Col>
              </Row>
            ) : null}
            {paypalMatchMessage ? (
              <Row className="m-0 p-0 mb-1">
                <Col className="m-0 p-0">
                  <Alert color="success" className="m-0 p-0">
                    <p className="mb-0 px-1">
                      <strong>
                        Ticket email matches PayPal purchase email
                      </strong>
                    </p>
                  </Alert>
                </Col>
              </Row>
            ) : null}
            {brandId !== user.brand_id ? (
              <Row className="m-0 p-0 mb-1">
                <Col className="m-0 p-0">
                  <Alert color="danger" className="m-0 p-0">
                    <p className="mb-0 px-1">
                      <strong>TICKET BRAND DOES NOT MATCH USER BRAND</strong>
                    </p>
                  </Alert>
                </Col>
              </Row>
            ) : null}
            <Row className="m-0 p-0 mb-0">
              <Col className="m-0 p-0">
                <strong>
                  {user.first_name} {user.last_name}
                </strong>{" "}
                {noWhiteSpace(
                  `${user.first_name} ${user.last_name}`
                ).toLowerCase() ===
                noWhiteSpace(requester.name).toLowerCase() ? (
                  <span style={{ fontSize: 8 }}>✅</span>
                ) : null}
              </Col>
              <Col>
                <center>
                  <small>
                    {BRANDS[user.brand_id]
                      ? BRANDS[user.brand_id].brand_name
                      : ""}{" "}
                    {brandId === user.brand_id ? (
                      <span style={{ fontSize: 8 }}>✅</span>
                    ) : null}
                  </small>
                </center>
              </Col>
            </Row>
            <Row className="m-0 p-0 mb-1">
              <Col className="m-0 p-0">
                {user.email}{" "}
                {user.email &&
                requester.email &&
                user.email.toLowerCase() === requester.email.toLowerCase() ? (
                  <small>
                    <span style={{ fontSize: 8 }}>✅</span>
                  </small>
                ) : null}
                {user.paypal_email ? (
                  <>
                    <br />
                    <small>paypal: {user.paypal_email}</small>
                  </>
                ) : null}
              </Col>
            </Row>
            <Row className="m-0 p-0 mb-1">
              <Col xs={6} className="m-0 p-0">
                <Button
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    zendeskClient.invoke("instances.create", {
                      location: "modal",
                      url: viewClientLink,
                      size: {
                        // optional
                        width: "80vw",
                        height: "80vh"
                      }
                    });
                  }}
                  style={{ width: "48%" }}
                  target="_blank"
                  color="primary"
                  size="sm"
                >
                  View
                </Button>
                <Button
                  style={{ width: "48%", marginLeft: 5 }}
                  href={viewClientLink}
                  target="_blank"
                  color="primary"
                  size="sm"
                >
                  Open
                </Button>
              </Col>
              <Col xs={6}>
                <SendAccountAccess
                  user={user}
                  partner={partner}
                  zendeskClient={zendeskClient}
                />
              </Col>
            </Row>
            <Row className="m-0 p-0 mt-3">
              <Col xs={7} className="m-0 p-0">
                {/*<p className="mb-0">
                  <small>
                    <CurrencyCodeSelector user={user} /> |{" "}
                    <a
                      href="#"
                      onClick={async e => {
                        e.preventDefault();

                        const message = (await zendeskClient.get(
                          "comment.text"
                        ))["comment.text"];

                        zendeskClient.set(
                          "comment.text",
                          `${message}

Here's a link to the order page: <a href='${BRANDS[user.brand_id || "KCP"].links.order}?cc=${currencyCode}'>Click or Tap Here to Access</a>`
                        );
                      }}
                    >
                      order
                    </a>{" "}
                    |{" "}
                    <a
                      href="#"
                      onClick={async e => {
                        e.preventDefault();

                        const message = (await zendeskClient.get(
                          "comment.text"
                        ))["comment.text"];

                        zendeskClient.set(
                          "comment.text",
                          `${message}

Here's a link to the sale: <a href='${BRANDS[user.brand_id || "KCP"].links.sale}?cc=${currencyCode}'>Click or Tap Here to Access</a>`
                        );
                      }}
                    >
                      sale
                    </a>{" "}
                    |{" "}
                    <a
                      href="#"
                      onClick={async e => {
                        e.preventDefault();

                        const message = (await zendeskClient.get(
                          "comment.text"
                        ))["comment.text"];

                        zendeskClient.set(
                          "comment.text",
                          `${message}

Here's a link to learn more about our service: <a href='${BRANDS[user.brand_id || "KCP"].links.learn}?cc=${currencyCode}'>Click or Tap Here to Learn More</a>`
                        );
                      }}
                    >
                      learn
                    </a>
                  </small>
                </p>*/}
                <p className="mb-0">
                  {credits > 0 ? <small>✅</small> : <small>❌</small>} has{" "}
                  {credits} week{credits !== 1 ? "s" : ""}
                </p>
                <p className="mb-0">
                  {user.onboarding && user.onboarding.plan_requested ? (
                    <small>✅</small>
                  ) : (
                    <small>❌</small>
                  )}{" "}
                  requested initial plan
                </p>
                <p className="mb-0">
                  {user.mobile_purchase &&
                  user.mobile_purchase.has_purchased ? (
                    <small>✅</small>
                  ) : (
                    <small>❌</small>
                  )}{" "}
                  mobile purchaser
                </p>
                <MealPlanStatus {...props} user={user} />
              </Col>
              <Col xs={5}>
                {user.password_has_changed ? (
                  <>
                    <SetDefaultPassword {...props} user={user} />
                    <br />
                    <br />
                  </>
                ) : null}

                <Row>
                  <Col>
                    <AddSingleCredit {...props} user={user} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <RemoveAllCredits {...props} user={user} />
                  </Col>
                </Row>
                <br />
                <div className="mb-1">
                  <BillingSummary {...props} user={user} />
                </div>
                <div className="mb-1">
                  <RefundSubmitted {...props} user={user} />
                </div>
                <MarketingUnsubscribe {...props} user={user} />
              </Col>
            </Row>
            <Row className="m-0 mt-1 p-0">
              <Col xs={6} className="m-0 p-0" align="center">
                &nbsp;
              </Col>
              <Col xs={6} className="m-0 p-0" align="center">
                &nbsp;
              </Col>
            </Row>
          </>
        );
      }}
    </FirestoreDocument>
  );
};

export default ZendeskUser;
