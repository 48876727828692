/*
LoadingButton wrapped with the EditorStore
*/
import React from "react";
import { inject, observer } from "mobx-react";
import { LoadingButton } from "common";

const EditorLoadingButton = props => {
  const { editorStore } = props.rootStore;

  const addProps = {
    ...props
  };

  delete addProps.rootStore;

  return (
    <LoadingButton {...addProps} loading={editorStore.isSaveLoading}>
      {props.children}
    </LoadingButton>
  );
};

export default inject("rootStore")(observer(EditorLoadingButton));
