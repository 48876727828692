import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Alert
} from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import { inject, observer } from "mobx-react";

import { LoadingButton } from "common";

import User from "api/User";
import Audit from "api/Audit";
import Event from "api/Event";

import queryString from "qs";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import Log from "util/Log";
import timestamp from "util/timestamp";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeForm: "login", // login or reset
      email: "",
      password: "",
      errorMessage: null,
      successMessage: null,
      loading: false
    };
  }

  componentDidMount() {
    const { search } = this.props.location;

    if (search) {
      // See if we have a client_id, which we need to auto-select.
      let query = queryString.parse(search, { ignoreQueryPrefix: true });
      if (query.email) {
        this.setState({
          email: query.email
        });
      }
      if (query.activeForm === "reset") {
        this.setState({
          activeForm: "reset"
        });
      }
    }
  }

  // Login or send reset email.
  async _doAction() {
    this.setState({
      loading: true,
      errorMessage: null
    });

    // Reset the auth failure flag so the user can retry.
    this.props.rootStore.authFail = false;

    const { email, password, activeForm } = this.state;

    if (activeForm === "login") {
      if (password === "") {
        this.setState({
          errorMessage: "You must enter your password.",
          loading: false
        });
        return;
      }

      try {
        await firebase.auth().signInWithEmailAndPassword(email, password);
        new Audit("client").event("Login success");

        Event.send("login.success");

        console.log("Login", firebase.auth().currentUser.uid);

        // Update the login timestamp for the user.
        // Do this here so it's only set when the user click the "login" button.
        // Not every time they're authenticated.
        /*
        await new User({ uid: firebase.auth().currentUser.uid }).update({
          last_login_at: timestamp()
        });
        */
      } catch (error) {
        Log(`Incorrect login: ${email}:${password}`, error);
        Event.send("login.fail", { error });
        this.setState({
          errorMessage: "Incorrect login.",
          loading: false
        });
      }
    } else {
      // Reset password.
      if (email === "") {
        this.setState({
          errorMessage: "You must enter your email address",
          loading: false
        });
        return;
      }
      try {
        // IE is running this twice for some reason.
        // Prevent that.
        if (this.passwordResetSent) {
          return;
        }
        this.passwordResetSent = true;
        await User.sendPasswordResetEmailForEmail(email);
      } catch (e) {
        Log(`Error sending password reset: ${email}`);
        this.setState({
          errorMessage: "An unexpected error occurred.",
          loading: false
        });
        return;
      }

      this.setState({
        successMessage:
          "An email with instructions to reset your password has been sent.",
        loading: false
      });
    }
  }

  render() {
    const { rootStore } = this.props;

    if (rootStore.appReady === false) {
      return null;
    }

    if (rootStore.authSuccess) {
      return <Redirect from="/login" to="/" />;
    }

    if (!this.state.email) {
      return <Redirect from="/login" to="/start" />;
    }

    /*
    if (rootStore.authFail) {
      return <Redirect from="/login" to="/login?error=true" />;
    }
    */

    const { activeForm } = this.state;
    const isLogin = activeForm === "login";

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col sm="6">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        this._doAction();
                      }}
                    >
                      <h1>{isLogin ? "Login" : "Reset password"}</h1>
                      <p className="text-muted my-3">
                        {isLogin
                          ? "Sign In to your account"
                          : "Enter your email and we'll send you an email with instructions to reset your password."}
                      </p>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="email"
                          placeholder="Email"
                          autoComplete="email"
                          value={this.state.email}
                          onChange={(e) => {
                            this.setState({
                              email: e.target.value
                            });
                          }}
                          style={{ fontSize: 16 }}
                        />
                      </InputGroup>
                      {isLogin ? (
                        <InputGroup className="mb-4">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="icon-lock" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="password"
                            placeholder="Password"
                            autoComplete="current-password"
                            value={this.state.password}
                            autoFocus
                            onChange={(e) => {
                              this.setState({
                                password: e.target.value
                              });
                            }}
                            style={{ fontSize: 16 }}
                          />
                        </InputGroup>
                      ) : null}
                      {this.state.errorMessage ? (
                        <Alert color="danger">{this.state.errorMessage}</Alert>
                      ) : null}
                      {rootStore.authFail ? (
                        <Alert color="danger">
                          Error logging in. Please try again.
                        </Alert>
                      ) : null}
                      {this.state.successMessage ? (
                        <Alert color="success">
                          {this.state.successMessage}
                        </Alert>
                      ) : null}
                      {!this.state.successMessage ? (
                        <Row>
                          <Col xs={6}>
                            {isLogin ? (
                              <LoadingButton
                                key={"loginButton"}
                                color="primary"
                                className="px-4"
                                onClick={this._doAction.bind(this)}
                                loading={
                                  this.state.loading && !rootStore.authFail
                                }
                                type="submit"
                              >
                                Login
                              </LoadingButton>
                            ) : (
                              <LoadingButton
                                key={"resetPasswordButton"}
                                color="primary"
                                className="px-4"
                                onClick={() => {
                                  if (this.resetClicked) {
                                    return;
                                  }
                                  this.resetClicked = true;
                                  this._doAction();
                                }}
                                loading={this.state.loading}
                                type="submit"
                              >
                                Reset password
                              </LoadingButton>
                            )}
                          </Col>
                          <Col xs={6} className="text-right">
                            <Button
                              color="link"
                              className="px-0"
                              onClick={() => {
                                this.setState({
                                  activeForm: isLogin ? "reset" : "login",
                                  errorMessage: null,
                                  successMessage: null
                                });
                              }}
                            >
                              {isLogin ? (
                                <span>Forgot password?</span>
                              ) : (
                                <span>Back to login</span>
                              )}
                            </Button>
                          </Col>
                        </Row>
                      ) : null}
                    </Form>
                  </CardBody>
                </Card>
                {/*<Card
                  className="text-white bg-primary py-5 d-md-down-none"
                  style={{ width: "44%" }}
                >
                  <CardBody className="text-center">
                    <div>
                      <h2>Sign up</h2>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua.
                      </p>
                      <Link to="/register">
                        <Button
                          color="primary"
                          className="mt-3"
                          active
                          tabIndex={-1}
                        >
                          Register Now!
                        </Button>
                      </Link>
                    </div>
                  </CardBody>
                </Card>*/}
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default inject("rootStore")(observer(Login));
