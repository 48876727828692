import qs from "qs";

/**
Merge the passed object with the current location.search and return a valid ?key=value string.

object with a value of [] or {} will remove it from the query string. null or blank will be key={BLANK}

*/
const add = (obj) => {
  const search = window.location.search;
  let newObj = {};
  if (search) {
    // remove the ?.
    newObj = {
      ...qs.parse(search, { ignoreQueryPrefix: true })
    };
  }
  newObj = {
    ...newObj,
    ...obj
  };

  return `?${qs.stringify(newObj)}`;
};

const get = () => {
  return qs.parse(window.location.search, { ignoreQueryPrefix: true }) || {};
};

const exportable = {
  add,
  get
};

export default exportable;
