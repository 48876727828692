// https://github.com/green-arrow/react-firestore/blob/master/src/FirestoreCollection.js

import React from "react";

import Firestore from "util/Firestore";

import _ from "underscore";

class FirestoreCollection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      data: null,
      error: null
    };
  }

  componentDidMount() {
    this._setupListener(this.props);
  }

  componentWillUnmount() {
    this._handleUnsubscribe();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.collection !== this.props.collection ||
      nextProps.sort !== this.props.sort ||
      !_.isEqual(nextProps.filter, this.props.filter) ||
      nextProps.startAt !== this.props.startAt ||
      nextProps.endAt !== this.props.endAt
    ) {
      // Unsubscribe and reload.
      this._handleUnsubscribe();
      this.setState({ isLoading: true }, () => this._setupListener(nextProps));
      return;
    }

    if (nextProps.limit !== this.props.limit) {
      // only limit is changing. dont reload.
      this._handleUnsubscribe();
      this._setupListener(nextProps);
    }
  }

  _setupListener(useProps) {
    const { collection, disableUpdates, ...queryProps } = useProps;
    let collectionRef = Firestore.db.collection(collection);
    if (useProps.collectionGroup === true) {
      collectionRef = Firestore.db.collectionGroup(collection);
    }
    const query = this._buildQuery(collectionRef, queryProps);

    this.unsubscribe = query.onSnapshot(
      (snapshot) => {
        if (snapshot) {
          if (snapshot.metadata.hasPendingWrites) {
            //return;
          }
          const newState = {
            isLoading: false,
            data: snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data()
            }))
          };
          this.setState(newState);
          if (this.props.onSnapshot) {
            this.props.onSnapshot(newState);
          }
          if (disableUpdates) {
            this._handleUnsubscribe();
          }
        }
      },
      (error) => {
        console.error(error);
        // Error
        this.setState({
          isLoading: false,
          error,
          data: null
        });
      }
    );
  }

  _buildQuery(collectionRef, queryProps) {
    const { sort, limit, filter, startAt, endAt } = queryProps;
    let query = collectionRef;

    if (sort) {
      sort.split(",").forEach((sortItem) => {
        const [field, order] = sortItem.split(":");

        query = query.orderBy(field, order);
      });
    }

    if (limit) {
      query = query.limit(limit);
    }

    if (startAt) {
      query = query.startAt(startAt);
    }

    if (endAt) {
      query = query.endAt(endAt);
    }

    if (filter && filter.length > 0) {
      //if filter is array of array, build the compound query
      if (Array.isArray(filter[0])) {
        filter.forEach((clause) => {
          query = query.where(...clause);
        });
      } else {
        //build the simple query
        query = query.where(...filter);
      }
    }

    return query;
  }

  _handleUnsubscribe() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  render() {
    return this.props.children(this.state);
  }
}

export default FirestoreCollection;
