import Chat from "./Chat";

class ChatMessage {
  constructor(chatId, threadId, messageId) {
    this.chatId = chatId;
    this.threadId = threadId;
    this.messageId = messageId;
  }

  chat() {
    return new Chat(this.chatId, this.threadId);
  }

  colRef() {
    return this.chat().threadDocRef().collection("messages");
  }

  docRef() {
    return this.colRef().doc(this.messageId);
  }

  /**
   * Set is_internal
   */
  setIsInternal(isInternal) {
    return this.docRef().update({
      is_internal: isInternal
    });
  }

  /**
   * Set is_deleted
   */
  setIsDeleted(isDeleted) {
    return this.docRef().update({
      is_deleted: isDeleted
    });
  }
}

export default ChatMessage;
