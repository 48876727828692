import React from "react";

import { Alert } from "reactstrap";

const AccessLinkWarningAlert = (props) => {
  const { email } = props;

  return (
    <Alert
      color="warning"
      className="mb-0"
      style={{ borderRadius: 0, borderBottomWidth: "medium" }}
    >
      This page is for <strong>{email}</strong> only. If this is not you,{" "}
      <a href="#" style={{ color: "#856404", textDecoration: "underline" }}>
        please click here
      </a>
    </Alert>
  );
};

export default AccessLinkWarningAlert;
