import React, { useState, useEffect, useRef } from "react";

import { InputGroup, Input, Button } from "reactstrap";
import { KeyDetector, LoadingButton } from "common";

import TextareaAutosize from "react-autosize-textarea";
import Measure from "react-measure";

import ChatAPI from "api/Chat";

import PartnerUserControls from "./PartnerUserControls";
import MessageInputContext from "./MessageInputContext";

const MessageInput = (props) => {
  const {
    rows,
    disableAutosize, // boolean - true to disable autosizer.
    chatType, // type of the chat "plan" or "billing"
    chatId, // ID of the chat/userid
    mealPlanId, //
    dayId,
    mealId, // id of the meal.
    onMessageSend,
    disableEnter,
    focusActive,
    onResize,
    renderBeforeInput // function(messageInputContext) - return a component to render before the input.
  } = props;

  // Settings for submitting a new message.
  //const [messageSettings, setMessageSettings] = useState({});

  const [enterDisabled, setEnterDisabled] = useState(true);
  //const [isLoading,setIsLoading]=useState(false);
  //const [comment, setComment] = useState("");

  const textAreaRef = useRef();

  useEffect(() => {
    textAreaRef.current.focus();
  }, [textAreaRef.current]);

  useEffect(() => {
    if (focusActive) {
      textAreaRef.current.focus();
    }
  }, [focusActive]);

  const chat = new ChatAPI(
    chatId,
    ChatAPI.getThreadId({
      type: chatType,
      plan_id: mealPlanId,
      day_id: dayId,
      meal_id: mealId
    })
  );

  const submitMessage = async (messageInputContext) => {
    textAreaRef.current.focus();

    const contextValue = messageInputContext.value;

    const hasMetadata =
      contextValue.metadata && Object.keys(contextValue.metadata).length > 0;

    const hasText = contextValue.text && contextValue.text !== "";

    if (!hasText && !hasMetadata) {
      return;
    }

    let text = contextValue.text;

    if (hasText) {
      text = text.trim();
    }

    messageInputContext.update({
      text: "",
      metadata: {}
    });

    const messageObject = {
      ...contextValue
    };

    if (!hasText) {
      delete messageObject.text;
    }

    await chat.sendMessage(messageObject);

    onMessageSend(messageInputContext);

    //setIsLoading(false);
  };

  return (
    <MessageInputContext.Consumer>
      {(messageInputContext) => {
        const { value } = messageInputContext;

        return (
          <Measure
            bounds
            onResize={(contentRect) => {
              if (onResize) {
                onResize(contentRect);
              }
            }}
          >
            {({ measureRef }) => {
              return (
                <div ref={measureRef}>
                  {renderBeforeInput
                    ? renderBeforeInput(messageInputContext)
                    : null}
                  <InputGroup
                    className={`p-2`}
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: 5,
                      background: value.is_internal ? "#fff3cd" : "#FFF"
                    }}
                  >
                    {disableAutosize ? (
                      <Input
                        innerRef={textAreaRef}
                        type="text"
                        className={`form-control ${
                          value.is_internal ? "alert-warning" : ""
                        }`}
                        placeholder="Your message..."
                        onChange={(e) => {
                          //setComment(e.target.value);
                          messageInputContext.update({
                            text: e.target.value
                          });
                        }}
                        value={value.text}
                        style={{ border: "none", fontSize: 16 }}
                        onFocus={() => {
                          setEnterDisabled(false);
                        }}
                        onBlur={() => {
                          setEnterDisabled(true);
                        }}
                      />
                    ) : (
                      <TextareaAutosize
                        ref={textAreaRef}
                        rows={rows}
                        maxRows={rows}
                        className={`form-control ${
                          value.is_internal ? "alert-warning" : ""
                        }`}
                        placeholder="Your message..."
                        onChange={(e) => {
                          //setComment(e.target.value);\

                          messageInputContext.update({
                            text: e.target.value
                          });
                        }}
                        value={value.text}
                        style={{ border: "none" }}
                        onFocus={() => {
                          setEnterDisabled(false);
                        }}
                        onBlur={() => {
                          setEnterDisabled(true);
                        }}
                      />
                    )}

                    <Button
                      color={value.is_internal ? "warning" : "primary"}
                      onClick={() => {
                        submitMessage(messageInputContext);
                      }}
                    >
                      Send
                    </Button>
                  </InputGroup>

                  <PartnerUserControls />
                  <KeyDetector.Component
                    isDisabled={disableEnter ? true : enterDisabled}
                    useKey={KeyDetector.ENTER}
                    onPress={(e) => {
                      e.preventDefault();
                      submitMessage(messageInputContext);
                    }}
                  />
                </div>
              );
            }}
          </Measure>
        );
      }}
    </MessageInputContext.Consumer>
  );
};

export default MessageInput;
