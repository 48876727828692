import React, { useState, useEffect } from "react";
import {
  Alert,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Container
} from "reactstrap";
import {
  Template,
  Loading,
  GSImage,
  MealPlanDocument,
  MealPlanShoppingDocument
} from "common";

import Image from "api/Image";
import MealPlanShopping from "api/MealPlanShopping";

import queryString from "qs";
import _ from "underscore";

import Backend from "util/Backend";

import "./GroceryList.css";

const GroceryList = (props) => {
  const [groceries, setGroceries] = useState(null);
  const [printingOptions, setPrintingOptions] = useState({
    hideImages: false,
    hideCheckboxes: false
  });
  const [error, setError] = useState(false);

  const { id } = props.match.params;
  let { week } = queryString.parse(props.location.search, {
    ignoreQueryPrefix: true
  });

  let newWeeklyPlan = false;

  // New plans dont go by week.
  if (!week) {
    week = "";
    newWeeklyPlan = true;
  }

  useEffect(() => {
    const fetchGroceries = async () => {
      const response = await Backend.get(
        `meal_plans/${id}/groceries?week=${week}`
      );

      if (response.success) {
        setGroceries(response.data);
      } else {
        setError(true);
      }
    };

    fetchGroceries();
  }, []);

  const loader = (
    <div style={{ marginTop: 50 }}>
      <center>
        <Loading />
      </center>
    </div>
  );

  return (
    <div
      className="app flex-row"
      style={{ fontFamily: "monospace", background: "#FFF" }}
    >
      <Container>
        {newWeeklyPlan ? (
          <div
            style={{
              background: "#F7F7F7",
              border: "1px solid #ccc",
              borderRadius: "0px 0px 5px 5px",
              padding: 20,
              marginBottom: 20,
              borderTop: 0
            }}
            className="no-print"
          >
            <strong>Printing Options</strong>
            <br />
            <br />
            <PrintingOptions
              printingOptions={printingOptions}
              onChange={(newPrintingOptions) => {
                setPrintingOptions(newPrintingOptions);
              }}
            />
          </div>
        ) : null}
        {!groceries && !error ? loader : null}
        {error ? <Alert color="danger">An error occurred.</Alert> : null}
        {groceries ? (
          <MealPlanDocument id={id}>
            {({ isLoading, data: mealPlanDoc }) => {
              if (isLoading) {
                return loader;
              }
              return (
                <MealPlanShoppingDocument id={id}>
                  {({ isLoading, data }) => {
                    if (isLoading) {
                      return loader;
                    }
                    return (
                      <Groceries
                        mealPlanId={id}
                        userDetails={{
                          uid: mealPlanDoc.uid,
                          partner_id: mealPlanDoc.partner_id,
                          brand_id: mealPlanDoc.brand_id
                        }}
                        groceries={groceries}
                        shopping={data?.items}
                        printingOptions={printingOptions}
                        shoppingDisabled={!newWeeklyPlan}
                      />
                    );
                  }}
                </MealPlanShoppingDocument>
              );
            }}
          </MealPlanDocument>
        ) : null}
      </Container>
    </div>
  );
};

const PrintingOptions = (props) => {
  const { printingOptions, onChange } = props;

  return (
    <>
      {/*<FormGroup check className="checkbox">
        <Input
          className={"form-check-input"}
          type="checkbox"
          id={"hide-images"}
          name={"hide-images"}
          checked={printingOptions.hideImages}
          onChange={(e) => {
            onChange({
              ...printingOptions,
              hideImages: e.target.checked
            });
          }}
        />
        <Label check className={"form-check-label"} htmlFor={"hide-images"}>
          Hide Images
        </Label>
      </FormGroup>*/}
      <FormGroup check className="checkbox">
        <Input
          className={"form-check-input"}
          type="checkbox"
          id={"hide-checkboxes"}
          name={"hide-checkboxes"}
          checked={printingOptions.hideCheckboxes}
          onChange={(e) => {
            onChange({
              ...printingOptions,
              hideCheckboxes: e.target.checked
            });
          }}
        />
        <Label check className={"form-check-label"} htmlFor={"hide-checkboxes"}>
          Hide Checkboxes
        </Label>
      </FormGroup>
    </>
  );
};

const Groceries = (props) => {
  const {
    mealPlanId,
    userDetails, // {uid,partner_id,brand_id}
    groceries,
    shopping, // {groceryItemId: boolean}
    printingOptions,
    shoppingDisabled
  } = props;

  /*
   Groceries are formated like:
   AisleName: [
    {image, display}
  ]
  */

  const handleCheckboxClick = (groceryItemId) => {
    if (window.user_id.startsWith(userDetails.uid) === false) {
      return;
    }
    if (shoppingDisabled) {
      return;
    }

    const isChecked = shopping && shopping[groceryItemId];
    const mealPlanShopping = new MealPlanShopping(mealPlanId);
    mealPlanShopping.updateGroceryId({
      groceryId: groceryItemId,
      isSelected: !isChecked,
      userDetails
    });
  };

  return Object.entries(groceries).map(([aisle, itemList], idx) => {
    let itemColumns = _.chunk(itemList, 4);

    return (
      <div key={idx} className="keep-together">
        <h4 style={{ marginTop: 25 }}>{aisle}</h4>
        {/*aisle === "Spices and Seasonings" ||
        aisle === "Produce;Spices and Seasonings" ? (
          <i>These items have low nutritional value and are optional</i>
        ) : null*/}
        <hr />
        {itemColumns.map((item, idx) => {
          return (
            <Row
              key={idx}
              className="mb-1"
              style={{ pageBreakInside: "avoid" }}
            >
              {item.map(
                (
                  { display_name, display_weight, grocery_item_id, image },
                  idx2
                ) => {
                  return (
                    <Col key={idx2} xs={12} sm={3} className="mb-1">
                      <Row>
                        <Col
                          xs="auto"
                          className="no-print"
                          style={{ paddingRight: 0 }}
                        >
                          <div
                            style={{
                              height: 35,
                              width: 35,
                              overflow: "hidden",
                              borderRadius: 5,
                              backgroundImage: `url(${new Image(
                                image
                              ).getResizeableURL({
                                width: 35,
                                height: 35,
                                fit: "cover"
                              })}`,
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              cursor: "pointer"
                            }}
                            onClick={() => {
                              handleCheckboxClick(grocery_item_id);
                            }}
                          />
                        </Col>

                        <Col style={{ display: "flex", flexDirection: "row" }}>
                          {shoppingDisabled ? null : (
                            <div
                              style={{
                                display: "flex",
                                width: 20,
                                height: 20,
                                border: "1px solid #ccc",
                                float: "left",
                                marginRight: 15,
                                alignItems: "center",
                                justifyContent: "center",
                                color: "green",
                                cursor: "pointer",
                                fontSize: 24
                              }}
                              className={
                                printingOptions.hideCheckboxes ? "no-print" : ""
                              }
                              onClick={() => {
                                handleCheckboxClick(grocery_item_id);
                              }}
                            >
                              {shopping && shopping[grocery_item_id] ? (
                                <span>✔</span>
                              ) : null}
                            </div>
                          )}
                          <div>
                            <h5
                              onClick={() => {
                                handleCheckboxClick(grocery_item_id);
                              }}
                              style={{ cursor: "pointer", fontSize: 14 }}
                            >
                              {display_name}
                            </h5>
                            {display_weight ? (
                              <p style={{ fontSize: 12 }}>• {display_weight}</p>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  );
                }
              )}
            </Row>
          );
        })}
      </div>
    );
  });
};

export default GroceryList;
