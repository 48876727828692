/*

A selector for exclusions.

Props:
- onChange - called with Array<String> of all selected options.
- exclusions - Array<String> all selected exclusions.
- disabled - boolean - disable all the checkboxes.
- disabledExclusions - Array<String> optional - individual exclusions that are disabled.
- hideDisabledExclusions - boolean
- useIcon - boolean - use an icon for the checkbox.
- strikethrough - boolean - add a strikethrough for the label when checked.
- disableHerbs - boolean - optional - hide herbs from selection
- disableSpices - boolean - optional - hide spices from selection

Idea from: https://medium.com/tripaneer-techblog/improving-the-usability-of-multi-selecting-from-a-long-list-63e1a67aab35

*/
import React, { useState } from "react";
import { Col, Row, FormGroup, Input, Label } from "reactstrap";
import _ from "underscore";
import data from "./exclusionsData";

import styles from "./ExclusionsSelector.module.css";

const ExclusionsSelector = (props) => {
  const {
    exclusions,
    disabled,
    disabledExclusions,
    hideDisabledExclusions,
    useIcon,
    strikethrough
  } = props;
  // Return suboptions for a category, "category.suboption"
  const getOptions = (category) => {
    return Object.keys(data[category]).map((option) => `${category}.${option}`);
  };

  // Render an excludable section
  const renderSection = (title, category) => {
    const options = getOptions(category);

    // The main category cannot be selected if any of the options are disabled.

    return (
      <Section
        title={title}
        canExcludeMainCategory={
          _.intersection(options, disabledExclusions).length < 1
        }
        mainCategoryValue={category}
        options={options}
        selected={props.exclusions}
        onChange={props.onChange}
        disabled={disabled}
        disabledOptions={disabledExclusions}
        hideDisabledOptions={hideDisabledExclusions}
        useIcon={useIcon}
        strikethrough={strikethrough}
      />
    );
  };

  return (
    <>
      <Section
        title="Common"
        canExcludeMainCategory={false}
        mainCategoryValue="common"
        options={["gluten", "eggs", "avocado", "coffee", "chocolate"]}
        selected={exclusions}
        onChange={props.onChange}
        disabled={disabled}
        disabledOptions={disabledExclusions}
        hideDisabledOptions={hideDisabledExclusions}
        useIcon={useIcon}
        strikethrough={strikethrough}
      />
      {renderSection("Dairy", "dairy")}
      {renderSection("Cheese", "cheese")}
      {renderSection("Protein Powder", "protein powder")}
      {renderSection("Beef", "beef")}
      {renderSection("Lamb", "lamb")}
      {renderSection("Pork", "pork")}
      {renderSection("Chicken", "chicken")}
      {renderSection("Turkey", "turkey")}
      {renderSection("Fish", "fish")}
      {renderSection("Shellfish", "shellfish")}
      {renderSection("Grains", "grains")}
      {renderSection("Pasta", "pasta")}
      {renderSection("Breads", "breads")}
      {renderSection("Legumes", "legumes")}
      {renderSection("Soy", "soy")}
      {renderSection("Sweeteners", "sweeteners")}
      {renderSection("Nuts", "nuts")}
      {renderSection("Seeds", "seeds")}
      <Section
        title="Vegetables"
        canExcludeMainCategory={false}
        mainCategoryValue="vegetables"
        options={getOptions("vegetables")}
        selected={exclusions}
        onChange={props.onChange}
        disabled={disabled}
        disabledOptions={disabledExclusions}
        hideDisabledOptions={hideDisabledExclusions}
        useIcon={useIcon}
        strikethrough={strikethrough}
      />
      {renderSection("Starchy vegetables", "starchy vegetables")}
      {renderSection("Oil and Vinegar", "oil and vinegar")}
      {renderSection("Cooking alcohols", "cooking alcohol")}
      {props.disableHerbs ? null : renderSection("Herbs", "herbs")}
      {props.disableSpices ? null : renderSection("Spices", "spices")}
      {renderSection("Condiments", "condiments")}
      {renderSection("Fruit", "fruit")}
    </>
  );
};

/*
Create an exclusions section. Title and box of checkboxes.

This is a controlled component.

Props:
- canExcludeMainCategory - boolean - can exclude the main category.
- mainCategoryValue - string - the value of the main category, if able to exclude it.
- options - Array<string> - the options to display.
- selected - Array<string> - current selected options.
- onChange - {value, isSelected}
- disabledOptions - Array<string> optional - options that are disabled. These cannot be modified, even if the main category is deselected.
- hideDisabledOptions - boolean - hide options if they are disabled.
- useIcon - boolean - use an icon for the checkboxes.
- strikethrough - boolean - strikethrough the label when checked.

*/
const Section = (props) => {
  const { selected, useIcon, strikethrough } = props;
  let { options } = props;
  const disabledOptions = props.disabledOptions || [];

  let colA = [],
    colAxs = [],
    colB = [],
    colBxs = [],
    colC = [],
    colCxs = [],
    colD = [],
    colDxs = [];

  if (options) {
    // If hideDisableOptions, remove disabled options so they are not shown.
    if (props.hideDisabledOptions) {
      options = options.filter((option) => {
        return !disabledOptions.includes(option);
      });
    }

    options.sort();

    // Sort into 4 columns for non-small screens.

    let length = options.length;
    let columns = 4;

    let optionsPerColumn = Math.ceil(length / columns) || 1;

    for (const option of options) {
      if (colA.length < optionsPerColumn) {
        colA.push(option);
      } else if (colB.length < optionsPerColumn) {
        colB.push(option);
      } else if (colC.length < optionsPerColumn) {
        colC.push(option);
      } else if (colD.length < optionsPerColumn) {
        colD.push(option);
      }
    }

    // Sort into 2 columns for small screens.

    columns = 2;
    optionsPerColumn = Math.ceil(length / columns) || 1;
    for (const option of options) {
      if (colAxs.length < optionsPerColumn) {
        colAxs.push(option);
      } else if (colBxs.length < optionsPerColumn) {
        colBxs.push(option);
      } else if (colCxs.length < optionsPerColumn) {
        colCxs.push(option);
      } else if (colDxs.length < optionsPerColumn) {
        colDxs.push(option);
      }
    }
  }

  const sel = new Set(selected || []);
  const mainSelected = sel.has(props.mainCategoryValue);

  const onChangeHandler = ({ value, checked }) => {
    // Modify the list of selected
    let newSelected = new Set(selected);

    if (value === props.mainCategoryValue) {
      if (checked) {
        // Adding main category.
        newSelected = new Set([props.mainCategoryValue]);
      } else {
        // Removing main category
        newSelected = new Set([]);
      }
    } else {
      // Not the main category.

      if (!checked) {
        if (!newSelected.has(value)) {
          // Value is deselected, but is not in the array, so it was selected via a main category.
          // Reset the set to all options.
          newSelected = new Set(options);
        }

        newSelected.delete(value);
      } else {
        newSelected.add(value);
      }

      // If all options are selected:
      // the main category should be added, and all sub options removed.
      // Only if canExcludeMainCategory
      if (
        _.difference(options, [...newSelected]).length < 1 &&
        props.canExcludeMainCategory
      ) {
        // All options exist in the newSelected array.
        // So they should all be removed and the mainCategoryValue added instead.
        newSelected = new Set([props.mainCategoryValue]);
      }
    }

    // Get all other selections that arent relevant to this section.

    let otherSelections = [];

    if (selected) {
      // Item is not a mainCategoryValue and not in the list of options.
      // So it's not apart of this section.
      otherSelections = selected.filter(
        (x) => x !== props.mainCategoryValue && !new Set(options).has(x)
      );
    }

    // Add the irrelvant back along with the new selections.
    // Make the entries unique.
    props.onChange([...new Set([].concat([...newSelected], otherSelections))]);
  };

  if (
    props.hideDisabledOptions &&
    disabledOptions.includes(props.mainCategoryValue)
  ) {
    // Main category is disabled and we want to hide it.
    return null;
  }

  return (
    <>
      <Row className="mb-2">
        <Col>
          <Checkbox
            checkboxHidden={!props.canExcludeMainCategory}
            title={<strong>{props.title}</strong>}
            value={props.mainCategoryValue}
            checked={mainSelected}
            onChange={onChangeHandler}
            disabled={
              props.disabled ||
              disabledOptions.includes(props.mainCategoryValue)
            }
            useIcon={useIcon}
            strikethrough={strikethrough}
          />
        </Col>
      </Row>
      {options && options.length > 0 ? (
        <Row
          style={{
            marginLeft: 0,
            marginRight: 0,
            marginTop: 5,
            border: "1px solid #ddd",
            borderRadius: 3
          }}
          className="py-2 mb-3"
        >
          {/* Hidden on xs, 3 col width */}
          <Col sm={3} className="d-none d-sm-block">
            {colA.map((value, idx) => {
              return (
                <Checkbox
                  key={idx}
                  value={value}
                  title={value}
                  checked={mainSelected || sel.has(value)}
                  onChange={onChangeHandler}
                  disabled={
                    props.disabled ||
                    disabledOptions.includes(props.mainCategoryValue) ||
                    disabledOptions.includes(value)
                  }
                  useIcon={useIcon}
                  strikethrough={strikethrough}
                />
              );
            })}
          </Col>
          <Col sm={3} className="d-none d-sm-block">
            {colB.map((value, idx) => {
              return (
                <Checkbox
                  key={idx}
                  value={value}
                  title={value}
                  checked={mainSelected || sel.has(value)}
                  onChange={onChangeHandler}
                  disabled={
                    props.disabled ||
                    disabledOptions.includes(props.mainCategoryValue) ||
                    disabledOptions.includes(value)
                  }
                  useIcon={useIcon}
                  strikethrough={strikethrough}
                />
              );
            })}
          </Col>
          <Col sm={3} className="d-none d-sm-block">
            {colC.map((value, idx) => {
              return (
                <Checkbox
                  key={idx}
                  value={value}
                  title={value}
                  checked={mainSelected || sel.has(value)}
                  onChange={onChangeHandler}
                  disabled={
                    props.disabled ||
                    disabledOptions.includes(props.mainCategoryValue) ||
                    disabledOptions.includes(value)
                  }
                  useIcon={useIcon}
                  strikethrough={strikethrough}
                />
              );
            })}
          </Col>
          <Col sm={3} className="d-none d-sm-block">
            {colD.map((value, idx) => {
              return (
                <Checkbox
                  key={idx}
                  value={value}
                  title={value}
                  checked={mainSelected || sel.has(value)}
                  onChange={onChangeHandler}
                  disabled={
                    props.disabled ||
                    disabledOptions.includes(props.mainCategoryValue) ||
                    disabledOptions.includes(value)
                  }
                  useIcon={useIcon}
                  strikethrough={strikethrough}
                />
              );
            })}
          </Col>
          {/* Hidden on all but xs with 6 col width */}
          <Col xs={6} className="d-block d-sm-none">
            {colAxs.map((value, idx) => {
              return (
                <Checkbox
                  key={idx}
                  value={value}
                  title={value}
                  checked={mainSelected || sel.has(value)}
                  onChange={onChangeHandler}
                  disabled={
                    props.disabled ||
                    disabledOptions.includes(props.mainCategoryValue) ||
                    disabledOptions.includes(value)
                  }
                  useIcon={useIcon}
                  strikethrough={strikethrough}
                />
              );
            })}
          </Col>
          <Col xs={6} className="d-block d-sm-none">
            {colBxs.map((value, idx) => {
              return (
                <Checkbox
                  key={idx}
                  value={value}
                  title={value}
                  checked={mainSelected || sel.has(value)}
                  onChange={onChangeHandler}
                  disabled={
                    props.disabled ||
                    disabledOptions.includes(props.mainCategoryValue) ||
                    disabledOptions.includes(value)
                  }
                  useIcon={useIcon}
                  strikethrough={strikethrough}
                />
              );
            })}
          </Col>
          <Col xs={6} className="d-block d-sm-none">
            {colCxs.map((value, idx) => {
              return (
                <Checkbox
                  key={idx}
                  value={value}
                  title={value}
                  checked={mainSelected || sel.has(value)}
                  onChange={onChangeHandler}
                  disabled={
                    props.disabled ||
                    disabledOptions.includes(props.mainCategoryValue) ||
                    disabledOptions.includes(value)
                  }
                  useIcon={useIcon}
                  strikethrough={strikethrough}
                />
              );
            })}
          </Col>
          <Col xs={6} className="d-block d-sm-none">
            {colDxs.map((value, idx) => {
              return (
                <Checkbox
                  key={idx}
                  value={value}
                  title={value}
                  checked={mainSelected || sel.has(value)}
                  onChange={onChangeHandler}
                  disabled={
                    props.disabled ||
                    disabledOptions.includes(props.mainCategoryValue) ||
                    disabledOptions.includes(value)
                  }
                  useIcon={useIcon}
                  strikethrough={strikethrough}
                />
              );
            })}
          </Col>
        </Row>
      ) : null}
    </>
  );
};

/**
* Create a checkbox. Controlled component.

Props:
- checked - boolean - checkbox is checked.
- checkboxHidden - boolean - hide the checkbox
- onChange - function called with object {value, checked}
- disabled - disable the checkbox
- useIcon - boolean - use an icon for the checkbox when selected.
- strikethrough - boolean - strikethrough the label when selected.

*/
const Checkbox = (props) => {
  // generate an ID so we can have multiple exclusions selectors on a single Page
  // this lets labels work and be clickable for their assigend checkbox.
  // if it appears in a modal and on the page (like meal plan create)
  const [id, setId] = useState(Math.round(Math.random() * 1000));

  let containerClasses = ["checkbox", styles.checkboxGroup];

  if (props.checkboxHidden) {
    // Remove the padding on the left so the label aligns properly.
    containerClasses.push("pl-0");
    containerClasses.push(styles.noSelect);
  }

  let displayTitle = props.title;
  if (typeof props.title === "string" && props.title.split(".").length > 1) {
    displayTitle = props.title.split(".")[1];
  }

  let labelStyle = {};

  if (props.strikethrough && props.checked) {
    labelStyle = { textDecoration: "line-through" };
  }

  return (
    <FormGroup check className={containerClasses.join(" ")}>
      {props.checkboxHidden ? null : (
        <>
          <Input
            className={["form-check-input", styles.checkboxInput].join(" ")}
            type="checkbox"
            id={`${id}-${props.value}`}
            name={`${id}-${props.value}`}
            value={props.value}
            checked={props.checked}
            onChange={(e) => {
              props.onChange({ value: props.value, checked: e.target.checked });
            }}
            disabled={props.disabled}
            style={{ display: props.checked && props.useIcon ? "none" : null }}
          />
          {props.checked && props.useIcon ? (
            <span
              style={{
                fontSize: 10,
                marginLeft: "-1.25rem",
                position: "absolute",
                top: 7
              }}
            >
              ❌
            </span>
          ) : null}
        </>
      )}
      <Label
        check
        className={[
          "form-check-label",
          styles.capitalize,
          props.disabled ? styles.disabled : ""
        ].join(" ")}
        htmlFor={`${id}-${props.value}`}
        style={labelStyle}
      >
        {displayTitle}
      </Label>
    </FormGroup>
  );
};

export default ExclusionsSelector;
