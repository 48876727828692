import React, { useEffect, useState } from "react";

const IntervalRefresh = (props) => {
  const {
    interval // interval in ms
  } = props;

  const [random, setRandom] = useState(0);

  useEffect(() => {
    const i = setInterval(() => {
      setRandom(Math.random());
    }, interval || 1000);
    return () => {
      clearInterval(i);
    };
  }, []);

  return props.children(random);
};

export default IntervalRefresh;
