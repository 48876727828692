/*
Render child function with list of hits from algolia.
*/

import { connectHits } from "react-instantsearch-dom";

const ResultList = connectHits((props) => {
  return props.children(props.hits);
});

export default ResultList;
