import Firestore from "util/Firestore";

import timestamp from "util/timestamp";

import User from "api/User";

class Chat {
  constructor(userId, threadId) {
    this.userId = userId;
    this.threadId = threadId;
  }

  static getThreadId({ type, plan_id, day_id, meal_id, food_index }) {
    if (type === "plan") {
      return `plan-${plan_id}-${day_id || ""}-${meal_id ? meal_id : ""}-${
        isNaN(food_index) ? "" : food_index
      }`;
    }
    return type;
  }

  static getObjectFromThreadString(threadId) {
    const p = threadId.split("-");
    if (p[0] === "plan") {
      return {
        type: p[0],
        plan_id: p[1],
        day_id: p[2],
        meal_id: p[3] || "",
        food_index: p[4] || ""
      };
    }
    return { type: p[0] };
  }

  static chatsColRef() {
    return Firestore.db.collection("chats");
  }

  chatDocRef() {
    return Chat.chatsColRef().doc(this.userId);
  }

  threadsColRef() {
    return this.chatDocRef().collection("threads");
  }

  threadDocRef() {
    return this.threadsColRef().doc(this.threadId);
  }

  messagesColRef() {
    return this.threadDocRef().collection("messages");
  }

  updateStatus(newStatus) {
    let promises = [];

    promises.push(
      this.chatDocRef().set(
        {
          active_threads: {
            [this.threadId]:
              newStatus === "solved" ? Firestore.FieldValue.delete() : newStatus
          },
          ...(newStatus === "wait_for_client"
            ? {
                wait_for_client_unviewed: {
                  [this.threadId]: true
                },
                wait_for_client_last_at: timestamp()
              }
            : {}),
          ...(newStatus !== "solved"
            ? { is_active: true }
            : {
                /* we don't know that it's not active... */
              }),
          updated_at: timestamp()
        },
        { merge: true }
      )
    );

    const activeUsersObject = {
      [window.user_id]: true
    };

    const userDetails = User.getUserDetailsFromUserId(this.userId);

    promises.push(
      this.threadDocRef().set(
        {
          status: newStatus,
          updated_at: timestamp(),
          // not used: is_active: newStatus !== "solved",
          active_users: activeUsersObject,
          // If solved:
          // - remove the need_help_from arary.
          // - is_active = false
          // otherwise:
          // - is_active = true
          ...(newStatus === "solved"
            ? {
                need_help_from: Firestore.FieldValue.delete(),
                is_active: false
              }
            : { is_active: true }),
          // If wait_for_client:
          // - update wait_for_client_at
          ...(newStatus === "wait_for_client"
            ? {
                wait_for_client_at: timestamp()
              }
            : {})
          //message_count: Firestore.FieldValue.increment(1)
        },
        { merge: true }
      )
    );

    if (newStatus === "solved") {
      const message = {
        text: "marked as solved",
        is_deleted: false,
        is_internal: true,
        created_at: timestamp(true),
        user_id: window.user_id
      };

      promises.push(this.messagesColRef().add(message));
    }
    return Promise.all(promises);
  }

  sendMessage({
    text, // string - text in the message
    reply_to_id, // string - message id this is a reply to
    is_internal, // boolean - is internal message
    is_system, // boolean - is this a system message (not belonging to a user). will not reopen.
    is_planchange, // boolean - is this to record a change to a plan. will not reopen.
    force_open_status, // boolean - when true, forces an open status.
    //new_status, // string - a new status to set.
    metadata // object - any data.
  }) {
    // Queue promises so the UI updates fast.

    const userDetails = User.getUserDetailsFromUserId(this.userId);

    let promises = [];

    let setStatusOpen = true;
    if (is_system || is_planchange || is_internal) {
      setStatusOpen = false;
    }

    if (force_open_status) {
      setStatusOpen = true;
    }

    if (setStatusOpen) {
      promises.push(
        this.chatDocRef().set(
          {
            active_threads: {
              [this.threadId]: "open"
            },
            is_active: true,
            updated_at: timestamp()
          },
          { merge: true }
        )
      );
    }

    const activeUsersObject = {};

    activeUsersObject[window.user_id] = true;

    /*
    const clientMessageUnreadCount = {};
    if (window.is_partner && !is_system && !is_internal) {
      clientMessageUnreadCount.client_message_unread_count = Firestore.FieldValue.increment(
        1
      );
    }
    */

    promises.push(
      this.threadDocRef().set(
        {
          ...(setStatusOpen ? { status: "open", is_active: true } : {}),
          ...(is_planchange ? { has_plan_change: true } : {}),
          type: Chat.getObjectFromThreadString(this.threadId).type,
          updated_at: timestamp(),
          uid: userDetails.uid,
          partner_id: userDetails.partner_id,
          brand_id: userDetails.brand_id,
          active_users: activeUsersObject,
          ...(!is_system && !is_planchange
            ? {
                message_count: Firestore.FieldValue.increment(1)
              }
            : {}),
          last_message_at: timestamp() - 1 // remove a second so the UI doesnt show "in a few seconds". Real accuracy isnt important.
          //...clientMessageUnreadCount
        },
        { merge: true }
      )
    );

    // Add is_* keys to every message so we can query based off those.
    const message = {
      is_deleted: false,
      is_internal: is_internal ? true : false,
      is_planchange: is_planchange ? true : false,
      created_at: timestamp(true)
    };

    if (text) {
      message.text = text;
    }

    if (reply_to_id) {
      message.reply_to_id = reply_to_id;
    }

    if (is_system) {
      message.user_id = "system";
    } else {
      message.user_id = window.user_id;
    }

    if (metadata) {
      message.metadata = metadata;
    }

    promises.push(this.messagesColRef().add(message));

    return Promise.all(promises);
  }

  /**
   * Called when client views thread.
   */
  clientViewedThread() {
    console.log(`wait_for_client_unviewed.${this.threadId}`);
    this.chatDocRef().update({
      [`wait_for_client_unviewed.${this.threadId}`]: Firestore.FieldValue.delete()
    });

    this.threadDocRef().update({
      client_last_viewed_at: timestamp()
    });
  }

  /*
   * Get a chat assignment for a user.
   * Return the chatId.
   */
  static async getAssignmentForUser() {
    // Load the 5 oldest items chats.
    // Remove any that are already assigned.
    // If there are unassigned chats:
    // Get a random one and take the assignment.
    // Can't query based on empty array in firestore, and dont have any flags to determine if a chat is assigned or not. Since we don't know if/when a doc is created when a message is sent.
    const querySnap = await Chat.chatsColRef()
      .where("is_active", "==", true)
      .orderBy("updated_at", "desc")
      .limit(5)
      .get();

    let validDocSnaps = [];
    querySnap.docs.forEach((docSnap) => {
      // Check if already assigned.
      const doc = docSnap.data();
      if (doc.assigned_to && doc.assigned_to.length > 0) {
        return;
      }
      validDocSnaps.push(docSnap);
    });

    if (validDocSnaps.length < 1) {
      return null;
    }

    const randomDocSnap =
      validDocSnaps[Math.floor(Math.random() * validDocSnaps.length)];

    // Run a transaction to make sure the current user can get this document assigned to them, as long as it's not assigned to anyone else.
    try {
      await Firestore.db.runTransaction(async (t) => {
        const docSnap = await t.get(randomDocSnap.ref);
        const doc = docSnap.data();
        if (doc.assigned_to && doc.assigned_to.length > 0) {
          throw "Document is assigned.";
        }
        // Document can be assigned to the current user.
        t.update(randomDocSnap.ref, {
          assigned_to: Firestore.FieldValue.arrayUnion(window.user_id)
        });
      });
    } catch (e) {
      return null;
    }

    return randomDocSnap.id;
  }

  /**
   * Unassign all chats from the user.
   */
  static async unAssignAllUser() {
    const querySnap = await Chat.chatsColRef()
      .where("assigned_to", "array-contains", window.user_id)
      .get();

    for (const docSnap of querySnap.docs) {
      await docSnap.ref.update({
        assigned_to: Firestore.FieldValue.arrayRemove(window.user_id)
      });
    }
  }

  /**
   * Remove the uid from being assigned to the chat.
   */
  removeChatAssignment(uid) {
    this.chatDocRef().update({
      assigned_to: Firestore.FieldValue.arrayRemove(uid)
    });
  }

  /**
   * Add the uid to be assigned to the chat.
   */
  addChatAssignment(uidStringOrList) {
    let uidList = [].concat(uidStringOrList);
    this.chatDocRef().update({
      assigned_to: Firestore.FieldValue.arrayUnion(...uidList)
    });
  }

  /**
   * Add uids to the thread's need_help_from array.
   */
  addThreadHelpAssignment(uidStringOrList) {
    let uidList = [].concat(uidStringOrList);
    this.threadDocRef().update({
      need_help_from: Firestore.FieldValue.arrayUnion(...uidList)
    });
    /*
    this.sendMessage({
      is_system:true,
      is_internal:true,
      text: ``
    })
    */
  }

  removeThreadHelpAssignment(uid) {
    this.threadDocRef().update({
      need_help_from: Firestore.FieldValue.arrayRemove(uid)
    });
  }
}

export default Chat;
