/**
Show a modal with cancel and save buttons.

Props:
- isOpen - is the modal open/visible.
- title
- onClose
- onSave
- saveDisabled
- hideSaveButton
- saveButtonText - text for the save button
- saveButtonProps - Object that is passed to the save button as props.
- secondaryButtonText - text for the secondary button
- footerLeftColumn - react element to display in the footer left column
- hideFooter - true to hide the modal footer.
- contentStyle - Object - added to the modal style, under content key
- showBackButton - boolean - show a back arrow in the header.
- onBackClick - function - run when the back button is clicked.
- className - passed to react-modal.
*/
import React, { useState } from "react";
import Modal from "react-modal";
import { Row, Col, Button, Alert } from "reactstrap";

import { LoadingButton, KeyDetector } from "common";

const EditModal = (props) => {
  const [error, setError] = useState(null);

  return (
    <Modal
      ariaHideApp={false}
      isOpen={props.isOpen}
      className={props.className}
      style={{
        overlay: { backgroundColor: "rgba(0,0,0,.4)", zIndex: 101 },
        content: {
          top: null,
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          padding: 0,
          width: "calc(100% - 20px)",
          maxWidth: 620,
          ...props.contentStyle
        }
      }}
    >
      <Row className="mt-2">
        <Col className="my-auto">
          <h2 style={{ fontSize: 22, fontWeight: 400 }} className="mb-0">
            {props.showBackButton ? (
              <i
                className="fa fa-chevron-left"
                style={{
                  fontSize: 16,
                  marginRight: 10,
                  padding: 5,
                  paddingLeft: 0,
                  cursor: "pointer"
                }}
                onClick={props.onBackClick}
              />
            ) : null}
            {props.title}
          </h2>
        </Col>
        <Col xs="auto">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              props.onClose();
            }}
            style={{ fontSize: 22, color: "#999" }}
          >
            <i className="fa fa-close" />
          </a>
        </Col>
      </Row>
      <hr className="mb-0 mt-2" />
      <div className="modal-body" style={props.modalBodyStyle}>
        {props.children}
      </div>
      {error ? <Alert color="danger">Error: {error}</Alert> : null}
      {props.hideFooter ? null : (
        <>
          <hr className="mt-0" />
          <Row className="pb-3">
            <Col className="my-auto">{props.footerLeftColumn}</Col>
            <Col xs="auto">
              <Button
                color="secondary"
                className="mr-2"
                onClick={props.onClose}
              >
                {props.secondaryButtonText || "Cancel"}
              </Button>
              {!props.hideSaveButton ? (
                <LoadingButton
                  color="primary"
                  {...props.saveButtonProps}
                  onClick={async () => {
                    try {
                      return await props.onSave();
                    } catch (e) {
                      console.error(e);
                      setError(e);
                      return false;
                    }
                  }}
                  disabled={props.saveDisabled}
                  loadOnEnter={false}
                >
                  {props.saveButtonText || "Save"}
                </LoadingButton>
              ) : null}
            </Col>
          </Row>
        </>
      )}
      {props.customFooter ? props.customFooter : null}
      {props.isOpen ? (
        <KeyDetector.Component
          useKey={KeyDetector.ESCAPE}
          onPress={props.onClose}
        />
      ) : null}
    </Modal>
  );
};

export default EditModal;
