/*
A loading page.
*/
import React from "react";
import { Row, Col } from "reactstrap";

import { Loading } from "common";

const PageLoading = props => {
  return (
    <div style={{ marginTop: "6rem" }}>
      <Row>
        <Col>
          <center>
            <Loading />
          </center>
        </Col>
      </Row>
    </div>
  );
};

export default PageLoading;
