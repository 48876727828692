import React from "react";

import { Alert, Card } from "reactstrap";

const CompleteCard = (props) => {
  const { actions } = props;

  if (actions.includes("s")) {
    return (
      <Card>
        <Alert color="success" className="mb-0 mt-0">
          <h4 className="alert-heading">Request success!</h4>
          <p className="mb-2">
            We'll send you an email when your sample plan is ready.
          </p>
          <p className="mb-0">
            It usually takes 1-2 hours, but can take up to 4 hours.
          </p>
        </Alert>
      </Card>
    );
  }

  if (actions.includes("p")) {
    return (
      <Card>
        <Alert color="success" className="mb-0 mt-0">
          <h4 className="alert-heading">Profile updated!</h4>
          <p className="mb-0">
            Thanks for confirming your information. Your profile has been
            updated.
          </p>
        </Alert>
      </Card>
    );
  }

  // fall back to exclusions.
  return (
    <Card>
      <Alert color="success" className="mb-0 mt-0">
        <h4 className="alert-heading">Preferences updated!</h4>
        <p className="mb-0">
          Thanks for confirming your food preferences. Your profile has been
          updated.
        </p>
      </Alert>
    </Card>
  );
};

export default CompleteCard;
