/**
For partner_users, get their information from firebase.
*/
import React from "react";

import { CurrentUser, FirestoreDocument } from "common";

const CurrentPartnerUser = props => {
  return (
    <CurrentUser>
      {user => {
        return (
          <FirestoreDocument collection="partner_users" id={user.uid}>
            {result => {
              return props.children(result);
            }}
          </FirestoreDocument>
        );
      }}
    </CurrentUser>
  );
};

export default CurrentPartnerUser;
