/*

Show a form to edit the clients profile.

Props:
user -
userChanges -
setUserChanges -
showErrors -
onHasError -
hideTargetWeight - boolean - hide the target weight field

*/
import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Button
} from "reactstrap";
import { inject } from "mobx-react";
import { Input as TextInput, FormError } from "common";
import Measurement from "util/Measurement";

import timestamp from "util/timestamp";

const ProfileForm = inject("rootStore")((props) => {
  const { user, userChanges, setUserChanges } = props;

  const [errors, setErrors] = useState({});

  useEffect(() => {
    let newUser = { ...user, ...userChanges };
    let newErrors = {};

    const measurementSystem =
      (newUser.measurements && newUser.measurements.system) ||
      props.rootStore.partnerStore.partner.get("measurement_system") ||
      "imperial";

    if (!newUser.profile || !newUser.profile.age) {
      newErrors = {
        ...newErrors,
        age: "Age is required"
      };
    } else if (newUser.profile && newUser.profile.age) {
      if (newUser.profile.age > 80) {
        newErrors = {
          ...newErrors,
          age: "We cant support clients over 80"
        };
      } else if (newUser.profile.age < 18) {
        newErrors = {
          ...newErrors,
          age: "We cant support clients under 18"
        };
      }
    }
    if (!newUser.profile || !newUser.profile.gender) {
      newErrors = {
        ...newErrors,
        gender: true
      };
    }
    if (!newUser.profile || !newUser.profile.activity_level) {
      newErrors = {
        ...newErrors,
        activity_level: true
      };
    }
    if (!newUser.measurements || !newUser.measurements.height) {
      newErrors = {
        ...newErrors,
        height: "Height is required"
      };
    } else if (newUser.measurements && newUser.measurements.height) {
      if (measurementSystem === "imperial") {
        // max height is 7ft = 84in
        if (newUser.measurements.height > 84) {
          newErrors = {
            ...newErrors,
            height: "Height is too high"
          };
        } else if (newUser.measurements.height < 44) {
          // min height is 3 foot 8 = 44in
          newErrors = {
            ...newErrors,
            height: "Height is too low"
          };
        }
      } else {
        // metric
        // 84in = 213cm
        if (newUser.measurements.height > 213) {
          newErrors = {
            ...newErrors,
            height: "Height is too high"
          };
        } else if (newUser.measurements.height < 111) {
          // 44in = 111.76
          newErrors = {
            ...newErrors,
            height: "Height is too low"
          };
        }
      }
    }
    if (!newUser.measurements || !newUser.measurements.weight) {
      newErrors = { ...newErrors, weight: "Weight is required" };
    } else if (newUser.measurements && newUser.measurements.weight) {
      if (measurementSystem === "imperial") {
        // Max weight 500lb
        if (newUser.measurements.weight >= 500) {
          newErrors = { ...newErrors, weight: "Weight is too high" };
        } else if (newUser.measurements.weight < 90) {
          // under 90
          newErrors = { ...newErrors, weight: "Weight is too low" };
        }
      } else {
        // Metric.
        // max 226kg = 500lb
        if (newUser.measurements.weight >= 226) {
          newErrors = {
            ...newErrors,
            weight: "Weight is too high"
          };
        } else if (newUser.measurements.weight < 41) {
          // under 90
          newErrors = {
            ...newErrors,
            weight: "Weight is too low"
          };
        }
      }
    }

    setErrors(newErrors);
    props.onHasError(Object.keys(newErrors).length > 0);
  }, [user, userChanges]);

  const defaultMeasurementSystem =
    (user.measurements && user.measurements.system) ||
    props.rootStore.partnerStore.partner.get("measurement_system");

  const measurementSystem =
    (userChanges.measurements && userChanges.measurements.system) ||
    defaultMeasurementSystem ||
    "imperial";

  const profile = {
    ...user.profile,
    ...userChanges.profile
  };

  return (
    <>
      <Row className="pb-2">
        <Col sm={4}>Gender</Col>
        <Col>
          <FormGroup check className="radio" inline>
            <Input
              className="form-check-input"
              type="radio"
              id="male"
              name="gender"
              value="male"
              checked={profile.gender === "male"}
              onChange={(e) => {
                setUserChanges({
                  ...userChanges,
                  profile: { ...profile, gender: "male" }
                });
              }}
              invalid={props.showErrors && errors.gender ? true : false}
            />
            <Label check className="form-check-label" htmlFor="male">
              Male
            </Label>
          </FormGroup>

          <FormGroup check className="radio" inline>
            <Input
              className="form-check-input"
              type="radio"
              id="female"
              name="gender"
              value="female"
              checked={profile.gender === "female"}
              onChange={(e) => {
                setUserChanges({
                  ...userChanges,
                  profile: {
                    ...profile,
                    gender: "female"
                  }
                });
              }}
              invalid={props.showErrors && errors.gender ? true : false}
            />
            <Label check className="form-check-label" htmlFor="female">
              Female
            </Label>
          </FormGroup>
          <FormError visible={props.showErrors && errors.gender}>
            Select gender
          </FormError>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col sm={4} className="my-auto">
          Age
        </Col>
        <Col sm={"auto"}>
          <TextInput
            type="number"
            id="age"
            name="age"
            value={profile.age}
            onChange={(age) => {
              setUserChanges({
                ...userChanges,
                profile: {
                  ...profile,
                  age,
                  age_at_timestamp: timestamp()
                }
              });
            }}
            invalid={props.showErrors && errors.age ? true : false}
          />
          <FormError>{errors.age}</FormError>
        </Col>
      </Row>
      <Row className="mt-2 py-2">
        <Col sm={4} className="my-auto">
          Measurement system
        </Col>
        <Col sm={"auto"}>
          <FormGroup check className="radio" inline>
            <Input
              className="form-check-input"
              type="radio"
              id="metric"
              name="measurement"
              value="metric"
              checked={measurementSystem === "metric"}
              onChange={(e) => {
                // Need to convert height and weight when switching systems.
                let newHeight = "";
                let newWeight = "";
                let newTargetWeight = "";
                if (
                  userChanges.measurements &&
                  userChanges.measurements.height
                ) {
                  newHeight = Measurement.toCm(userChanges.measurements.height);
                } else if (user.measurements && user.measurements.height) {
                  newHeight = Measurement.toCm(user.measurements.height);
                }

                if (
                  userChanges.measurements &&
                  userChanges.measurements.weight
                ) {
                  newWeight = Measurement.toKg(userChanges.measurements.weight);
                } else if (user.measurements && user.measurements.weight) {
                  newWeight = Measurement.toKg(user.measurements.weight);
                }

                if (
                  userChanges.measurements &&
                  userChanges.measurements.target_weight
                ) {
                  newTargetWeight = Measurement.toKg(
                    userChanges.measurements.target_weight
                  );
                } else if (
                  user.measurements &&
                  user.measurements.target_weight
                ) {
                  newTargetWeight = Measurement.toKg(
                    user.measurements.target_weight
                  );
                }

                setUserChanges({
                  ...userChanges,
                  measurements: {
                    ...userChanges.measurements,
                    height: Math.round(newHeight * 100) / 100,
                    weight: newWeight,
                    target_weight: newTargetWeight,
                    system: "metric"
                  }
                });
              }}
            />
            <Label check className="form-check-label" htmlFor="metric">
              Metric
            </Label>
          </FormGroup>
          <FormGroup check className="radio" inline>
            <Input
              className="form-check-input"
              type="radio"
              id="imperial"
              name="measurement"
              value="imperial"
              checked={measurementSystem === "imperial"}
              onChange={(e) => {
                // Need to convert height and weight when switching systems.
                let newHeight = "";
                let newWeight = "";
                let newTargetWeight = "";
                if (
                  userChanges.measurements &&
                  userChanges.measurements.height
                ) {
                  newHeight = Math.round(
                    Measurement.fromCm(userChanges.measurements.height)
                  );
                } else if (user.measurements && user.measurements.height) {
                  newHeight = Math.round(
                    Measurement.fromCm(user.measurements.height)
                  );
                }

                if (
                  userChanges.measurements &&
                  userChanges.measurements.weight
                ) {
                  newWeight = Math.round(
                    Measurement.fromKg(userChanges.measurements.weight)
                  );
                } else if (user.measurements && user.measurements.weight) {
                  newWeight = Math.round(
                    Measurement.fromKg(user.measurements.weight)
                  );
                }

                if (
                  userChanges.measurements &&
                  userChanges.measurements.target_weight
                ) {
                  newTargetWeight = Math.round(
                    Measurement.fromKg(userChanges.measurements.target_weight)
                  );
                } else if (
                  user.measurements &&
                  user.measurements.target_weight
                ) {
                  newTargetWeight = Math.round(
                    Measurement.fromKg(user.measurements.target_weight)
                  );
                }

                setUserChanges({
                  ...userChanges,
                  measurements: {
                    ...userChanges.measurements,
                    height: Math.round(newHeight * 100) / 100,
                    weight: newWeight,
                    target_weight: newTargetWeight,
                    system: "imperial"
                  }
                });
              }}
            />
            <Label check className="form-check-label" htmlFor="imperial">
              Imperial
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <HeightInput
        user={user}
        setUserChanges={setUserChanges}
        userChanges={userChanges}
        measurement={measurementSystem}
        hasError={props.showErrors && errors.height ? true : false}
        error={errors.height}
      />
      <WeightInput
        user={user}
        setUserChanges={setUserChanges}
        userChanges={userChanges}
        measurement={measurementSystem}
        hasError={props.showErrors && errors.weight ? true : false}
        error={errors.weight}
      />
      {!props.hideTargetWeight ? (
        <TargetWeightInput
          user={user}
          setUserChanges={setUserChanges}
          userChanges={userChanges}
          measurement={measurementSystem}
          hasError={props.showErrors && errors.target_weight ? true : false}
        />
      ) : null}
      <Row className="pb-2 mt-4">
        <Col sm={4}>Current activity level</Col>
        <Col>
          <FormGroup check className="radio">
            <Input
              className="form-check-input"
              type="radio"
              id="low"
              name="activity_level"
              value="low"
              checked={profile.activity_level === "low"}
              onChange={(e) => {
                setUserChanges({
                  ...userChanges,
                  profile: {
                    ...profile,
                    activity_level: "low"
                  }
                });
              }}
              invalid={props.showErrors && errors.activity_level ? true : false}
            />
            <Label check className="form-check-label" htmlFor="low">
              Normal (office job, a little exercise)
            </Label>
          </FormGroup>
          <FormGroup check className="radio">
            <Input
              className="form-check-input"
              type="radio"
              id="medium"
              name="activity_level"
              value="medium"
              checked={profile.activity_level === "medium"}
              onChange={(e) => {
                setUserChanges({
                  ...userChanges,
                  profile: {
                    ...profile,
                    activity_level: "medium"
                  }
                });
              }}
              invalid={props.showErrors && errors.activity_level ? true : false}
            />
            <Label check className="form-check-label" htmlFor="medium">
              Medium (1 hour of exercise 3 days per week)
            </Label>
          </FormGroup>
          <FormGroup check className="radio">
            <Input
              className="form-check-input"
              type="radio"
              id="high"
              name="activity_level"
              value="high"
              checked={profile.activity_level === "high"}
              onChange={(e) => {
                setUserChanges({
                  ...userChanges,
                  profile: {
                    ...profile,
                    activity_level: "high"
                  }
                });
              }}
              invalid={props.showErrors && errors.activity_level ? true : false}
            />
            <Label check className="form-check-label" htmlFor="high">
              High (2 hours of exercise 5 times per week)
            </Label>
          </FormGroup>
          <FormGroup check className="radio">
            <Input
              className="form-check-input"
              type="radio"
              id="extreme"
              name="activity_level"
              value="extreme"
              checked={profile.activity_level === "extreme"}
              onChange={(e) => {
                setUserChanges({
                  ...userChanges,
                  profile: {
                    ...profile,
                    activity_level: "extreme"
                  }
                });
              }}
              invalid={props.showErrors && errors.activity_level ? true : false}
            />
            <Label check className="form-check-label" htmlFor="extreme">
              Athlete (intense exercise 7 days per week)
            </Label>
          </FormGroup>
          <FormError
            visible={props.showErrors && errors.activity_level ? true : false}
          >
            Select activity level
          </FormError>
        </Col>
      </Row>
    </>
  );
});

const HeightInput = (props) => {
  const { user, setUserChanges, userChanges, measurement } = props;

  // Need to keep track of whether inches or feet is blank, because they are derived from the total inches.
  const [isInchesBlank, setInchesBlank] = useState(
    measurement !== "imperial" &&
      (!user.measurements || !user.measurements.height)
  );
  const [isFeetBlank, setFeetBlank] = useState(
    measurement !== "imperial" &&
      (!user.measurements || !user.measurements.height)
  );

  let measurements = user.measurements;

  if (
    userChanges.measurements &&
    userChanges.measurements.height !== null &&
    typeof userChanges.measurements.height !== "undefined"
  ) {
    measurements = userChanges.measurements;
  }

  let heightValue =
    measurements && measurements.height ? measurements.height : "";

  let feetValue;
  let inchesValue;
  if (!heightValue) {
    feetValue = "";
    inchesValue = "";
  } else if (measurement === "imperial") {
    feetValue = Math.floor(heightValue / 12);
    inchesValue = heightValue - feetValue * 12;
  }

  return (
    <Row className="mt-2">
      <Col sm={4} className="my-auto">
        Height
      </Col>
      {measurement === "metric" ? (
        <>
          <Col xs={4} sm={2}>
            <TextInput
              type="number"
              id="height"
              name="height"
              value={heightValue}
              onChange={(height) => {
                setUserChanges({
                  ...userChanges,
                  measurements: {
                    ...measurements,
                    system: measurement,
                    height
                  }
                });
              }}
              style={{ width: 80 }}
              invalid={props.hasError}
            />
            <FormError>{props.error}</FormError>
          </Col>
          <Col xs={4} className="my-auto">
            centimeters
          </Col>
        </>
      ) : (
        <>
          <Col xs={4} sm={2}>
            <FormGroup>
              <Label htmlFor="heightFeet">Feet</Label>
              <TextInput
                type="number"
                id="heightFeet"
                name="heightFeet"
                value={isFeetBlank ? "" : feetValue}
                onChange={(heightFeet) => {
                  if (heightFeet === "") {
                    setFeetBlank(true);
                  } else {
                    setFeetBlank(false);
                  }

                  let newHeight = "";
                  if (heightFeet) {
                    newHeight = heightFeet * 12;
                  }
                  if (inchesValue) {
                    if (!newHeight) {
                      newHeight = 0;
                    }
                    newHeight += inchesValue;
                  }
                  setUserChanges({
                    ...userChanges,
                    measurements: {
                      ...measurements,
                      system: measurement,
                      height: newHeight
                    }
                  });
                }}
                invalid={props.hasError}
              />
              <FormError>{props.error}</FormError>
            </FormGroup>
          </Col>
          <Col xs={4} sm={2}>
            <FormGroup>
              <Label htmlFor="heightInches">Inches</Label>
              <TextInput
                type="number"
                id="heightInches"
                name="heightInches"
                value={isInchesBlank ? "" : inchesValue}
                onChange={(heightInches) => {
                  if (heightInches === "") {
                    setInchesBlank(true);
                  } else {
                    setInchesBlank(false);
                  }

                  let newHeight = "";
                  if (heightInches) {
                    newHeight = heightInches;
                  }
                  if (feetValue) {
                    if (!newHeight) {
                      newHeight = 0;
                    }
                    newHeight += feetValue * 12;
                  }

                  setUserChanges({
                    ...userChanges,
                    measurements: {
                      ...measurements,
                      system: measurement,
                      height: newHeight
                    }
                  });
                }}
              />
            </FormGroup>
          </Col>
        </>
      )}
    </Row>
  );
};

const WeightInput = (props) => {
  const { user, setUserChanges, userChanges, measurement } = props;

  let measurements = {
    ...user.measurements,
    ...userChanges.measurements
  };

  let weightValue =
    measurements && measurements.weight ? measurements.weight : "";

  if (weightValue !== "") {
    weightValue = Math.round(weightValue * 100) / 100;
  }

  return (
    <Row className="mt-2">
      <Col sm={4} className="my-auto">
        Weight
      </Col>
      <Col xs={4} sm={2}>
        <TextInput
          type="number"
          id="weight"
          name="weight"
          value={weightValue}
          onChange={(weight) => {
            setUserChanges({
              ...userChanges,
              measurements: {
                ...measurements,
                system: measurement,
                weight
              }
            });
          }}
          style={{ width: 80 }}
          invalid={props.hasError}
        />
        <FormError>{props.error}</FormError>
      </Col>
      <Col xs={4} sm={2} className="my-auto">
        {measurement === "metric" ? "kilograms" : "pounds"}
      </Col>
    </Row>
  );
};

const TargetWeightInput = (props) => {
  const { user, setUserChanges, userChanges, measurement } = props;

  let measurements = {
    ...user.measurements,
    ...userChanges.measurements
  };

  let weightValue =
    measurements && measurements.target_weight
      ? measurements.target_weight
      : "";

  if (weightValue !== "") {
    weightValue = Math.round(weightValue * 100) / 100;
  }

  return (
    <Row className="mt-2">
      <Col sm={4} className="my-auto">
        Target weight
      </Col>
      <Col xs={4} sm={2}>
        <TextInput
          type="number"
          id="target_weight"
          name="target_weight"
          value={weightValue}
          onChange={(target_weight) => {
            setUserChanges({
              ...userChanges,
              measurements: {
                ...measurements,
                system: measurement,
                target_weight
              }
            });
          }}
          style={{ width: 80 }}
          invalid={props.hasError}
        />
        <FormError>Target weight is required</FormError>
      </Col>
      <Col xs={4} sm={2} className="my-auto">
        {measurement === "metric" ? "kilograms" : "pounds"}
      </Col>
    </Row>
  );
};

export default ProfileForm;
