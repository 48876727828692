import React from "react";
import { FirestoreCollection, Loading } from "common";
import { Redirect } from "react-router-dom";
import UserNotFound from "./UserNotFound";

const ZendeskHome = (props) => {
  const { partner, requester, zendeskClient, brandId } = props;

  // If email isn't available, do a search.
  if (!requester.email) {
    return <Redirect to={`/zendesk/search?q=${requester.name}`} />;
    return (
      <UserNotFound
        zendeskClient={zendeskClient}
        partner={partner}
        requester={requester}
      />
    );
  }

  // Couldn't find by searching by zendesk user id.
  // Try to get an exact email match.
  console.log("Search for direct email");
  return (
    <FirestoreCollection
      collection="users"
      filter={[
        ["email", "==", requester.email],
        ["partner_id", "==", partner.id]
      ]}
    >
      {({ isLoading, data }) => {
        if (isLoading) {
          return (
            <center>
              <Loading />
            </center>
          );
        }

        if (data.length < 1) {
          // Try and get by paypal email address.
          return (
            <FirestoreCollection
              collection="users"
              filter={[
                ["paypal_email", "==", requester.email],
                ["partner_id", "==", partner.id]
              ]}
            >
              {({ isLoading, data }) => {
                if (isLoading) {
                  return (
                    <center>
                      <Loading />
                    </center>
                  );
                }
                if (data.length < 1) {
                  return (
                    <Redirect to={`/zendesk/search?q=${requester.email}`} />
                  );
                  return (
                    <UserNotFound
                      zendeskClient={zendeskClient}
                      partner={partner}
                      requester={requester}
                    />
                  );
                }

                let userId = data[0].id;
                if (data.length > 1 && brandId) {
                  // Look for the user that matches the correct brand.
                  data.forEach(({ id, brand_id }) => {
                    if (brand_id === brandId) {
                      userId = id;
                    }
                  });
                } else if (data.length > 1 && !brandId) {
                  return (
                    <Redirect to={`/zendesk/search?q=${requester.email}`} />
                  );
                  return (
                    <UserNotFound
                      zendeskClient={zendeskClient}
                      partner={partner}
                      requester={requester}
                    />
                  );
                }

                return <Redirect to={`/zendesk/user/${userId}`} />;
              }}
            </FirestoreCollection>
          );
        }

        let userId = data[0].id;
        if (data.length > 1 && brandId) {
          // Look for the user that matches the correct brand.
          data.forEach(({ id, brand_id }) => {
            if (brand_id === brandId) {
              userId = id;
            }
          });
        } else if (data.length > 1 && !brandId) {
          return <Redirect to={`/zendesk/search?q=${requester.email}`} />;
          return (
            <UserNotFound
              zendeskClient={zendeskClient}
              partner={partner}
              requester={requester}
            />
          );
        }

        return <Redirect to={`/zendesk/user/${userId}`} />;
      }}
    </FirestoreCollection>
  );
};

export default ZendeskHome;
