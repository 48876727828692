import axios from "axios";
import firebase from "firebase/compat/app";

import { SERVER } from "config";

class Backend {
  /*
  POST to the backend server.
  method - the route to call, without leading slash (like: "users/create")
  */
  static async post(method, data = {}) {
    let token;
    if (firebase.auth().currentUser) {
      token = await firebase.auth().currentUser.getIdToken(true);
    }

    return axios
      .post(`${SERVER}${method}`, data, {
        auth: {
          username: token
        },
        headers: {
          "X-Brand-Id": window.brand_id,
          "X-Partner-Id": window.partner_id
        }
      })
      .then((response) => {
        if (response.data.auth && response.data.auth === false) {
          // Handle non-authenticated.
        }
        if (response.data.error) {
          throw response.data.message;
        }
        return response.data;
      });
  }

  static async get(method, data, options) {
    let token;
    if (firebase.auth().currentUser) {
      token = await firebase.auth().currentUser.getIdToken(true);
    }

    return axios
      .get(`${SERVER}${method}`, {
        ...options,
        params: data,
        auth: {
          username: token
        },
        headers: {
          "X-Brand-Id": window.brand_id,
          "X-Partner-Id": window.partner_id
        }
      })
      .then((response) => {
        if (response.data.auth && response.data.auth === false) {
          // Handle non-authenticated.
        }
        if (response.data.error) {
          throw response.data.message;
        }
        return response.data;
      });
  }
}

export default Backend;
