/*
Main page heading.

Props:
history - boolean (optional) - show last page from the navigationStore with a link to go back.
button - Object
  - text
  - onClick

*/
import React from "react";
import { inject } from "mobx-react";
import { Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";

const PageHeader = inject("rootStore")((props) => {
  let history = null;

  if (props.history) {
    const {
      rootStore: { navigationStore }
    } = props;

    history = navigationStore.history[navigationStore.history.length - 1];

    // When a page is loaded it adds to the history array.
    // It won't re-add if it's already the current page at the top of the stack.
    // We need to make sure here that in the situation where the PageHeader is re-rendering, it doesnt use
    // the last item added if that's the current page.

    if (history && history.link === window.location.pathname) {
      if (navigationStore.history.length - 2 >= 0) {
        // Use the second last item in the array.
        history = navigationStore.history[navigationStore.history.length - 2];
      } else {
        history = null;
      }
    }
  }

  const { defaultHistory } = props;

  return (
    <div style={{ marginTop: "2rem" }}>
      <Row>
        <Col>
          {history ? (
            <div className="header-navigation">
              <Link
                to={history.link}
                onClick={() => {
                  props.rootStore.navigationStore.history.pop();
                }}
              >
                <i className="fa fa-chevron-left" /> {history.title}
              </Link>
            </div>
          ) : defaultHistory ? (
            <div className="header-navigation">
              <Link to={defaultHistory.link}>
                <i className="fa fa-chevron-left" /> {defaultHistory.title}
              </Link>
            </div>
          ) : null}

          <h1 style={{ fontSize: 28 }}>{props.title}</h1>
          {props.subtitle}
        </Col>
        {props.button ? (
          <Col xs={"auto"}>
            <Button color="primary" onClick={props.button.onClick}>
              {props.button.text}
            </Button>
          </Col>
        ) : null}
        {props.action ? <Col xs={"auto"}>{props.action}</Col> : null}
      </Row>
    </div>
  );
});

export default PageHeader;
