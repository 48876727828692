const SearchClientQueryBuilder = (query, options) => {
  let queries = [];

  let searchEmail = query.split(" ").length < 2;
  let searchFirst = query.indexOf("@") < 0;
  let searchLast = query.split(" ").length > 1 && query.indexOf("@") < 0;

  if (searchEmail) {
    let emailPrefix = query.split("@")[0];

    queries.push(
      {
        fuzzy: {
          email: {
            value: emailPrefix
          }
        }
      },
      {
        match_phrase_prefix: {
          email: emailPrefix
        }
      },
      {
        fuzzy: {
          paypal_email: {
            value: emailPrefix
          }
        }
      },
      {
        match_phrase_prefix: {
          paypal_email: emailPrefix
        }
      }
    );
  }
  if (searchFirst) {
    queries.push(
      {
        fuzzy: {
          first_name: {
            value: query.split(" ")[0]
          }
        }
      },
      {
        wildcard: {
          first_name: query.split(" ")[0] + "*"
        }
      }
    );
  }
  if (searchLast) {
    queries.push(
      {
        fuzzy: {
          last_name: {
            value: query.split(" ").pop()
          }
        }
      },
      {
        wildcard: {
          last_name: query.split(" ").pop() + "*"
        }
      }
    );
  }

  return {
    dis_max: {
      queries: queries
    }
  };
};

export default SearchClientQueryBuilder;
