/**
Component that shows other open tickets for the current requester
*/
import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import axios from "axios";
import moment from "moment";

import { Loading } from "common";

const TicketMerger = props => {
  const [ticketData, setTicketData] = useState(null);
  const [mergeLoading, setMergeLoading] = useState(false);
  const { zendeskClient } = props;

  useEffect(() => {
    const start = async () => {
      const result = await zendeskClient.get("ticket");

      // Prevent the merger modal from showing when looking at old, closed tickets.
      if (
        result.ticket.status !== "new" &&
        result.ticket.status !== "open" &&
        result.ticket.status !== "pending"
      ) {
        return;
      }

      const currentTicketId = result.ticket.id;
      const requesterUserId = result.ticket.requester.id;
      // Get all their tickets.
      const zendeskResult = await zendeskClient.request({
        url: `/api/v2/users/${requesterUserId}/tickets/requested.json`,
        type: "GET",
        dataType: "json"
      });

      /* zendeskResult:
        count,
        tickets: {
          id,
          status
        }

        Need to see how many with a status of "new" or "open"
        Don't include the current ticket.
        If more than 1, show the modal.
      */

      const openOrNewTickets = zendeskResult.tickets.filter(
        ({ status, id }) => {
          // Filter out the current ticket.
          if (id === currentTicketId) {
            return false;
          }
          if (status === "open" || status === "new" || status === "pending") {
            return true;
          }
          return false;
        }
      );

      if (openOrNewTickets.length < 1) {
        return null;
      }

      // Zendesk seems to always give tickets ascending.
      // Not sure if this is consistent though.
      // We want to show the most recent at the top.
      openOrNewTickets.reverse();

      setTicketData({
        otherTickets: openOrNewTickets,
        currentTicketId,
        requesterUserId
      });
    };

    start();
  }, []);

  if (!ticketData) {
    return null;
  }

  const { otherTickets, currentTicketId, requesterUserId } = ticketData;

  const performMerge = async e => {
    if (mergeLoading) {
      return;
    }

    setMergeLoading(true);

    const targetComment = otherTickets.map(({ updated_at, description }) => {
      return `On ${moment(updated_at).format(
        "MMM Do YYYY - HH:mm:ss"
      )}, they wrote:

    ${description}

    ========

`;
    });

    // Merge the tickets into one.
    // This creates a job, which we need to check the status of.
    const mergeResult = await zendeskClient.request({
      url: `/api/v2/tickets/${currentTicketId}/merge.json`,
      type: "POST",
      contentType: "application/json",
      data: JSON.stringify({
        ids: otherTickets.map(({ id }) => id),
        target_comment: `Auto merged tickets in: ${otherTickets
          .map(({ id }) => id)
          .join(",")}

${targetComment}`,
        source_comment: `Auto merged into ticket ${currentTicketId}`,
        target_comment_is_public: false,
        source_comment_is_public: false
      })
    });

    // Need to check for the job status.
    let checkingJob = false;
    const interval = setInterval(async () => {
      if (checkingJob) {
        // Locked/loading.
        return;
      }

      console.log("CHECKING FOR JOB STATUS");

      checkingJob = true;

      const jobId = mergeResult.job_status.id;

      const jobStatusResult = await zendeskClient.request({
        url: `/api/v2/job_statuses/${jobId}.json`,
        type: "GET",
        dataType: "json"
      });

      if (jobStatusResult.job_status.status === "completed") {
        console.log("JOB DONE!");

        // Update the current ticket so that the UI refreshes.
        // Only way I can figure out how to force a refresh.
        await zendeskClient.request({
          url: `/api/v2/tickets/${currentTicketId}.json`,
          type: "PUT",
          contentType: "application/json",
          data: JSON.stringify({
            ticket: {
              status: "open"
            }
          })
        });

        clearInterval(interval);

        // Not loading. Remove the ticket data so the modal is no more!
        setMergeLoading(false);
        setTicketData(null);
      }

      checkingJob = false;
    }, 500);

    console.log(mergeResult);
  };

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: "rgba(0,0,0,.5)",
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
        flexDirection: "column"
      }}
    >
      {mergeLoading ? (
        <Loading color="#FFF" />
      ) : (
        <>
          <span style={{ color: "#FFF", fontWeight: "bold" }}>
            There {otherTickets.length > 1 ? "are" : "is"}
          </span>
          <span style={{ color: "#FFF", fontWeight: "bold", fontSize: 32 }}>
            {otherTickets.length}
          </span>
          <span style={{ color: "#FFF", fontWeight: "bold" }}>
            other open ticket{otherTickets.length > 1 ? "s" : ""}
          </span>
          <br />
          <Button color="primary" href="#" onClick={performMerge}>
            MERGE TICKETS
          </Button>
          <br />
          <a
            href="#"
            style={{ color: "#FFF" }}
            onClick={e => {
              e.preventDefault();
              setTicketData(null);
            }}
          >
            cancel - do not merge
          </a>
        </>
      )}
    </div>
  );
};

export default TicketMerger;
