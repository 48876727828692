/**
Map domains to partner ids so we know who the client belongs to.
*/
const CONFIGMAP = {
  "myplan.ketocustomplan.com": {
    partner_id: "7ilnrGcpcDBy8ps7gvwm",
    brand_id: "KCP",
    // List of potential support emails to match with in the zendesk app.
    support_emails: ["support@ketocustomplan.com"]
  },
  "plan.eggfast.com": {
    partner_id: "7ilnrGcpcDBy8ps7gvwm",
    brand_id: "EF",
    // List of potential support emails to match with in the zendesk app.
    support_emails: ["hello@eggfast.com"]
  },
  "my.10minutes.com": {
    partner_id: "7ilnrGcpcDBy8ps7gvwm",
    brand_id: "10M",
    // List of potential support emails to match with in the zendesk app.
    support_emails: ["hello@10minutes.com"]
  },
  "my.weightlossdiet.com": {
    partner_id: "7ilnrGcpcDBy8ps7gvwm",
    brand_id: "WLD",
    // List of potential support emails to match with in the zendesk app.
    support_emails: ["hello@weightlossdiet.com"]
  },
  "my.healthyleap.com": {
    partner_id: "7ilnrGcpcDBy8ps7gvwm",
    brand_id: "HL",
    // List of potential support emails to match with in the zendesk app.
    support_emails: ["hello@healthyleap.com"]
  },
  "my.mealific.com": {
    partner_id: "7ilnrGcpcDBy8ps7gvwm",
    brand_id: "ML",
    // List of potential support emails to match with in the zendesk app.
    support_emails: ["hello@mealific.com"]
  },
  "localhost:3000": {
    partner_id: "7ilnrGcpcDBy8ps7gvwm",
    brand_id: "ML",
    support_emails: ["support@ketocustomplan.com"]
  },
  "192.168.1.98:3000": {
    partner_id: "7ilnrGcpcDBy8ps7gvwm",
    brand_id: "KCP",
    support_emails: ["support@ketocustomplan.com"]
  }
};

export { CONFIGMAP };
