// https://www.algolia.com/doc/guides/building-search-ui/going-further/improve-performance/react/?language=react#refresh-the-cache-triggered-by-an-action-from-the-user

import React, { useEffect, useState } from "react";

import { SearchBox } from "react-instantsearch-dom";
import { withRouter } from "react-router-dom";
import querySearch from "util/querySearch";

const CustomSearchBox = withRouter(props => {
  //const { algoliaSearchStore } = props.rootStore;
  const [refresh, setRefresh] = useState(false);

  // Ridiculous, but it's how they say to do it! https://www.algolia.com/doc/guides/building-search-ui/going-further/improve-performance/react/?language=react#refresh-the-cache-periodically

  // Too bad there's no way to just straight up disable the cache.
  useEffect(() => {
    const interval = setInterval(() => {
      setRefresh(true);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(
    () => {
      setRefresh(false);
    },
    [refresh]
  );

  const updateHistory = search => {
    if (search) {
      props.history.push({
        search: querySearch.add({ search })
      });
    } else {
      // Remove the variable to keep the url clean.
      props.history.push({
        search: querySearch.add({ search: {} })
      });
    }
  };

  return (
    <SearchBox
      {...props}
      showLoadingIndicator
      refresh={refresh}
      translations={{ placeholder: props.placeholder }}
      onReset={() => {
        // Reset the search param.
        if (!props.updateUrl) {
          return;
        }
        updateHistory(null);
      }}
      onChange={e => {
        if (!props.updateUrl) {
          return;
        }

        updateHistory(e.target.value);
      }}
    >
      {props.children}
    </SearchBox>
  );
});

export default CustomSearchBox;
