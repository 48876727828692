/**
 * Send a log to the server.
 */

import Backend from "util/Backend";

const Log = (message, data) => {
  Backend.post("log", { message, data });
};

export default Log;
