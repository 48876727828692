import React, { useRef, useState, useEffect } from "react";
import Feed from "react-scrollable-feed";

import { Loading, CurrentUser } from "common";

import * as Chat from "./index";

import useWindowDimensions from "hooks/useWindowDimensions";

import moment from "moment";

const ScrollableMessagesList2 = (props) => {
  const {
    chatId,
    threadId,
    defaultMessageLimit,
    newMessageCount,
    renderMessage,
    fillHeight,
    clientLastViewedAt,
    scrollToBottomKey, // Change this to re-scroll to the bottom
    disableLoadingIndicator, // true and the loading icon will not show.
    heightOffset, // Reduce the height by this much. only when fillHeight=true
    style // Object{container, scroller } - style to apply
  } = props;

  return (
    <Chat.MessagesList
      chatId={chatId}
      threadId={threadId}
      defaultMessageLimit={defaultMessageLimit}
      newMessageCount={newMessageCount}
    >
      {({ isLoaded, isLoadingMore, hasMoreMessages, onLoadMore, messages }) => {
        // Make sure to always render, even if empty, to prevent empty jitters

        let messagesList = messages.map((m, idx) => {
          return (
            <CurrentUser key={m.id}>
              {(currentUser) => {
                const currentUserIsStaff =
                  currentUser.account_type === "partner";

                const addProps = {
                  chatId,
                  threadId,
                  clientHasViewed:
                    clientLastViewedAt &&
                    m.created_at / 1000 < clientLastViewedAt
                      ? true
                      : false,
                  currentUserIsStaff,
                  showAsCurrentUser:
                    (currentUserIsStaff && m.user?.is_staff) ||
                    (!currentUserIsStaff && !m.user?.is_staff),
                  message: m
                };

                return (
                  <>
                    {renderMessage ? (
                      renderMessage(addProps)
                    ) : (
                      <Chat.MessageBubble {...addProps} />
                    )}
                  </>
                );
              }}
            </CurrentUser>
          );
        });

        return (
          <Feed
            onScroll={(e) => {
              console.log(e);
            }}
          >
            {isLoadingMore ? <span>Loading...</span> : null}
            {messagesList}
          </Feed>
        );
      }}
    </Chat.MessagesList>
  );
};

export default ScrollableMessagesList2;
