/**
 * Renders props.children(callbackWhenReady)
 * The children must call callbackWhenReady -- once that fires and the timer runs, the children will display.
 */
import React, { useState, useEffect, useRef } from "react";

const MinimumDelay = (props) => {
  const {
    seconds, // seconds to delay
    delayComponent, // component to render while delaying
    onComplete, // callback when complete
    disabled // no delay, just render children function
  } = props;

  const [show, setShow] = useState(false);
  const [ready, setReady] = useState(false);

  const readyRef = useRef(ready);
  readyRef.current = ready;

  const onFinish = () => {
    if (!onComplete) {
      return;
    }
    onComplete();
  };

  useEffect(() => {
    if (disabled) {
      setShow(true);
      setReady(true);
      return;
    }

    const timer = setTimeout(() => {
      setShow(true);
      if (readyRef.current === true) {
        onFinish();
      }
    }, seconds * 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      {!ready || !show ? delayComponent : null}
      <div style={!ready || !show ? { display: "none" } : null}>
        {props.children(() => {
          setReady(true);
          if (show) {
            onFinish();
          }
        })}
      </div>
    </>
  );
};

export default MinimumDelay;
