/**
 * Shows a prompt to retrieve images from AI.
 *
 */
import React, { useState, useEffect } from "react";
import { Input, Row, Col, Button, InputGroup } from "reactstrap";
import { EditModal, Loading, KeyDetector, BodyScrollDisable } from "common";

import Backend from "util/Backend";

const AIImageModal = (props) => {
  const {
    defaultPrompt,
    onClose,
    onSave // function(gsImageUrl)
  } = props;

  const [promptText, setPromptText] = useState(defaultPrompt || "");
  const [isLoading, setIsLoading] = useState(false);
  const [imageList, setImageList] = useState([]);

  const [selectedImage, setSelectedImage] = useState(null);

  // Called when submit is clicked.
  // Load images and set state.
  const onSubmitPrompt = async () => {
    if (promptText === "") {
      return;
    }

    if (isLoading) {
      return;
    }

    setImageList([]);

    setIsLoading(true);
    const result = await Backend.get("ai_images/images", {
      prompt: promptText,
      n: 6
    });

    if (result.success === false) {
      window.alert(result.message);
      setIsLoading(false);
      return;
    }

    setImageList(result.data);
    setIsLoading(false);
  };

  return (
    <BodyScrollDisable>
      <EditModal
        isOpen={true}
        title={"Get an image"}
        onClose={onClose}
        saveDisabled={!selectedImage}
        onSave={async () => {
          if (!selectedImage) {
            return false;
          }

          // Save the selected image.
          const result = await Backend.post("ai_images/save", {
            url: selectedImage
          });

          onSave(result.data);

          return false;
        }}
      >
        <InputGroup>
          <Input
            bsSize="lg"
            type="text"
            placeholder="Describe the image you want..."
            value={promptText}
            onChange={(e) => {
              setPromptText(e.target.value);
            }}
          />
          <Button
            color="primary"
            style={{ borderRadius: 0 }}
            onClick={onSubmitPrompt}
          >
            Submit
          </Button>
          <KeyDetector.Component
            useKey={KeyDetector.ENTER}
            onPress={onSubmitPrompt}
          />
        </InputGroup>
        {isLoading ? (
          <center>
            <Loading style={{ marginTop: 50, marginBottom: 50 }} />
          </center>
        ) : null}
        <ImageResults
          imageList={imageList}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
        />
      </EditModal>
    </BodyScrollDisable>
  );
};

const ImageResults = (props) => {
  const { imageList, selectedImage, setSelectedImage } = props;

  const renderList = imageList.map(({ url }, idx) => {
    return (
      <img
        key={idx}
        src={url}
        style={{
          height: 170,
          width: 170,
          margin: 10,
          border: selectedImage === url ? "5px solid pink" : null,
          cursor: "pointer"
        }}
        onClick={(e) => {
          setSelectedImage(url);
        }}
      />
    );
  });

  if (imageList.length < 1) {
    return null;
  }

  return (
    <div className="mt-4">
      <strong>Image results</strong>
      <br />
      {renderList}
    </div>
  );
};

export default AIImageModal;
