/**
Keep tracking of shopping booleans for a grocery list.
*/

import Firestore from "util/Firestore";

class MealPlanShopping {
  constructor(mealPlanId) {
    this.id = mealPlanId;
  }

  static colRef() {
    return Firestore.db.collection("meal_plan_shopping");
  }

  docRef() {
    return MealPlanShopping.colRef().doc(this.id);
  }

  /**
   * Update a groceryId with a boolean.
   */
  updateGroceryId({
    groceryId,
    isSelected,
    userDetails // {uid,partner_id,brand_id}
  }) {
    const obj = {
      ...userDetails,
      items: {
        [groceryId]: isSelected
      }
    };
    console.log(obj);
    return this.docRef().set(obj, { merge: true });
  }
}

export default MealPlanShopping;
