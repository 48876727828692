// NOT USED. THE MAIN ISSUE IS WITH MULTIPLE TABS, THIS MIGHT BE USEFUL IN THE FUTURE.

// https://www.algolia.com/doc/guides/building-search-ui/going-further/improve-performance/react/?language=react#refresh-the-cache-triggered-by-an-action-from-the-user

// Used with common/Search/SearchBox

import { observable, decorate } from "mobx";

class AlgoliaSearchStore {
  refreshKey = 0;

  /**
   * Call this to force the client-side algolia cache to clear
   */
  clearCache() {
    this.refreshKey = Math.random();
  }
}

decorate(AlgoliaSearchStore, {
  refreshKey: observable
});
export default AlgoliaSearchStore;
