import React, { useState } from "react";

import Backend from "util/Backend";
import moment from "moment";
import { BRANDS } from "const";

const BillingSummary = props => {
  const [loading, setLoading] = useState(false);

  const { user, zendeskClient } = props;

  const email = user.paypal_email ? user.paypal_email : user.email;

  if (loading) {
    return <small>loading...</small>;
  }

  return (
    <small>
      <a
        href="#"
        onClick={async e => {
          e.preventDefault();
          setLoading(true);

          const response = await Backend.get(
            `chargedesk/get_charges_for_email?email=${email}`
          );

          const result = [];

          for (const brand of Object.keys(response.data)) {
            if (brand.toLowerCase() === "paypal") {
              result.push(`PayPal<br/>`);
            } else {
              result.push(`${BRANDS[brand].brand_name}<br/>`);
            }

            // Sory by timestamp.
            response.data[brand].sort((a, b) =>
              a.occurred > b.occurred ? 1 : -1
            );

            for (const charge of response.data[brand]) {
              console.log(charge.occurred);

              let last4 = "";

              if (charge.last_4_digits) {
                last4 = `*${charge.last_4_digits}`;
              }

              let failReason = "";
              if (charge.failure_reason) {
                failReason = `(${charge.failure_reason})`;
              }

              const str = `<strong>${moment
                .unix(charge.occurred)
                .format("MMM DD, YYYY HH:mm:ss")}</strong> <br/>
                - Product: ${charge.description} <br/>
                - Source: ${charge.payment_method_brand} ${last4}<br/>
                - Amount ${
                  charge.status !== "failed" ? "charged" : "attempted"
                }: ${charge.amount_formatted} <br/>
                - Status: ${charge.status_text} ${failReason}`;

              if (charge.amount_refunded !== "0.00") {
                str = `${str} <br/>- Amount refunded: ${charge.amount_refunded_formatted}`;
              }

              result.push(str);
              result.push("");
            }
            result.push("<br/>");
          }

          const message = (await zendeskClient.get("comment.text"))[
            "comment.text"
          ];

          zendeskClient.set("comment.type", "internalNote");

          zendeskClient.set(
            "comment.text",
            `${message}

~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~<br/>
|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${"this is a record of all charges across all our brands".toUpperCase()}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|<br/>
|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- you need to remove this notice&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|<br/>
|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- you need to remove irrelevant charges listed from other brands&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|<br/>
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~<br/><br/>

${result.join(`<br/>`)}`
          );

          setLoading(false);
        }}
      >
        Billing Summary
      </a>
    </small>
  );
};

export default BillingSummary;
