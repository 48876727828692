import React, { useState } from "react";

import { FirestoreCollection, CurrentUser } from "common";

import MessageUserDetails from "./MessageUserDetails";

const DEFAULT_LOADING = {
  isLoaded: false,
  isLoadingMore: false,
  hasMoreMessages: null,
  onLoadMore: () => {},
  messages: []
};

const MessagesList = (props) => {
  const {
    chatId,
    threadId,
    defaultMessageLimit,
    newMessageCount // number - optional - how many extra messages to show. Increment this as new messages are added so old messages aren't hidden by the limit.
  } = props;

  const [messageLimit, setMessageLimit] = useState(defaultMessageLimit || 15);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const loadNumMessages =
    messageLimit +
    (newMessageCount || 0) +
    1; /* Load an extra one to know if there are earlier messages*/

  return (
    <CurrentUser>
      {(currentUser) => {
        let filter = [];

        if (currentUser.account_type !== "partner") {
          filter.push(["is_internal", "==", false]);
          filter.push(["is_deleted", "==", false]);
        }

        return (
          <FirestoreCollection
            collection={`chats/${chatId}/threads/${threadId}/messages`}
            sort={"created_at:desc"}
            limit={loadNumMessages}
            filter={filter}
            onSnapshot={() => {
              setIsLoadingMore(false);
            }}
          >
            {({ isLoading, data }) => {
              if (isLoading) {
                return props.children(DEFAULT_LOADING);
              }

              let messages = [].concat(data);

              let hasMoreMessages = data.length > loadNumMessages - 1;

              // Remove the last item. We always load one extra item to see if there's more messages.
              messages = messages.slice(0, loadNumMessages - 1);

              // Since we want to limit to the last X messages, we load descending then reverse the array.
              messages.reverse();

              let activeUsers = {};
              messages.forEach((message) => {
                activeUsers[message.user_id] = true;
              });

              return (
                <MessageUserDetails activeUsers={Object.keys(activeUsers)}>
                  {({ isLoading, users }) => {
                    if (isLoading) {
                      return props.children(DEFAULT_LOADING);
                    }

                    messages = messages.map((message, idx) => {
                      // Determine if the user is different in the subsequent message.
                      const prevMessageUserIsDifferent = messages[idx - 1]
                        ? messages[idx - 1].user_id !== message.user_id
                        : true;

                      const nextMessageUserIsDifferent = messages[idx + 1]
                        ? messages[idx + 1].user_id !== message.user_id
                        : true;

                      return {
                        ...message,
                        user: users[message.user_id],
                        prev_message_user_is_different: prevMessageUserIsDifferent,
                        next_message_user_is_different: nextMessageUserIsDifferent
                      };
                    });

                    return props.children({
                      isLoaded: true, // initial loading status
                      isLoadingMore: isLoadingMore, // loading more status
                      hasMoreMessages: hasMoreMessages,
                      onLoadMore: () => {
                        setIsLoadingMore(true);
                        setMessageLimit(
                          (messageLimit) => loadNumMessages + 5 - 1
                        );
                      },
                      messages: messages
                    });
                  }}
                </MessageUserDetails>
              );
            }}
          </FirestoreCollection>
        );
      }}
    </CurrentUser>
  );

  /*
  return (
    <ThreadActiveUserDetails chatId={chatId} threadId={threadId}>
      {({ isLoading, users }) => {
        if (isLoading) {
          return props.children(DEFAULT_LOADING);
        }
        console.log("Loaded acfive users");
        return (
          <FirestoreCollection
            collection={`chats/${chatId}/threads/${threadId}/messages`}
            sort={"created_at:desc"}
            limit={loadNumMessages}
            onSnapshot={() => {
              setIsLoadingMore(false);
            }}
          >
            {({ isLoading, data }) => {
              if (isLoading) {
                return props.children(DEFAULT_LOADING);
              }
              console.log("Loaded messages");
              let messages = [].concat(data);

              let hasMoreMessages = data.length > loadNumMessages - 1;

              // Remove the last item. We always load one extra item to see if there's more messages.
              messages = messages.slice(0, loadNumMessages - 1);

              // Since we want to limit to the last X messages, we load descending then reverse the array.
              messages.reverse();

              messages = messages.map((message) => {
                return {
                  ...message,
                  user: users[message.user_id]
                };
              });

              console.log(messages);

              return props.children({
                isLoaded: true, // initial loading status
                isLoadingMore: isLoadingMore, // loading more status
                hasMoreMessages: hasMoreMessages,
                onLoadMore: () => {
                  setIsLoadingMore(true);
                  setMessageLimit((messageLimit) => loadNumMessages + 5 - 1);
                },
                messages: messages
              });
            }}
          </FirestoreCollection>
        );
      }}
    </ThreadActiveUserDetails>
  );*/
};

export default MessagesList;
