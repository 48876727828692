class NavigationStore {
  history = [];

  /*
  pop() {
    return this.history.pop();
  }
  */
}
/*
decorate(NavigationStore, {
  history: observable
});
*/
export default NavigationStore;
