import React from "react";
import ReactLoading from "react-loading";

const Loading = props => {
  return (
    <div
      style={{
        visibility: props.visibility ? props.visibility : "visible",
        ...props.style
      }}
    >
      <ReactLoading
        type={"spinningBubbles"}
        height={props.size || 25}
        width={props.size || 25}
        color={props.color || "#000"}
      />
    </div>
  );
};

export default Loading;
