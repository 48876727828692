import React, { useState } from "react";
import { Alert } from "reactstrap";
import { EditModal } from "common";

import {
  CURRENT_USER_COLORS,
  OTHER_USER_COLORS,
  INTERNAL_MESSAGE_COLORS,
  SYSTEM_MESSAGE_COLORS
} from "./const";

import ChatMessage from "api/ChatMessage";

import Bubble from "./Bubble";

const MessageBubbleStaffModal = (props) => {
  const { chatId, threadId, message, onClose, onSave } = props;

  const chatMessage = new ChatMessage(chatId, threadId, message.id);

  return (
    <EditModal
      isOpen={true}
      title={`Message ${message.id}`}
      hideSaveButton={true}
      secondaryButtonText="Close"
      onClose={onClose}
      onSave={() => {
        onSave();
      }}
    >
      <Bubble
        message={{
          ...message,
          prev_message_user_is_different: true // saying the prev message user is different forces the name to show. so override it.
        }}
        showAsCurrentUser={true}
      />
      <hr />
      <ul>
        <li>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              chatMessage.setIsInternal(!message.is_internal);
            }}
          >
            {message.is_internal ? (
              <span>
                <i className="fa fa-refresh" /> Make public
              </span>
            ) : (
              <span>
                <i className="fa fa-refresh" /> Make internal
              </span>
            )}
          </a>
        </li>
        <li>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              chatMessage.setIsDeleted(!message.is_deleted);
            }}
          >
            {message.is_deleted ? (
              <span>
                <i className="fa fa-undo" /> Un-Delete
              </span>
            ) : (
              <span>
                <i className="fa fa-trash" /> Delete
              </span>
            )}
          </a>
        </li>
      </ul>
    </EditModal>
  );
};

export default MessageBubbleStaffModal;
