import React, { useState, useEffect } from "react";

const BodyScrollDisable = (props) => {
  const { disabled } = props;

  const [resetBodyOverflow, setResetBodyOverflow] = useState(true);

  const allow = () => {
    // Only allow body scroll if it was set to hidden directly.
    // If over was already hidden, and this component didnt hide it, don't undo it.
    // This allows recipes to popup from a messages modal without changing the overflow state.

    if (!resetBodyOverflow) {
      return;
    }
    document.getElementsByTagName("body")[0].style.overflow = "";
  };

  const prevent = () => {
    const overflow = document.getElementsByTagName("body")[0].style.overflow;

    if (overflow === "hidden") {
      // Do not want to reset the overlow.

      setResetBodyOverflow(false);
      return;
    }
    setResetBodyOverflow(true);
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
  };

  useEffect(() => {
    if (disabled) {
      return;
    }

    prevent();

    return allow;
  }, []);

  return props.children || null;
};

export default BodyScrollDisable;
