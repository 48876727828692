import React from "react";

import { FirestoreDocument } from "common";

const ThreadDocument = (props) => {
  const { chatId, threadId, onLoad } = props;

  return (
    <FirestoreDocument
      collection={`chats/${chatId}/threads`}
      id={threadId}
      onLoad={onLoad}
    >
      {props.children}
    </FirestoreDocument>
  );
};

export default ThreadDocument;
