/*
Component that renders its children only for admin users.
*/

import { inject } from "mobx-react";

const AdminUserRender = inject("rootStore")((props) => {
  const {
    rootStore: { userStore }
  } = props;
  if (userStore.user.get("account_type") === "admin") {
    return props.children;
  }
  return null;
});
export default AdminUserRender;
