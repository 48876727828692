import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

import { Search, Loading } from "common";
import { BRANDS } from "const";

import { ELASTIC_SEARCH } from "config";

import {
  SearchkitManager,
  SearchkitProvider,
  SearchBox,
  Hits,
  NoHits,
  InitialLoader
} from "searchkit";

import NavHeader from "views/pages/Zendesk/NavHeader";

import SearchClientQueryBuilder from "components/SearchClientQueryBuilder";

import { get } from "lodash";

import "./styles.css";

const searchkit = new SearchkitManager(`${ELASTIC_SEARCH.url}/search-users3`, {
  httpHeaders: { Authorization: `ApiKey ${ELASTIC_SEARCH.api_key}` }
});

const ZendeskSearch = (props) => {
  const { brandId } = props;

  return (
    <Row className="m-0 p-0">
      <Col className="">
        <NavHeader zendeskClient={props.zendeskClient} page="search" />
        <SearchkitProvider searchkit={searchkit}>
          <>
            <SearchBox
              searchOnChange={true}
              queryOptions={{
                analyzer: "standard"
              }}
              queryBuilder={SearchClientQueryBuilder}
            />
            <Hits
              hitsPerPage={50}
              highlightFields={[
                "email",
                "first_name",
                "last_name",
                "paypal_email"
              ]}
              sourceFilter={[
                "email",
                "first_name",
                "last_name",
                "brand_id",
                "created_at",
                "paypal_email"
              ]}
              listComponent={({ hits }) => {
                return hits.map((hit, idx) => {
                  const data = hit._source;
                  return (
                    <>
                      <p
                        key={idx}
                        style={{
                          color: brandId !== data.brand_id ? "#666" : null,
                          marginBottom: 0
                        }}
                      >
                        <small>
                          {data.brand_id}
                          {": "}
                          <Link
                            to={`/zendesk/user/${hit._id}`}
                            style={{
                              color: brandId !== data.brand_id ? "#666" : null
                            }}
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: get(
                                  hit.highlight,
                                  ["first_name", "0"],
                                  data.first_name
                                )
                              }}
                            />{" "}
                            <span
                              dangerouslySetInnerHTML={{
                                __html: get(
                                  hit.highlight,
                                  ["last_name", "0"],
                                  data.last_name
                                )
                              }}
                            />
                            {" - "}
                            <span
                              dangerouslySetInnerHTML={{
                                __html: get(
                                  hit.highlight,
                                  ["email", "0"],
                                  data.email
                                )
                              }}
                            />
                          </Link>
                        </small>
                      </p>
                      {data.paypal_email &&
                      data.paypal_email.toLowerCase() !==
                        data.email.toLowerCase() ? (
                        <small>
                          <p
                            style={{ marginBottom: 0, marginLeft: 15 }}
                            dangerouslySetInnerHTML={{
                              __html: get(
                                hit.highlight,
                                ["paypal_email", "0"],
                                data.paypal_email
                              )
                            }}
                          />
                        </small>
                      ) : null}
                    </>
                  );
                });
              }}
            />
            <NoHits />
            <InitialLoader component={Loading} />
          </>
        </SearchkitProvider>
      </Col>
    </Row>
  );
};

export default ZendeskSearch;
