import React from "react";

import Modal from "react-modal";

import { Loading } from "common";

const LoadingModal = props => {
  return (
    <Modal
      ariaHideApp={false}
      isOpen={props.isOpen}
      style={{
        overlay: {},
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          padding: 0,
          border: "none",
          background: "transparent"
        }
      }}
    >
      <center>
        <Loading />
      </center>
    </Modal>
  );
};
export default LoadingModal;
