import React from "react";

const PageBody = (props) => {
  return (
    <div style={{ marginTop: "2rem", paddingBottom: "4rem", ...props.style }}>
      {props.children}
    </div>
  );
};
export default PageBody;
