/*
Render a child function with the current partner.
Loads from the api, because client's don't have access to firestore partner collection.
*/
import React, { useState, useEffect } from "react";
import Backend from "util/Backend";

import { CurrentUser } from "common";

const CurrentUserPartner = (props) => {
  return (
    <CurrentUser>
      {(user) => {
        return <LoadPartner user={user}>{props.children}</LoadPartner>;
      }}
    </CurrentUser>
  );
};

const LoadPartner = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [partner, setPartner] = useState(null);

  useEffect(() => {
    const load = async () => {
      const response = await Backend.get(`partners/${props.user.partner_id}`);
      setPartner(response.data);
      setIsLoading(false);
    };
    load();
  }, [props.user.partner_id]);

  return props.children({ isLoading, partner, user: props.user });
};

export default CurrentUserPartner;
