import Backend from "util/Backend";

class EmailMarketingIntegration {
  constructor(userId) {
    this.userId = userId;
  }

  unsubscribe() {
    return Backend.post(
      `users/${this.userId}/integrations/email_marketing/unsubscribe`
    );
  }

  /**
  Force the unsubscribe of an email when we don't know what account it belongs to.
  */
  static unsubscribeEmailAddress(email) {
    return Backend.post(
      `users/integrations/email_marketing/global_unsubscribe`,
      { email }
    );
  }

  static partnerHasIntegration(partnerDocument) {
    return (
      partnerDocument.integrations &&
      partnerDocument.integrations.klaviyo &&
      partnerDocument.integrations.klaviyo.enabled
    );
  }
}
export default EmailMarketingIntegration;
