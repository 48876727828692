/**
 Show an alert with the number of other tickets
*/
import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";

const OtherSolvedTickets = props => {
  const { zendeskClient } = props;

  const [requestedTickets, setRequestedTickets] = useState({});

  useEffect(() => {
    const go = async () => {
      const result = await zendeskClient.get("ticket");
      const requesterUserId = result.ticket.requester.id;

      const zendeskResult = await zendeskClient.request({
        url: `/api/v2/users/${requesterUserId}/tickets/requested.json`,
        type: "GET",
        dataType: "json"
      });

      let tickets = {};

      const solvedOrClosedTickets = zendeskResult.tickets.filter(
        ({ status, id }) => {
          if (!tickets[status]) {
            tickets[status] = 0;
          }
          tickets[status]++;
        }
      );

      setRequestedTickets(tickets);
    };

    go();
  }, []);

  /*
  if (otherTicketCount === null || otherTicketCount < 1) {
    return null;
  }

  const openRequesterTicketList = async e => {
    e.preventDefault();
    const result = await zendeskClient.get("ticket");
    const requesterUserId = result.ticket.requester.id;
    zendeskClient.invoke("routeTo", "user", requesterUserId);
  };
  */

  return (
    <Row className="mb-2 ml-0 mr-0">
      <Col style={{ fontSize: 11 }}>
        <span
          style={{
            background: "rgb(255, 182, 72)",
            fontWeight: "bold",
            fontSize: 9,
            padding: 4,
            borderRadius: 2
          }}
        >
          N
        </span>{" "}
        {requestedTickets.new || "-"}
      </Col>
      <Col style={{ fontSize: 11 }}>
        <span
          style={{
            background: "rgb(227, 79, 50)",
            fontWeight: "bold",
            fontSize: 9,
            padding: 4,
            color: "#FFF",
            borderRadius: 2
          }}
        >
          O
        </span>{" "}
        {requestedTickets.open || "-"}
      </Col>
      <Col style={{ fontSize: 11 }}>
        <span
          style={{
            background: "rgb(48, 145, 236)",
            fontWeight: "bold",
            fontSize: 9,
            padding: 4,
            color: "#FFF",
            borderRadius: 2
          }}
        >
          P
        </span>{" "}
        {requestedTickets.pending || "-"}
      </Col>
      <Col style={{ fontSize: 11 }}>
        <span
          style={{
            background: "#87929D",
            fontWeight: "bold",
            fontSize: 9,
            padding: 4,
            color: "#FFF",
            borderRadius: 2
          }}
        >
          S
        </span>{" "}
        {requestedTickets.solved || "-"}
      </Col>
      <Col style={{ fontSize: 11 }}>
        <span
          style={{
            background: "#D8DCDE",
            fontWeight: "bold",
            fontSize: 9,
            padding: 4,
            color: "#FFF",
            borderRadius: 2
          }}
        >
          C
        </span>{" "}
        {requestedTickets.closed || "-"}
      </Col>
    </Row>
  );
};

export default OtherSolvedTickets;
