/**
Render a child function with the loaded users information.
*/
import React from "react";

import { FirestoreDocument } from "common";

import User from "api/User";

const UserDocument = (props) => {
  let { userId, uid, partnerId, brandId } = props;

  if (userId) {
    // Determine uid,partnerId,brandId
    const obj = User.getUserDetailsFromUserId(userId);
    uid = obj.uid;
    partnerId = obj.partner_id;
    brandId = obj.brand_id;
  }

  return (
    <FirestoreDocument collection="users" id={`${uid}-${partnerId}-${brandId}`}>
      {({ isLoading, data, error }) => {
        if (isLoading || data) {
          return props.children({ isLoading, data, error });
        }

        return null;

        /*
        if (!data || error) {
          // Try loading using the old uid.
          return (
            <FirestoreDocument collection="users" id={uid}>
              {results => {
                return props.children(results);
              }}
            </FirestoreDocument>
          );
        }*/
      }}
    </FirestoreDocument>
  );
};

export default UserDocument;
