/**

A component that saves history to the navigaiton store.

Props:
title: title of the current page.

*/
import { useEffect } from "react";
import { inject } from "mobx-react";
import { withRouter } from "react-router-dom";

const NavigationHistory = inject("rootStore")(
  withRouter((props) => {
    const {
      rootStore: { navigationStore }
    } = props;

    const getLink = () => {
      let search = "";
      if (window.location.search) {
        search = window.location.search;
      }
      return window.location.pathname + search;
    };

    useEffect(() => {
      if (
        navigationStore.history.length > 0 &&
        navigationStore.history[navigationStore.history.length - 1].link ===
          props.link
      ) {
        // Dont add to the history stack if the last item is the current page.
        //console.log("already have this page at the top of history stack");
        return;
      }
      //console.log("Adding", props.link, "to the history");

      navigationStore.history.push({
        link: getLink(),
        title: props.title
      });
    }, [navigationStore.history, props.link, props.title]);

    useEffect(() => {
      // Search has changed. Need to update in the history store.
      navigationStore.history[
        navigationStore.history.length - 1
      ].link = getLink();

      //console.log(JSON.stringify(toJS(navigationStore.history), null, 2));
    }, [props.history.location.search]);

    return null;
  })
);
export default NavigationHistory;
