/**
Render a child function with the loaded partner user.
*/
import React from "react";

import { FirestoreDocument } from "common";

const PartnerUser = (props) => {
  const { uid } = props;

  return (
    <FirestoreDocument collection="partner_users" id={uid}>
      {(result) => {
        return props.children(result);
      }}
    </FirestoreDocument>
  );
};

export default PartnerUser;
