import { observable, decorate } from "mobx";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import Event from "api/Event";

import UserStore from "./UserStore";
import EditorStore from "./EditorStore";
import PartnerStore from "./PartnerStore";
import NavigationStore from "./NavigationStore";
import AlgoliaSearchStore from "./AlgoliaSearchStore";

import Log from "util/Log";

class RootStore {
  build = null;
  newBuildAvailable = false;

  authSuccess = false;
  authFail = false;

  appReady = false;

  constructor() {
    this.userStore = new UserStore();
    this.editorStore = new EditorStore();
    this.partnerStore = new PartnerStore();
    this.navigationStore = new NavigationStore();
    this.algoliaSearchStore = new AlgoliaSearchStore();
    this.init();
  }

  async init() {
    //await this.loadBuild();

    const user = await this.loadUserAuth();
    if (user) {
      await this.setupLoggedInUser(user);
    } else {
      // No auth.
    }

    let skipFirstRun = true;

    // Listen for changes to authentication.
    firebase.auth().onAuthStateChanged((user) => {
      if (skipFirstRun) {
        skipFirstRun = false;
        return;
      }

      if (user) {
        this.setupLoggedInUser(user);
      } else {
        this.authSuccess = false;
        // User logged out. Reset the stores.
        this.userStore = new UserStore();
        this.partnerStore = new PartnerStore();
      }
    });

    // App is ready to roll.
    this.appReady = true;
  }

  // Load the user authentication.
  async loadUserAuth() {
    return new Promise((resolve) => {
      const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
        unsubscribe();
        resolve(user);
      });
    });
  }

  async setupLoggedInUser(user) {
    try {
      await this.userStore.init(user);

      // Clients dont have access to partner information.
      if (this.userStore.user.get("account_type") !== "client") {
        await this.partnerStore.init(this.userStore.user.get("partner_id"));
      }
      this.authEmail = user.email;
      this.authSuccess = true;

      Log("Authentication success", { uid: user.uid, email: user.email });

      Event.send("auth.success");
    } catch (e) {
      console.log(e);
      // Error setting up the user.
      // Log them out, and set the authFail to redirect.
      await this.userStore.logout();
      this.authFail = true;

      Event.send("auth.fail", e);

      Log("Authentication fail", e);
    }
  }

  /**
   * Load the current build number the app was launched with.
   * Set a interval checking it. If it changes, set the newBuildAvailable flag
   * When a newBuildAvailable, the modal in DefaultLayout will show itself telling the user to refresh.
   */
  /*
  async loadBuild() {
    const getBuild = async () => {
      const response = await axios.get("/build.js");
      return response.data;
    };

    this.build = await getBuild();

    this.buildInterval = setInterval(async () => {
      if ((await getBuild()) !== this.build) {
        clearTimeout(this.buildInterval);
        // delay to let the rest of the build finish uploading.
        // right now build.js is one of the first files to upload.
        // in the future, fix the release script and this can be removed.
        setTimeout(() => {
          this.newBuildAvailable = true;
        }, 120000);
      }
    }, 25000);
  }
  */
}

decorate(RootStore, {
  appReady: observable,
  authSuccess: observable,
  authFail: observable,
  newBuildAvailable: observable
});

export default RootStore;
