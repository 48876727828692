import React, { useState } from "react";
import { Alert } from "reactstrap";
import { Loading } from "common";
import EmailMarketing from "api/integrations/EmailMarketing";
import SmsMarketing from "api/integrations/SmsMarketing";

import Audit from "api/Audit";

const MarketingUnsubscribe = props => {
  const { user, partner, zendeskClient } = props;

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);

  if (success === true) {
    return (
      <Alert color="success" className="p-1 m-0">
        Unsubscribe success!
      </Alert>
    );
  }

  return (
    <>
      {success === false ? (
        <Alert color="danger" className="p-1 m-0">
          Error unsubscribing
        </Alert>
      ) : null}
      <small>
        <a
          href="#"
          onClick={async e => {
            e.preventDefault();
            if (loading) {
              return;
            }
            setLoading(true);

            // Do the unsubscribe.
            try {
              await new EmailMarketing(user.id).unsubscribe();
              //await new SmsMarketing(user.id).unsubscribe();

              zendeskClient.set("ticket.status", "solved");
              const message = (await zendeskClient.get("comment.text"))[
                "comment.text"
              ];
              // Only send the macro if the message is empty, and the macro id exists.
              if (
                (message === "" || message === "<p></p>") &&
                partner.integrations &&
                partner.integrations.zendesk &&
                partner.integrations.zendesk
                  .email_marketing_unsubscribe_macro_id
              ) {
                const macroId =
                  partner.integrations.zendesk
                    .email_marketing_unsubscribe_macro_id;

                zendeskClient.invoke("macro", macroId);
              }

              new Audit("client", user.id).event("Unsubscribed from marketing");

              setSuccess(true);
            } catch (e) {
              setSuccess(false);
            }
            setLoading(false);
            return false;
          }}
        >
          Unsubscribe email
        </a>
        {loading ? (
          <Loading
            size={12}
            style={{ display: "inline-block", marginLeft: 5 }}
          />
        ) : null}
      </small>
    </>
  );
};

export default MarketingUnsubscribe;
