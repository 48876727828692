import FirestoreDocument from "./FirestoreDocument";

const MealPlanDocument = (props) => {
  return (
    <FirestoreDocument collection="meal_plans" id={props.id}>
      {props.children}
    </FirestoreDocument>
  );
};

export default MealPlanDocument;
