import Firestore from "util/Firestore";

class PartnerUser {
  constructor(uid) {
    this.uid = uid;
  }

  get() {
    return Firestore.db
      .collection("partner_users")
      .doc(this.uid)
      .get();
  }
}

export default PartnerUser;
