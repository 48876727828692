import React from "react";

import moment from "moment";

import {
  CURRENT_USER_COLORS,
  OTHER_USER_COLORS,
  INTERNAL_MESSAGE_COLORS,
  SYSTEM_MESSAGE_COLORS
} from "./const";

const Bubble = (props) => {
  const {
    message,
    showAsCurrentUser, // should the message be shown as from the current user
    currentUserIsStaff, // is the current user staff
    onClick,
    clientHasViewed
  } = props;

  const {
    text,
    user,
    prev_message_user_is_different,
    created_at,
    is_internal,
    is_deleted
  } = message;

  return (
    <div style={{ minWidth: 150 }} onClick={onClick}>
      {prev_message_user_is_different ? (
        <p style={{ fontSize: 11, margin: 0 }}>{user?.first_name}</p>
      ) : null}
      <div
        style={{
          border: "1px solid #ccc",
          borderRadius: 8,
          padding: 5,
          paddingLeft: 10,
          paddingRight: 10,
          marginBottom: 3,
          cursor:
            currentUserIsStaff || !showAsCurrentUser ? "pointer" : "inherit",
          ...(showAsCurrentUser ? CURRENT_USER_COLORS : OTHER_USER_COLORS),
          ...(is_internal ? INTERNAL_MESSAGE_COLORS : {}),
          opacity: is_deleted ? 0.33 : 1
        }}
      >
        <pre
          style={{
            fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial`,
            fontSize: 14,
            whiteSpace: "pre-wrap",
            ...(showAsCurrentUser ? CURRENT_USER_COLORS : OTHER_USER_COLORS),
            ...(is_internal ? INTERNAL_MESSAGE_COLORS : {}),
            margin: 0
          }}
        >
          {text}
        </pre>
        <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
          <div>
            {clientHasViewed &&
            showAsCurrentUser &&
            !is_internal &&
            !is_deleted ? (
              <i className="fa fa-check" style={{ fontSize: 9 }} />
            ) : null}
          </div>
          <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
            <p style={{ margin: 0 }}>
              {is_deleted ? <small>deleted </small> : null}
              <DateDisplay createdAt={created_at} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const DateDisplay = (props) => {
  const { createdAt } = props;
  const m = moment.unix(createdAt / 1000);
  let display = m.format("h:mm a");

  if (m.isBefore(moment(), "day")) {
    display = m.format("MMM D h:mm a");
  }

  return <small>{display}</small>;
};

export default Bubble;
