/**
 * The card that is loaded first, and determines which type of get started this is.
 */
import React from "react";

import { Card, CardBody, CardHeader, Button } from "reactstrap";

import ProfileUpdateCard from "./ProfileUpdateCard";
import SamplePlanCard from "./SamplePlanCard";
import ExclusionsUpdateCard from "./ExclusionsUpdateCard";

import ValidateSample from "./ValidateSample";
import ValidateUser from "./ValidateUser";

const StartCard = (props) => {
  const { accessKey, actions, onValidate } = props;

  // If requesting a sample plan, always show that first.
  if (actions.includes("s")) {
    // Sample plan validates itself.
    return (
      <ValidateSample accessKey={accessKey} onValidate={onValidate}>
        {({ email }) => {
          return <SamplePlanCard email={email} {...props} />;
        }}
      </ValidateSample>
    );
  }

  // Otherwise, show profile first.
  if (actions.includes("p")) {
    return (
      <ValidateUser accessKey={accessKey} onValidate={onValidate}>
        {({ email }) => {
          return <ProfileUpdateCard email={email} {...props} />;
        }}
      </ValidateUser>
    );
  }

  // Finally, fallback to exclusions.
  return (
    <ValidateUser accessKey={accessKey} onValidate={onValidate}>
      {({ email }) => {
        return <ExclusionsUpdateCard email={email} {...props} />;
      }}
    </ValidateUser>
  );
};

export default StartCard;
