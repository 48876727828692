/**
Tabs used for filtering.

<FilterTabs.Container>
  <FilterTabs.Tab onClick={() => { // Set filter state }}>Tab Name</FilterTabs.Tab>
</FilterTabs.Container>

*/
import React from "react";
import { Nav, NavLink } from "reactstrap";

const Container = props => {
  return <Nav>{props.children}</Nav>;
};

const Tab = props => {
  const selectedBorder = "3px solid";
  return (
    <NavLink
      href="#"
      className="subtle"
      style={{
        fontSize: 18,
        borderBottom: props.selected ? selectedBorder : null
      }}
      onClick={e => {
        e.preventDefault();
        props.onClick();
      }}
    >
      {props.children}
    </NavLink>
  );
};

const filterTabs = {
  Tab,
  Container
};

export default filterTabs;
