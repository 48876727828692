import { observable, decorate, autorun } from "mobx";

class EditorStore {
  isEditMode = false;

  isSaveLoading = false;
  notificationType = null;
  notificationMessage = null;

  // When the save button is clicked in the EditorHeader
  onSaveClick = null;
  onDiscardClick = null;

  errors = new Map();
  showErrors = false;

  // Prevent the prompt from telling the user they have unsaved changes.
  preventPrompt = false;

  constructor() {
    // Cleanup variables when the edit mode is no longer active.
    autorun(() => {
      if (!this.isEditMode) {
        console.log("EditorStore: Reset functions");
        this.onSaveClick = null;
        this.onDiscardClick = null;
      }
    });
  }

  saveLoading() {
    this.isSaveLoading = true;
  }

  saveComplete() {
    this.isSaveLoading = false;
  }

  saveError(message) {
    this.notificationType = "danger";
    this.notificationMessage = message;
    this.isSaveLoading = false;
    this.hideNotificationAfterDelay();
  }

  saveSuccess(message) {
    this.isSaveLoading = false;
    if (!message) {
      // Don't show a message.
      return;
    }
    this.notificationType = "success";
    this.notificationMessage = message;
    this.hideNotificationAfterDelay();
  }

  hideNotificationAfterDelay() {
    setTimeout(() => {
      this.notificationType = null;
      this.notificationMessage = null;
    }, 6000);
  }
}

decorate(EditorStore, {
  isEditMode: observable,
  onSaveClick: observable,
  onDiscardClick: observable,
  hasErrors: observable,
  showErrors: observable,
  isSaveLoading: observable,
  notificationType: observable,
  notificationMessage: observable,
  preventPrompt: observable
});

export default EditorStore;
