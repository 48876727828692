/**
 * Holds information for the current partner that the user is apart of.
 */

import { observable, decorate } from "mobx";
import Firestore from "util/Firestore";

class PartnerStore {
  partner = new Map();

  async init(partnerId) {
    await this.load(partnerId);
  }

  async load(partnerId) {
    if (this.listenerAttached) {
      return;
    }

    const doc = Firestore.db.collection("partners").doc(partnerId);
    const docSnapshot = await doc.get();
    this.partner = new Map(Object.entries(docSnapshot.data()));
    this.partner.set("id", partnerId);

    this.listenerAttached = true;
    doc.onSnapshot((docSnapshot) => {
      let partner = new Map(Object.entries(docSnapshot.data()));
      partner.set("id", partnerId);
      this.partner = partner;
    });
  }
}

decorate(PartnerStore, {
  partner: observable
});
export default PartnerStore;
