/*
Render child function with search state information.

Props:
updateUrl - update the URL with ?search={query}

*/
import { connectStateResults } from "react-instantsearch-dom";

const State = connectStateResults((props) => {
  const { searchState, searchResults } = props;

  return props.children({
    hasResults: searchResults && searchResults.nbHits !== 0,
    hasQuery: searchState && searchState.query
  });
});

export default State;
