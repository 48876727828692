// https://github.com/green-arrow/react-firestore/blob/master/src/FirestoreDocument.js

import React from "react";

import Firestore from "util/Firestore";

class FirestoreDocuments extends React.Component {
  constructor(props) {
    super(props);
    const stateObj = {};
    const idSet = new Set(props.ids);
    for (const id of idSet) {
      stateObj[id] = null;
    }
    this.state = stateObj;
    this.isLoaded = false;
  }

  componentDidMount() {
    this._setupListener(this.props);
  }

  componentWillUnmount() {
    this._handleUnsubscribe();
  }

  /*
  static getDerivedStateFromProps(props, state) {
    const stateObj = {};
    for (const id of props.ids) {
      stateObj[id] = state[id] || null;
    }
    console.log("New state", stateObj);
    return stateObj;
  }
  */

  componentDidUpdate(prevProps) {
    if (
      prevProps.ids !== this.props.ids ||
      prevProps.collection !== this.props.collection
    ) {
      this._handleUnsubscribe();
      this._setupListener();
    }
  }

  /*
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.ids !== this.props.ids ||
      nextProps.collection !== this.props.collection
    ) {
      this._handleUnsubscribe();

      const stateObj = {};
      for (const id of nextProps.ids) {
        stateObj[id] = this.state[id] || null;
      }
      console.log("new State", stateObj);
      this.setState(stateObj, () => {
        this._setupListener(nextProps);
      });
    }
  }
  */

  _setupListener() {
    const { collection, ids } = this.props;
    //const docRef = Firestore.db.collection(collection).doc(id);

    const idSet = new Set(ids);

    let docRefs = [];
    for (const id of idSet) {
      docRefs.push(Firestore.db.collection(collection).doc(id));
    }

    this.unsubscribes = [];
    for (const docRef of docRefs) {
      this.unsubscribes.push(
        docRef.onSnapshot(
          (docSnap) => {
            //setTimeout(() => {
            if (docSnap && docSnap.exists) {
              const documentData = docSnap.data();

              this.setState((oldState) => {
                let newState = {
                  ...oldState
                };

                // Delete old ids that arent in props anymore.
                Object.keys(newState).forEach((key) => {
                  if (!ids.includes(key)) {
                    delete newState[key];
                  }
                });

                newState[docSnap.id] = documentData;

                // See if all ids are loaded.
                const allValues = Object.values(newState);
                const allValuesNotNull = allValues.filter((x) => x !== null);
                if (allValuesNotNull.length >= idSet.size) {
                  if (this.props.onAllLoad && !this.onAllLoadComplete) {
                    this.props.onAllLoad();
                    this.onAllLoadComplete = true;
                  }
                }
                return newState;
              });
            }
            //}, 1000);
          },
          (error) => {}
        )
      );
    }
  }

  _handleUnsubscribe() {
    if (this.unsubscribes) {
      this.unsubscribes.forEach((f) => {
        f();
      });
    }
    this.onAllLoadComplete = null;
  }

  render() {
    // only render when all items are loaded.

    // For some reason state doesn't change? Can't seem to update it with newState in onSnapshot.
    // To fix, duplicate the object and remove items that arent in the props.ids array.
    const nState = {
      ...this.state
    };

    Object.keys(nState).forEach((key) => {
      if (!this.props.ids.includes(key)) {
        delete nState[key];
      }
    });

    const allValues = Object.values(nState);
    const allValuesNotNull = allValues.filter((x) => x !== null);

    /*
    console.log("AVNN", allValuesNotNull.length);
    console.log("ID LEN", this.props.ids.length);
    console.log("state", this.state);
    */

    if (allValuesNotNull.length < Object.keys(nState).length) {
      /*
      let obj = {};
      for (const id of this.props.ids) {
        obj[id] = null;
      }
      */
      return this.props.children({
        state: nState,
        isLoading: true
      });
    }

    // All are loaded.
    return this.props.children({
      state: nState,
      isLoading: false
    });
  }
}

export default FirestoreDocuments;
