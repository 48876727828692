import FirestoreDocument from "./FirestoreDocument";

const MealPlanShoppingDocument = (props) => {
  return (
    <FirestoreDocument collection="meal_plan_shopping" id={props.id}>
      {props.children}
    </FirestoreDocument>
  );
};

export default MealPlanShoppingDocument;
