/**

Page to print. Because this is not loaded as a child of containers/DefaultLayout, it needs to make sure the app is ready for auth purposes.

This lets it open in a new tab and work properly.

*/
import React, { Component } from "react";
import { Row, Col, Container, Card, CardBody } from "reactstrap";
import { inject, observer } from "mobx-react";
import { Template, Loading } from "common";
import Backend from "util/Backend";

import queryString from "qs";

import firebase from "@firebase/app";
import "@firebase/firestore";

import "./PrintHtml.css";

const PrintHtmlPage = inject("rootStore")(
  observer((props) => {
    const { rootStore } = props;
    const { userStore } = rootStore;

    if (rootStore.appReady === false) {
      return null;
    }

    return <PrintHtml {...props} />;
  })
);

class PrintHtml extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      html: null,
      hideImages: false
    };
  }

  componentDidMount() {
    this._load();
  }

  async _load() {
    this.setState({
      loading: true,
      html: null
    });

    const { id } = this.props.match.params;
    const { week } = queryString.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });

    const html = await Backend.get(
      `meal_plans/${id}/html?week=${week}&hideImages=${this.state.hideImages}`
    );

    this.setState({
      html,
      loading: false
    });
  }

  render() {
    const { week } = queryString.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });

    return (
      <div className="flex-row" style={{ fontFamily: "monospace" }}>
        <Row className="justify-content-center">
          <Col xs={6} style={{ padding: 25, paddingTop: 0 }}>
            {this.state.loading ? (
              <div style={{ margin: 25 }}>
                <center>
                  <Loading />
                </center>
              </div>
            ) : (
              <>
                {/*<div
                  className="noprint"
                  style={{
                    padding: 10,
                    background: "#F7F7F7",
                    borderRadius: 5,
                    marginTop: 25,
                    marginBottom: 25
                  }}
                >
                  <strong>Printing options</strong>
                  <br />
                  <br />
                  <input
                    id="hide_images"
                    type="checkbox"
                    checked={this.state.hideImages}
                    onChange={(e) => {
                      this.setState(
                        {
                          hideImages: e.target.checked
                        },
                        () => {
                          this._load();
                        }
                      );
                    }}
                    style={{ marginRight: 5, marginTop: -5 }}
                  />
                  <label htmlFor="hide_images">Hide Images</label>
                  <br />
                  <br />
                </div>*/}
                <div dangerouslySetInnerHTML={{ __html: this.state.html }} />
              </>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default PrintHtmlPage;
