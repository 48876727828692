const CURRENT_USER_COLORS = {
  background: "#6EA7FD",
  color: "#FFF"
};
const OTHER_USER_COLORS = {
  background: "#F7F7F7"
};
const INTERNAL_MESSAGE_COLORS = {
  background: "#fff3cd",
  color: "#000"
};

const SYSTEM_MESSAGE_COLORS = {
  background: "orange"
};

export {
  CURRENT_USER_COLORS,
  OTHER_USER_COLORS,
  INTERNAL_MESSAGE_COLORS,
  SYSTEM_MESSAGE_COLORS
};
