/**
 * Given an array of activeUsers, load their info from the backend.
 * This lets us render a child components with user details without loading multiple times.
 */
import React, { useState, useEffect, useMemo } from "react";

import { CurrentUser } from "common";

import Backend from "util/Backend";

const MessageUserDetails = (props) => {
  const { activeUsers } = props;

  const [users, setUsers] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const load = async () => {
      const response = await Backend.post("messages/user_info", {
        user_ids: activeUsers
      });
      /*
      {
        first_name
        last_name
        is_staff
      }
      */
      setUsers(response.data.users);
      setIsLoading(false);
    };
    load();
  }, [activeUsers.length]);

  return (
    <CurrentUser>
      {(user) => {
        let renderUsers = Object.assign({}, users);

        // If it's the first message in a chat, and the user is staff,
        // the UI will show the message as non-staff (since the user_info hasn't loaded, and firestore writes render instantly). Then once the activeUsers.lenght changes, user_info loads and the user is correctly identified as staff.
        // This makes sure that partners are loaded and identified as staff initially.
        if (!renderUsers[user.uid] && user.account_type === "partner") {
          renderUsers[user.uid] = {
            is_staff: true
          };
        }

        return props.children({
          isLoading,
          users: renderUsers
        });
      }}
    </CurrentUser>
  );
};

export default MessageUserDetails;
