import React from "react";

import CurrentUser from "./CurrentUser";

const DebugEnabled = (props) => {
  // Render children if the debug is enabled.
  return (
    <CurrentUser>
      {(user) => {
        if (user.account_type === "partner") {
          return props.children;
        }
        return null;
      }}
    </CurrentUser>
  );
};

export default DebugEnabled;
