/*
Template page

props:
centerFixed - boolean (default false) - make the page fixed, and centered.

*/
import React from "react";

const Page = props => {
  return (
    <div className="animated fadeIn">
      <div className={props.centerFixed ? "col-fixed" : null}>
        {props.children}
      </div>
    </div>
  );
};
export default Page;
