import React, { useEffect } from "react";
import { inject } from "mobx-react";

import { Redirect } from "react-router-dom";

const Logout = inject("rootStore")(props => {
  useEffect(() => {
    const run = async () => {
      await props.rootStore.userStore.logout();
      window.location.href = "/";
    };
    run();
  }, []);

  return null;
});

export default Logout;
