// ErrorBoundary.js
import React, { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
    this.setState({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <center>
          <h3 style={{ marginTop: 50 }}>Something went wrong.</h3>
          <p>Refresh the page to try again.</p>
          {/*<details style={{ whiteSpace: "pre-wrap" }}>
            <summary>Click to see error information</summary>
            <p>{this.state.error && this.state.error.toString()}</p>
            <p>{this.state.errorInfo?.componentStack}</p>
          </details>*/}
        </center>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
