import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import NavHeader from "views/pages/Zendesk/NavHeader";

import { BRANDS } from "const";

const Resources = (props) => {
  const { zendeskClient } = props;

  const [brandIdState, setBrandIdState] = useState(props.brandId);

  if (!brandIdState) {
    return (
      <>
        <NavHeader zendeskClient={zendeskClient} page="resources" />
        <span>
          Can't determine the brand from the ticket. Please select the brand
          this is for:
        </span>
        <br />
        <ul>
          {Object.entries(BRANDS).map(([bID, { brand_name }]) => {
            return (
              <li key={bID}>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setBrandIdState(bID);
                  }}
                >
                  {brand_name}
                </a>
              </li>
            );
          })}
        </ul>
      </>
    );
  }

  const brand = BRANDS[brandIdState];

  return (
    <>
      <NavHeader zendeskClient={zendeskClient} page="resources" />
      <Row className="m-0 p-0 mb-0">
        <Col>
          Resources for {brand.brand_name}
          <ul>
            {brand.zendesk_resources ? (
              brand.zendesk_resources.map(
                ({ injectMessage, title, spacer }, idx) => {
                  if (spacer) {
                    return <li>--</li>;
                  }

                  return (
                    <li key={idx}>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();

                          const sendMessage = async (str) => {
                            const message = (
                              await zendeskClient.get("comment.text")
                            )["comment.text"];

                            zendeskClient.set(
                              "comment.text",
                              `${message}

${str}`
                            );
                          };

                          if (typeof injectMessage === "string") {
                            return sendMessage(injectMessage);
                          }
                          return sendMessage(injectMessage({ brand }));
                        }}
                      >
                        {title}
                      </a>
                    </li>
                  );
                }
              )
            ) : (
              <span>No resources available.</span>
            )}
          </ul>
        </Col>
      </Row>
    </>
  );
};

export default Resources;
