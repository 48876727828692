// THIS NEEDS TO MATCH EXACTLY WHATS ON THE SERVER SIDE.
// USE THE SERVER SIDE FILE AS THE SOURCe OF TRUTH -- DONT EDIT THIS ONE.
const SEARCH = {
  // common
  gluten: {
    pasta: [],
    noodle: [],
    bread: [],
    pita: [],
    naan: [],
    bagel: [],
    muffin: [],
    crouton: [],
    tortilla: [],
    yeast: [],
    wheat: []
  },
  chocolate: {},
  eggs: {},
  avocado: {},
  coffee: {},
  // Dairy
  dairy: {
    butter: [],
    milk: [],
    cream: [],
    "sour cream": [],
    yogurt: ["yogurt"]
  },
  cheese: {
    cheddar: [],
    mozzarella: [],
    parmesan: [],
    feta: [],
    "goat cheese": [],
    "cream cheese": ["cream cheese"],
    "cottage cheese": ["cottage"],
    ricotta: [],
    swiss: [],
    "monterey jack": []
  },
  "protein powder": {
    whey: ["whey"],
    casein: ["casein"]
    //vegan: ["vegan"] <-- SOON!
  },
  beef: {
    steak: ["beef tenderloin", "beef sirloin"],
    roasts: ["beef round"],
    ground: [],
    "deli meat": []
  },
  lamb: [],
  pork: {
    bacon: [],
    ham: [],
    sausage: ["pepperoni"],
    chops: [],
    ground: [],
    roasts: [],
    "deli meat": ["prosciutto"],
    ribs: []
  },
  chicken: {
    breast: [],
    wings: [],
    thighs: [],
    sausage: [],
    ground: [],
    "deli meat": [],
    drumsticks: []
  },
  turkey: {
    bacon: [],
    breast: [],
    sausage: [],
    ground: [],
    "deli meat": []
  },
  fish: {
    salmon: [],
    "tuna (fresh)": ["tuna"],
    "tuna (canned)": ["canned tuna"],
    halibut: [],
    tilapia: [],
    trout: [],
    cod: []
  },
  shellfish: {
    shrimp: [],
    mussels: [],
    clams: [],
    crab: [],
    scallops: [],
    lobster: []
  },
  grains: {
    "breakfast cereals": ["cereal"],
    /*pastas: ["pasta", "spaghetti", "penne", "fettucini"],*/
    oats: ["oatmeal"],
    "white rice": [],
    "brown rice": [],
    quinoa: [],
    flour: []
  },
  breads: {
    rye: ["rye bread"],
    wheat: ["wheat bread"],
    white: ["white bread"],
    sourdough: [],
    tortilla: []
  },
  pasta: {
    "bow tie": [],
    fettuccine: [],
    penne: [],
    lasagna: [],
    spaghetti: [],
    macaroni: [],
    orzo: [],
    "jumbo shells": [],
    ravioli: []
  },
  legumes: { beans: [], lentils: [], peas: [], chickpeas: [] },
  soy: { soy: ["edamame", "soy"], tofu: [], "soy milk": [] },
  sweeteners: {
    honey: [],
    sugar: [],
    stevia: [],
    agave: [],
    swerve: []
  },
  nuts: {
    peanuts: [],
    "peanut butter": [],
    almonds: [],
    "almond flour": [],
    "almond milk": [],
    "almond butter": [],
    walnuts: [],
    pecans: [],
    pistachios: [],
    cashew: [],
    coconut: [],
    "coconut flour": [],
    "coconut milk": []
  },
  seeds: {
    pumpkin: [],
    sesame: [],
    sunflower: [],
    chia: [],
    flax: []
  },
  // veg
  vegetables: {
    artichoke: [],
    asparagus: [],
    beets: [],
    broccoli: [],
    carrots: [],
    sprouts: [],
    celery: [],
    "bell peppers": [],
    "hot peppers": [],
    tomato: [],
    "sundried tomato": [],
    eggplant: [],
    arugula: [],
    mushrooms: [],
    spinach: [],
    kale: [],
    radishes: ["radish"],
    parsley: [],
    lettuce: [],
    cabbage: [],
    onion: ["shallot", "scallion"],
    garlic: [],
    cauliflower: [],
    leeks: [],
    cucumber: [],
    "brussels sprouts": ["brussels"],
    zucchini: [],
    squash: [],
    pickles: [],
    olives: [],
    "vegetable broth": [],
    turnip: [],
    pumpkin: []
  },
  "starchy vegetables": {
    potatoes: ["potato", "hashbrown"],
    yams: ["sweet potato"],
    corn: []
  },

  "oil and vinegar": {
    "olive oil": [],
    "avocado oil": [],
    "vegetable oil": [],
    "coconut oil": [],
    "MCT oil": [],
    "balsamic vinegar": [],
    "white vinegar": [],
    "rice wine vinegar": [],
    "apple cider vinegar": [],
    "red wine vinegar": []
  },

  "cooking alcohol": {
    "red wine": [],
    "white wine": [],
    "cooking sherry": []
  },

  herbs: {
    thyme: [],
    mint: [],
    coriander: [],
    basil: [],
    cilantro: [],
    oregano: [],
    rosemary: [],
    sage: [],
    dill: [],
    parsley: [],
    ginger: []
  },

  spices: {
    "curry powder": ["curry"],
    cumin: [],
    cinnamon: [],
    /*salt: [],*/
    "chili powder": ["chili"],
    "black pepper": [],
    allspice: [],
    cardamom: [],
    "cayenne pepper": [],
    paprika: [],
    cajun: [],
    turmeric: [],
    "italian seasoning": [],
    "crushed red pepper": [],
    cloves: [],
    capers: [],
    "seafood seasoning": [],
    "chicken bullion": [],
    "chicken seasoning": [],
    "pork seasoning": [],
    nutmeg: []
  },

  condiments: {
    ketchup: [],
    mustard: ["dijon"],
    mayonnaise: [],
    "barbeque sauce": [],
    relish: [],
    "hot sauce": [],
    "ranch dressing": [],
    "worcestershire sauce": [],
    "hoisin sauce": [],
    "caesar salad dressing": [],
    "italian salad dressing": [],
    jam: []
  },

  // fruit
  fruit: {
    apple: [],
    banana: [],
    grapes: [],
    orange: [],
    strawberries: ["strawberr"],
    raspberries: ["raspberr"],
    blueberries: ["blueberr"],
    blackberries: ["blackberr"],
    cherries: ["cherr"],
    lemon: [],
    lime: [],
    cantaloupe: [],
    watermelon: [],
    dates: [],
    grapefruit: [],
    pears: [],
    plums: [],
    peaches: [],
    kiwi: [],
    "goji berries": ["ID:qZu0icuR3sYgQfK1n7hn"],
    cranberries: []
  },
  vegan: {
    cheese: [],
    egg: [],
    tempea: []
  }
};

export default SEARCH;
