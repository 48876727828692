/*
Render a child function with the current user from the userStore.
*/

import { toJS } from "mobx";
import { inject, observer } from "mobx-react";

const CurrentUser = inject("rootStore")(
  observer((props) => {
    const {
      rootStore: { userStore }
    } = props;
    const user = toJS(userStore.user);
    return props.children(user);
  })
);
export default CurrentUser;
