import React, { useState } from "react";

import { Tooltip } from "reactstrap";

const InfoTip = props => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(Math.round(Math.random() * 100000) + "");

  const domId = `tooltip-${id}`;

  return (
    <>
      <i id={domId} className="fa fa-info-circle" />
      <Tooltip
        placement="right"
        isOpen={open}
        target={domId}
        toggle={() => {
          setOpen(!open);
        }}
      >
        {props.children}
      </Tooltip>
    </>
  );
};

export default InfoTip;
