/**
Display an ingredient version properly.

This load the ingredient from firestore.

Displays: 1 UNIT INGREDIENT_NAME

Does its best to make the units nice, and translate units to the best unit.

Props:
- amount - amount to show
- ingredientVersionId - id of the ingredient version
- unitId - the unit id to display


*/
import React from "react";

import { FirestoreDocument } from "common";

const Fraction = require("fraction.js");

const IngredientVersionAmountDisplay = (props) => {
  const { amount, ingredientVersionId, unitId } = props;

  return (
    <FirestoreDocument
      collection="data_ingredient_versions"
      id={ingredientVersionId}
    >
      {({ isLoading, data }) => {
        if (isLoading) {
          return "---";
        }
        const ingredientVersion = data.data;
        return `${transform(
          amount,
          ingredientVersion.grams_per[unitId].name
        )} ${ingredientVersion.name} (original ${amount}, ${
          ingredientVersion.grams_per[unitId].name
        })`;
      }}
    </FirestoreDocument>
  );
};

/*
Return the best, nicest, formatted version of amount + unit name.
*/
const transform = (amountValue, unitName) => {
  let amount = amountValue;
  let unit = unitName.toLowerCase();

  if (unit === "dash" || unit === "pinch") {
    // Round up.
    return `${Math.ceil(amountValue)} ${unitName}`;
  }

  if (unit === "to taste") {
    return "to taste";
  }

  // Get the real unit.
  // cup, chopped
  unit = unit.split(",")[0];
  // Maybe it has a space.
  unit = unit.split(" ")[0];

  // Use units defined by https://www.npmjs.com/package/convert-units
  if (unit === "tablespoon") {
    unit = "Tbs";
  } else if (unit === "tbsp") {
    unit = "Tbs";
  } else if (unit === "teaspoon") {
    unit = "tsp";
  }

  console.log(amount, unit);

  console.log(new Fraction(0.3333).toFraction(true));

  if ((amount + "").indexOf(".") > 0) {
    let f = new Fraction(amount);
    amount = f.toFraction(true);
  }

  if (unit === "tsp") {
    unit = "teaspoon";
  } else if (unit === "Tbs") {
    unit = "tablespoon";
  }

  return `${amount} ${unit}`;
};

export default IngredientVersionAmountDisplay;
