// Snack constants mapped to nice names.
// These constants are used in manage/mealplans/create meal plan/ OptionsCard
export const SNACK_OPTIONS = {
  default: "Best nutrition, up to two snacks",
  NO_SNACK: "No snack",
  ONE_SNACK: "Prefer one snack",
  ONE_SNACK_FIXED: "Always one snack",
  TWO_SNACK: "Prefer two snacks",
  TWO_SNACK_FIXED: "Always two snacks",
  THREE_SNACK: "Up to three snacks",
  THREE_SNACK_FIXED: "Always three snacks"
};

// Valid grocery aisle choices.
// Fake IDs so we can change the names later.
// THIS NEEDS TO MATCH SERVER SIDE AS WELL.
export const GROCERY_AISLES = {
  kd892hj3rf: "Produce",
  cmei38cjek: "Herbs",
  j3kf823: "Bread",
  eicmfkj39s: "Milk, Eggs, Other Dairy",
  n03idjf23: "Cheese",
  smdoirl3krj: "Spices and Seasonings",
  xncv3rklj: "Meat",
  a93kfjd99: "Oil, Vinegar, Salad Dressing",
  bmei3slk: "Dried Fruit, Nuts",
  i3kcnvlsj: "Condiments",
  bhek3l2kd: "Baking",
  alsj39832: "Deli",
  vne92lkvd: "Nut butters, Jams, Honey",
  uj4erif32j: "Seafood",
  ni38234: "Beverages and Juices",
  xdu382nfd: "Frozen Foods",
  ei38jdjfs: "Canned Goods",
  jsdfa28dda: "Pasta and Rice",
  o10iejkdf2: "Ethnic Foods",
  k383jfnsd: "Health Foods",
  q39234md: "Alcohol"
};

export const COLORS = {
  calories: "#000",
  carbohydrates: "#EC6B56",
  protein: "#47B39C",
  fat: "#FFC154",
  fiber: "#CCC"
};

// For these unit ID's, always round up.
// This should match what's on the server/generator constants.
export const ROUND_AMOUNT_FOR_UNITS = [
  "ukMddGharyM09ktkGY9E", // Egg > "egg"
  "kKwuXjMPC0uz", // Egg > Hard Boiled Egg
  "d8X7PsgDyAOyIpGzYB4y", // Egg White > "egg white (separated from yolk)"
  "mukyvVuWcIaMym2xQ0Sg", // Egg Yolk > 1 large yolk
  "rjBdwzFwncAG", // Wooden skewer > "Wooden skewer"
  "fY1hz2jsfkdi" // Toothpick > Toothpick
];

// If the name matches these, roudn up.
// This should match what's on the server/generator constants.
export const ROUND_AMOUNT_FOR_UNIT_NAMES = [
  "to taste",
  "dash",
  "as needed",
  "pinch"
];

// Nutrients: unit
export const NUTRIENTS = {
  alcohol: "g",
  caffeine: "mg",
  calcium: "mg",
  calories: "cal",
  carbohydrates: "g",
  cholesterol: "mg",
  copper: "mg",
  fat: "g",
  fiber: "g",
  folate: "µg",
  iron: "mg",
  magnesium: "mg",
  manganese: "mg",
  phosphorus: "mg",
  potassium: "mg",
  protein: "g",
  "saturated fat": "g",
  selenium: "µg",
  sodium: "mg",
  sugar: "g",
  "vitamin a": "IU",
  "vitamin b1": "mg",
  "vitamin b12": "µg",
  "vitamin b2": "mg",
  "vitamin b3": "mg",
  "vitamin b5": "mg",
  "vitamin b6": "mg",
  "vitamin c": "mg",
  "vitamin d": "µg",
  "vitamin e": "mg",
  "vitamin k": "µg",
  zinc: "mg"
};

export const OPTIONAL_INGREDIENTS = [
  "fUlb3I2XItaMEiglcMB0", // black pepper
  "B1pMNauCuALFNwRoXKlH", // salt
  "5Zvu41gjjvku3FwaTGsA", // allspice
  "iAKk4bMynh7LQDFH1mjL", // cardamom
  "ZDtUwMd8RDw0BQL5U8Bz", // cinnamon
  "hPLdxXlm44pmkuU3TvEq", // paprika
  "Kd4biwQrbkUYkUdbmUb0", // cayenne
  "Iv5dPNgUgJ8qs604mo6i", // cumin
  "8siJcRTRRXOa8TbVdje8", // turmeric
  "c9TLCy5xxXeBh6Ug6vND", // cajun
  "1gqEVTfMEP7ZOzlp62D2", // chili powder
  "lR48H1mzNfKXxoiurZGi", // curry powder
  "0i6vaaowwR68BW3x8ljc", // basic
  "IWQdk0qCNHDVvjMP8JQs", // coriander leaf
  "G6Vw12QDw7YWYvGI3dGD", // coriander seed
  "r6qHpyuvZKNdSKVPjpbi", // oregano
  "dLmjVPnbJHIpHKzkSMQL", // sage
  "akh9JbI87JRrRA0xfy7R", // cilantro
  "dorifAjcu9s72oHzouiJ", // mint
  "20eMqszH1XJg1C7A1TPn", // rosemary
  "bABWNtPZHqbivJpy79Yc", // thyme
  "eGFo77ylXFWTs9zxRjI3", // dill
  "8BnMM3OGqrO3s2lT8N2Z" // parsley
];

// Brand config.
export const BRANDS = {
  KCP: {
    brand_name: "KetoCustomPlan.com",
    support_email: "support@ketocustomplan.com",
    logo: "",
    // used with zendesk to give quick links to main pages.
    /*links: {
      order: "https://www.ketocustomplan.com/purchase/meal-plan/order/main",
      sale: "https://www.ketocustomplan.com/results/sale-main",
      learn: "https://www.ketocustomplan.com/articles/about-our-meal-plans"
    },*/
    custom_order_link:
      "https://www.ketocustomplan.com/purchase/meal-plan/order/order-custom",
    products: {
      "plan-1": {
        name: "1 Month Keto Plan"
      },
      "plan-2": {
        name: "2 Month Keto Plan"
      },
      "plan-6": {
        name: "6 Month Keto Plan"
      },
      workout: {
        name: "Workout"
      },
      ebook: {
        name: "Beginners Guide eBook"
      },
      yoga: {
        name: "Yoga Program"
      },
      meditation: {
        name: "Meditation + Mindset Program"
      }
    },
    /*
    injectMessage: string | function
    function(details){
      return string_to_inject
    }
    */
    zendesk_resources: [
      {
        injectMessage:
          "<a href='https://www.ketocustomplan.com/_resources/Guide_to_Keto_Substitutions.pdf'>Guide to Substitutions</a>",
        title: "Substitutions Guide"
      },
      {
        injectMessage:
          "<a href='https://www.ketocustomplan.com/_resources/Staying_Keto_While_Eating_Out.pdf'>Staying Keto While Eating Out</a>",
        title: "Keto While Eating Out Guide"
      },
      {
        injectMessage:
          "<a href='https://www.ketocustomplan.com/_resources/Keto_Diet_What_to_Drink.pdf'>Keto: What to Drink</a>",
        title: "Drinks Guide"
      },
      {
        injectMessage:
          "<a href='https://www.ketocustomplan.com/_resources/Keto_Treats_Guide.pdf'>Keto Treats Guide</a>",
        title: "Treats Guide"
      },
      {
        injectMessage:
          "<a href='https://www.ketocustomplan.com/_resources/KetoFoodList.pdf'>Keto Food List</a>",
        title: "Food List"
      },
      {
        injectMessage:
          "<a href='https://www.ketocustomplan.com/articles/about-our-meal-plans'>About Our Meal Plans</a>",
        title: "About Our Meal Plans"
      },
      { spacer: true },
      {
        injectMessage:
          "<a href='https://www.ketocustomplan.com/purchase/meal-plan/order/main'>Order Your Keto Plan Here</a>",
        title: "Order page"
      },
      {
        injectMessage:
          "<a href='https://www.ketocustomplan.com/purchase/meal-plan/order/order-sale'>Order Your Keto Plan Here - On Sale Now</a>",
        title: "Order (discount) page"
      }
    ],
    default_mealplan_meals: [
      {
        id: "a",
        display_name: "Breakfast",
        side_settings: "OPTIONAL",
        meal_names: ["breakfast"],
        calorie_range: [5, 25]
      },
      {
        id: "b",
        display_name: "Lunch",
        side_settings: "OPTIONAL",
        meal_names: ["lunch"],
        calorie_range: [20, 40]
      },
      {
        id: "c",
        display_name: "Dinner",
        side_settings: "OPTIONAL",
        meal_names: ["dinner"],
        calorie_range: [30, 60]
      },
      {
        id: "d",
        display_name: "Snack",
        side_settings: "NONE",
        meal_names: ["snack"],
        calorie_range: [1, 8]
      }
    ]
  },
  // KA isn't an enabled brand, but this is used to map chargedesk charges
  KA: {
    brand_name: "KetoAssociation.com",
    support_email: "support@ketoassociation.com",
    products: null
  },
  EF: {
    brand_name: "EggFast.com",
    support_email: "hello@eggfast.com",
    logo: "",
    products: null
  },
  "10M": {
    brand_name: "10Minutes.com",
    support_email: "hello@10minutes.com",
    logo: "",
    products: null
  },
  WLD: {
    brand_name: "WeightLossDiet.com",
    support_email: "hello@weightlossdiet.com",
    logo: "",
    /*
    injectMessage: string | function
    function(details){
      return string_to_inject
    }
    */
    zendesk_resources: [
      {
        injectMessage:
          "<a href='https://weightlossdiet.com/learn/abour-our-plans'>Learn more about our plans here</a>",
        title: "About Our Plans"
      },
      {
        injectMessage:
          "<a href='https://weightlossdiet.com/_assets/WeightLossDiet_Guide_to_Substitutions.pdf'>Guide to Substitutions</a>",
        title: "Substitutions Guide"
      },
      { spacer: true },
      {
        injectMessage:
          "<a href='https://weightlossdiet.com/order/plan/main'>Order Your Weight Loss Plan Here</a>",
        title: "Order page"
      }
    ],
    custom_order_link: "https://weightlossdiet.com/order/custom",
    products: {
      "plan-1": {
        name: "1 Month Personalized Plan"
      },
      "plan-2": {
        name: "2 Month Personalized Plan"
      },
      "plan-6": {
        name: "6 Month Personalized Plan"
      },
      "workout-1": {
        name: "Personalized Workout"
      }
    },
    default_mealplan_meals: [
      {
        id: "a",
        display_name: "Breakfast",
        side_settings: "OPTIONAL",
        meal_names: ["breakfast"],
        calorie_range: [5, 25]
      },
      {
        id: "b",
        display_name: "Lunch",
        side_settings: "OPTIONAL",
        meal_names: ["lunch"],
        calorie_range: [20, 40]
      },
      {
        id: "c",
        display_name: "Dinner",
        side_settings: "OPTIONAL",
        meal_names: ["dinner"],
        calorie_range: [30, 60]
      },
      {
        id: "d",
        display_name: "Snack",
        side_settings: "NONE",
        meal_names: ["snack"],
        calorie_range: [1, 8]
      }
    ]
  },
  HL: {
    brand_name: "HealthyLeap.com",
    support_email: "hello@healthyleap.com",
    logo: "",
    /*
    injectMessage: string | function
    function(details){
      return string_to_inject
    }
    */
    zendesk_resources: [],
    custom_order_link: "",
    products: {}
  },
  ML: {
    brand_name: "Mealific.com",
    support_email: "hello@mealific.com",
    logo: "",
    default_mealplan_meals: [
      {
        id: "a",
        display_name: "Breakfast",
        side_settings: "OPTIONAL",
        meal_names: ["breakfast"],
        calorie_range: [5, 25]
      },
      {
        id: "b",
        display_name: "Lunch",
        side_settings: "OPTIONAL",
        meal_names: ["lunch"],
        calorie_range: [20, 40]
      },
      {
        id: "c",
        display_name: "Dinner",
        side_settings: "OPTIONAL",
        meal_names: ["dinner"],
        calorie_range: [30, 60]
      },
      {
        id: "d",
        display_name: "Snack",
        side_settings: "NONE",
        meal_names: ["snack"],
        calorie_range: [1, 8]
      }
    ]
  }
};

// Map cloudflare ID -> metadata.
export const YOGA_VIDEOS = {
  "9fdeaf970f51ba1fec8cfd6143228c8c": {
    name: "Warm up + Flow",
    index: 0,
    description:
      "A flowing and warming sequence of movements. Opens stuck joins and increases energy, which prepares your whole system.",
    thumbnail: "warm_up.png"
  },
  ee45c36fa76bbe54c57d0216b3032519: {
    name: "Gentle Chair Yoga",
    index: 1,
    thumbnail: "chair_yoga.png",
    description:
      "A gentle program performed on a chair. Best for people with mobility issues, or just getting started with a fitness program."
  },
  "1621a6f13176c4dbda79089b54c22d5e": {
    name: "Stability + Spine",
    index: 2,
    thumbnail: "stability_spine.png",
    description:
      "A sequence to engage, strengthen, and stretch the spinal muscles."
  },
  ccce99e180b00d7f32a799a3e5979132: {
    name: "Stamina + Energy",
    index: 3,
    thumbnail: "stamina_energy.png",
    description:
      "Uplift your energy and help build endurance so you can accomplish more daily."
  },
  "67f29d58d7cae8a5c04afd14f7f4f025": {
    name: "Warrior Nature + Endurance",
    index: 4,
    thumbnail: "warrior_nature.png",
    description:
      "Amplify your inner warrior with this gentle challenge. Be inspired to become grounded, centered, stable and focused."
  },
  d6a7beb185f1ed465d0e07beeafa5613: {
    name: "Balance + Breathe",
    index: 5,
    thumbnail: "balance_breathe.png",
    description: "Heal and cleanse by improving oxygen flow and mindfullness."
  },
  "350fd895ef444d760d1252a7e7ad4db3": {
    name: "Detox + Cleanse",
    index: 6,
    thumbnail: "detox_cleanse.png",
    description:
      "Inspiring movement that can help you cleanse and detox with flowing movements to bring balance to your soul."
  },
  "77ffbaf441ce7e477205997a883d669d": {
    name: "Strong Core + Open Heart",
    index: 7,
    thumbnail: "core_heart.png",
    description:
      "Improve posture by strengthening the back and shoulders, while opening the chest, by focusing on a stronger core."
  },
  "56b6130b1d5cdb9b14832067f6da1aa4": {
    name: "Flexibility + Restore",
    index: 8,
    thumbnail: "flexibility_restore.png",
    description:
      "A gentle way to cool down and stretch properly. Avoid stretching incorrectly, which can cause inflammation and pain. Stretch the right way by opening your body and enjoying the freedom it brings."
  },
  "891e7b32e559308c78535a8ff155fa7d": {
    name: "Core Strength + Empower",
    index: 9,
    thumbnail: "core_strength.png",
    description:
      "Your core and back muscles are important to staying strong, standing tall, and avoiding fatigue. Get inspired to build strong and flexible abdominal muscles and full core support."
  }
};

export const MEDITATE_AUDIO = {
  "100": {
    name: "Anxiety relief"
  },
  "101": {
    name: "Make peace with anxiety"
  },
  "102": {
    name: "Stress relief"
  },
  "200": {
    name: "Basics of mindfullness"
  },
  "202": {
    name: "Beginner meditation"
  },
  "203": {
    name: "Evening meditation"
  },
  "204": {
    name: "Morning meditation"
  },
  "300": {
    name: "Calm workplace stress"
  },
  "301": {
    name: "Calming impulses"
  },
  "302": {
    name: "Focus meditation"
  },
  "303": {
    name: "Take a break from your mind"
  },
  "400": {
    name: "Conflict resolution"
  },
  "401": {
    name: "Improve productivity"
  },
  "402": {
    name: "Intention setting"
  },
  "403": {
    name: "Communicate with kindness"
  },
  "404": {
    name: "Let go of perfectionism"
  },
  "405": {
    name: "Manage your organization"
  },
  "406": {
    name: "Take time for your interests"
  },
  "407": {
    name: "Time abundance"
  },
  "500": {
    name: "Gratitude meditation"
  },
  "501": {
    name: "Listen to your body"
  },
  "502": {
    name: "Mind body connection"
  },
  "503": {
    name: "Self esteem"
  },
  "504": {
    name: "Self gratitude"
  },
  "505": {
    name: "Self love"
  },
  "506": {
    name: "Staying in the present moment"
  },
  "507": {
    name: "Take a step back"
  }
};
