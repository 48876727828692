/**
- An input that returns the exact value to onChange, and as a number if necessary.
- Accepts value as an array, where the first defined entry is used.
- All other Input props are passed
*/
import React from "react";
import { Input } from "reactstrap";

/**
If value is array, see if the first entry is a valid type. If not, use the second item.
Not array, regular value.
*/
const determineValue = value => {
  if (!Array.isArray(value)) {
    if (value !== null && typeof value !== "undefined") {
      return value;
    }
    return "";
  }
  const [a, b] = value;
  if (a !== null && typeof a !== "undefined") {
    return a;
  }
  if (b !== null && typeof b !== "undefined") {
    return b;
  }
  // Empty string. This prevents a React warning about changing controled/uncontrolled inputs.
  return "";
};

const BetterInput = props => {
  return (
    <Input
      {...props}
      value={determineValue(props.value)}
      onChange={e => {
        const { value } = e.target;
        if (props.type === "number" && value !== "") {
          props.onChange(Number(value));
          return;
        }
        props.onChange(value);
      }}
    />
  );
};
export default BetterInput;
