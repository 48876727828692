import React from "react";

const LoadingIcon = (props) => {
  const classNames = ["ml-2 fa fa-refresh pointer"];

  if (props.loading) {
    classNames.push("icon-spin");
  }

  return (
    <i
      className={[classNames.join(" ")]}
      onClick={(e) => {
        props.onClick();
      }}
    />
  );
};
export default LoadingIcon;
