import React, { useState } from "react";

import { Alert } from "reactstrap";
import { Loading } from "common";

import User from "api/User";
import Audit from "api/Audit";

const RefundSubmitted = (props) => {
  const { user, partner, zendeskClient } = props;

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);

  if (success === true) {
    return (
      <Alert color="success" className="p-1 m-0">
        <small>Success!</small>
      </Alert>
    );
  }

  return (
    <>
      {success === false ? (
        <Alert color="danger" className="p-1 m-0">
          <small>Error setting refund</small>
        </Alert>
      ) : null}
      <small>
        <a
          href="#"
          onClick={async (e) => {
            e.preventDefault();
            if (loading) {
              return;
            }
            setLoading(true);

            const client = new User(user.id);
            try {
              await client.update({
                meal_plan_credits: 0,
                refunded: true,
                workout_enabled: false,
                meditate_enabled: false,
                yoga_enabled: false
              });

              new Audit("client", user.id).event("Refund complete");

              zendeskClient.set("ticket.status", "solved");
              const message = (await zendeskClient.get("comment.text"))[
                "comment.text"
              ];
              // Only send the macro if the message is empty.
              if (message === "" || message === "<p></p>") {
                const macroId = 360068600272;
                zendeskClient.invoke("macro", macroId);
              }

              setSuccess(true);
            } catch (e) {
              setSuccess(false);
            }

            setLoading(false);
          }}
        >
          Remove Products
        </a>
      </small>
      {loading ? (
        <Loading size={12} style={{ display: "inline-block", marginLeft: 5 }} />
      ) : null}
    </>
  );
};

export default RefundSubmitted;
