/**
Zendesk app. Only works when iframed inside zendesk. See: the zendesk development app.
*/
import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { Switch, Route, withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { CurrentUserPartner } from "common";

import Measure from "react-measure";

import Backend from "util/Backend";

import User from "./User";
import Home from "./Home";
import Search from "./Search";
import Escalate from "./Escalate";

import Resources from "./Resources";

import queryString from "qs";

import TicketMerger from "./TicketMerger";

// Call the page with ?user_id=USER_ID to open the user_id directly.
const ZendeskApp = (props) => {
  // Because this is a standalone app, need to make sure the app has loaded.
  const { rootStore } = props;

  if (rootStore.appReady === false) {
    return null;
  }

  if (!rootStore.authSuccess) {
    return <UserNeedsToLogin />;
  }

  const queryParams = queryString.parse(props.location.search, {
    ignoreQueryPrefix: true
  });

  return (
    <ZendeskClient queryParams={queryParams}>
      {(zendesk) => {
        // zendesk = {requester,zendeskClient}
        return (
          <>
            <TicketMerger zendeskClient={zendesk.zendeskClient} />
            <CurrentUserPartner>
              {(partner) => {
                return (
                  <>
                    <Switch>
                      <Route
                        path="/zendesk/user/:userId"
                        exact={true}
                        render={(props) => (
                          <User {...zendesk} partner={partner} {...props} />
                        )}
                      />
                      <Route
                        path="/zendesk/search"
                        exact={true}
                        render={(props) => (
                          <Search {...zendesk} partner={partner} {...props} />
                        )}
                      />
                      <Route
                        path="/zendesk/escalate"
                        render={(props) => (
                          <Escalate {...zendesk} partner={partner} {...props} />
                        )}
                      />
                      <Route
                        path="/zendesk/resources"
                        render={(props) => (
                          <Resources
                            {...zendesk}
                            partner={partner}
                            {...props}
                          />
                        )}
                      />
                      <Route
                        path="/zendesk"
                        render={(props) => (
                          <Home {...zendesk} partner={partner} {...props} />
                        )}
                      />
                    </Switch>
                  </>
                );
              }}
            </CurrentUserPartner>
          </>
        );
      }}
    </ZendeskClient>
  );
};

const UserNeedsToLogin = (props) => {
  return (
    <>
      <p>You need to login</p>
      <Button href={`${window.location.origin}/login`}>Login Here</Button>
    </>
  );
};

const ZendeskClient = withRouter((props) => {
  const { queryParams } = props;

  // zendesk user id.
  const [requester, setRequester] = useState(null);

  // ticket brand tag.
  const [brandTag, setBrandTag] = useState(null);

  // zendesk client object.
  const [client, setClient] = useState(null);

  useEffect(() => {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://static.zdassets.com/zendesk_app_framework_sdk/2.0/zaf_sdk.min.js";

    script.addEventListener("load", () => {
      const zendeskClient = window.ZAFClient.init();
      setClient(zendeskClient);

      const loadAndSetRequester = () => {
        zendeskClient.get("ticket").then((data) => {
          console.log(data);
          if (!data) {
            return;
          }

          // Find the tag prefixed with "brand_"
          for (const tag of data.ticket.tags) {
            if (tag.startsWith("brand_")) {
              setBrandTag(tag);
            }
          }

          const requester = data.ticket.requester;
          //if (requester.email && requester.name) {
          setRequester(requester);
          //}
        });
      };

      loadAndSetRequester();

      //if (queryParams.newTicket) {
      // Its loaded on a new ticket. Need to listen to email address changes so it reloads.
      zendeskClient.on("ticket.requester.email.changed", function (e) {
        loadAndSetRequester();
        props.history.push("/zendesk");
      });

      // When the tickets saved, see if there is a basecamp todo ID set and a public comment
      // If there is, need to notify the server so a comment is created in BC.
      zendeskClient.on("ticket.save", async () => {
        const result = await zendeskClient.get(
          `ticket.customField:custom_field_360032139692`
        );

        const todoID = result["ticket.customField:custom_field_360032139692"];
        if (todoID) {
          const ticketComment = await zendeskClient.get("ticket.comment");
          const comment = ticketComment["ticket.comment"];
          if (
            comment.type !== "internalNote" &&
            comment.text &&
            comment.text !== "" &&
            comment.text !== "<p></p>"
          ) {
            try {
              await Backend.post("basecamp/comment_todo", {
                project_id: 15644965,
                recording_id: todoID,
                content: `Zendesk public reply: <blockquote>${comment.text}</blockquote>`
              });

              return true;
            } catch (e) {
              throw new Error(`Error: ${result.message}`);
            }
          }
        }
        return true;
      });

      // Fuck this, instead we just need to make sure that when creating a ticket via API it sets the recipient

      // Verify that the original recipient matches the current brand.
      // Otherwise, set the outgoing email address to whatever the brand is.
      // This is done for tickets created via the API that would normally just get sent with the default address.
      /*
      zendeskClient.on("ticket.save", async () => {
        const result = await zendeskClient.get(`ticket`);

        if (!result || !result.ticket || !result.ticket.id) {
          return true;
        }

        // Return the brand code given an array of tags.
        // [brand_ef, mp2, paid_customer] => EF
        const getBrandFromTags = tags => {
          for (const tag of tags) {
            if (tag.startsWith("brand_")) {
              return tag
                .split("_")
                .pop()
                .toUpperCase();
            }
          }
          return null;
        };

        const brandCode = getBrandFromTags(result.ticket.tags);
        if (!brandCode) {
          // Don't know the brand.
          return true;
        }

        const zendeskResult = await zendeskClient.request({
          url: `/api/v2/tickets/${result.ticket.id}.json`,
          type: "GET",
          dataType: "json"
        });

        if (zendeskResult.ticket.recipient) {
          // Recipient is set. Good to go.
          return true;
        }

        console.log(
          "Set the ticket recipient to",
          BRANDS[brandCode].support_email
        );
        await zendeskClient.request({
          url: `/api/v2/tickets/${result.ticket.id}.json`,
          type: "PUT",
          contentType: "application/json",
          data: JSON.stringify({
            ticket: {
              recipient: BRANDS[brandCode].support_email
            }
          })
        });
      });
      */
    });

    document.getElementsByTagName("head")[0].appendChild(script);
  }, []);

  if (!requester || !requester.id) {
    if (queryParams.newTicket) {
      return <center>Set the ticket email address to load the client.</center>;
    }
    return null;
  }

  return (
    <Measure
      bounds
      onResize={(contentRect) => {
        let height = contentRect.bounds.height + 50;
        if (height > 375) {
          height = 375;
        }
        client.invoke("resize", {
          width: "100%",
          height: height + "px"
        });
      }}
    >
      {({ measureRef }) => (
        <div ref={measureRef}>
          {props.children({
            requester,
            brandId: brandTag && brandTag.split("_").pop().toUpperCase(),
            zendeskClient: client
          })}
        </div>
      )}
    </Measure>
  );
});

export default inject("rootStore")(observer(ZendeskApp));
