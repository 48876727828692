/*
Refine results, but don't show anything to the user.

Normally, the user would be able to select the things they refine.

We don't want to do this here.

***
Actually using this wrong, can just use the Configure component from react-instantsearch-dom instead.  ****

*/
import { connectRefinementList } from "react-instantsearch-dom";

const Refinement = connectRefinementList(props => {
  return null;
});

export default Refinement;
