import React from "react";

import { CurrentUserPartner, FirestoreCollection } from "common";

const PartnerUserList = (props) => {
  return (
    <CurrentUserPartner>
      {(partner) => {
        return (
          <FirestoreCollection
            collection="partner_users"
            filter={[["partner_id", "==", partner.id]]}
          >
            {props.children}
          </FirestoreCollection>
        );
      }}
    </CurrentUserPartner>
  );
};

export default PartnerUserList;
