import React from "react";
import { Button } from "reactstrap";
const SendAccountAccess = props => {
  const { user, partner, zendeskClient } = props;

  return (
    <small>
      <Button
        color="primary"
        size="sm"
        href="#"
        block
        onClick={async e => {
          e.preventDefault();
          //zendeskClient.set("ticket.status", "solved");

          // Dont use passwords for accounts anymore.
          let passwordMessage = null;
          // Only use passwords if its been changed.
          if (user.password_has_changed) {
            passwordMessage = `&lt;your password&gt;<br/><br/>
If you forget your password, you can <a href="https://${domainName}/login?email=${user.email}&activeForm=reset">reset it here.</a>`;
          }

          let domainName = "myplan.ketocustomplan.com";

          if (user.brand_id) {
            domainName = partner.brands[user.brand_id].domain_name;
          }

          let blacklistedDomains = [
            "talktalk.net",
            "windstream.net",
            "optonline.net",
            "wwdb.org",
            "newwavecomm.net",
            "cogeco.ca",
            "iowatelecom.net",
            "embarqmail.com",
            "@q.com",
            "centurylink.net",
            "wavecable.com",
            "tiscali.co.uk",
            "zoominternet.net",
            "fuse.net",
            "cavtel.net",
            "cableone.net",
            "@tds.net",
            "optimum.net",
            "centurytel.net",
            "mts.net",
            "zoomtown.com"
          ];

          const isEmailDomainBlacklisted = email => {
            for (const e of blacklistedDomains) {
              if (email.endsWith(e)) {
                return true;
              }
            }
            return false;
          };

          const supportsHTML =
            (await zendeskClient.get("ticket.via"))["ticket.via"].channel !==
            "fb_private_message";

          /*
          if (isEmailDomainBlacklisted(user.email)) {
            domainName = "accessketoplan.com";
          }
          */

          const message = (await zendeskClient.get("comment.text"))[
            "comment.text"
          ];
          // Detect if publicReply is available, otherwise this crashes.
          // zendeskClient.set("comment.type", "publicReply");
          if (passwordMessage) {
            let injectMessage = `Here's how to access your account:<br/><br/>
            Login page: <a href="https://${domainName}/login?email=${user.email}">https://${domainName}/login</a><br/>
            Email: ${user.email}
            <br/>
            Password: ${passwordMessage}`;

            if (!supportsHTML) {
              injectMessage = `Here's how to access your account:<br/><br/>
              Login: https://${domainName}/start<br/>
              Email: ${user.email}
              <br/>
              Password: ${passwordMessage}`;
            }

            zendeskClient.set(
              "comment.text",
              `${message}

${injectMessage}

`
            );
          } else {
            let injectMessage = `Access your account here: <a href="https://${domainName}/start?email=${user.email}">https://${domainName}/start</a>`;

            if (!supportsHTML) {
              injectMessage = `Access your account here: https://${domainName}/start`;
            }

            zendeskClient.set(
              "comment.text",
              `${message}

${injectMessage}

`
            );
          }
        }}
      >
        Account Access
      </Button>
    </small>
  );
};

export default SendAccountAccess;
