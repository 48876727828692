/**

Create a loading button

*/
import React, { useState } from "react";
import { Button } from "reactstrap";
import Measure from "react-measure";
import ReactLoading from "react-loading";

import PropTypes from "prop-types";

import { KeyDetector } from "common";

const LoadingButton = (props) => {
  const [loading, setLoading] = useState(false);

  const [dimensions, setDimensions] = useState(null);

  let style = {};

  let addProps = {
    ...props
  };

  delete addProps.loading;
  delete addProps.loadOnEnter;

  const isDisabled = loading || props.loading || props.disabled;

  const onClick = async () => {
    if (isDisabled) {
      return;
    }

    if (props.loading !== false && props.loading !== true) {
      setLoading(true);
    }
    const result = props.onClick();

    // onClick response handling.
    if (result === false || (await result) === false) {
      setLoading(false);
    }
  };

  if (dimensions) {
    style = {
      width: Math.ceil(dimensions.width) + 1,
      height: Math.ceil(dimensions.height) + 1
    };

    return (
      <>
        <Button
          {...addProps}
          onClick={onClick}
          disabled={isDisabled}
          style={style}
        >
          {!loading && !props.loading ? (
            props.children
          ) : (
            <center>
              <ReactLoading type={"spinningBubbles"} height={25} width={25} />
            </center>
          )}
        </Button>
        <KeyDetector.Component
          isDisabled={!props.loadOnEnter || isDisabled}
          useKey={KeyDetector.ENTER}
          onPress={onClick}
        />
      </>
    );
  }

  if (!dimensions) {
    return (
      <Measure
        bounds
        onResize={(contentRect) => {
          if (!dimensions) {
            setDimensions(contentRect.bounds);
          }
        }}
      >
        {({ measureRef }) => (
          <div ref={measureRef} style={{ display: "inline-block" }}>
            <Button {...addProps}>{props.children}</Button>
          </div>
        )}
      </Measure>
    );
  }
};

LoadingButton.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  loadOnEnter: PropTypes.bool
};

export default LoadingButton;
