import { observable, decorate } from "mobx";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import PartnerUser from "api/PartnerUser";
import User from "api/User";

class UserStore {
  //authLoading = true;

  needsReauthentication = false;
  hasReauthenticatedSuccess = false;
  hasReauthenticatedError = false;

  uid = null;
  idToken = null;

  //searchKey = null;

  user = new Map();

  async init(firebaseAuthUser) {
    if (firebaseAuthUser) {
      // user is signed in!
      const { uid } = firebaseAuthUser;
      this.uid = uid;
      window.__uid = this.uid;
      //this.idToken = await firebase.auth().currentUser.getIdToken(true);
      //window.__idToken = this.idToken;
      await this.load();
    } else {
      this.uid = null;
    }
  }

  async load() {
    if (this.listenerAttached) {
      return;
    }

    // See if it's a partner user.
    const partnerUser = new PartnerUser(this.uid);
    try {
      const partnerUserDocSnap = await partnerUser.get();
      if (partnerUserDocSnap.exists) {
        this.user.set("uid", this.uid);
        //this.user.set("user_id", this.uid);
        this.user.set("partner_id", partnerUserDocSnap.data().partner_id);
        this.user.set("account_type", "partner");
        this.user.set("is_admin", partnerUserDocSnap.data().admin);
        this.user.set("privileges", partnerUserDocSnap.data().privileges);
        window.user_id = this.uid;
        window.partner_id = this.user.get("partner_id");
        window.is_partner = true;
        return;
      }
    } catch (e) {
      // Fails with missing permissions if non partner.
    }

    const brandDetails = User.getPartnerBrandDetailsFromDomain();
    let userId = `${this.uid}-${brandDetails.partner_id}-${brandDetails.brand_id}`;
    //console.log("User ID", userId);
    let userObject = new User(userId);

    let userDocumentSnapshot = await userObject.get();

    if (!userDocumentSnapshot) {
      throw new Error("Not Found");
    }

    const onSnapshot = (docSnapshot) => {
      //this.user = new Map(Object.entries(docSnapshot.data()));

      for (const key of Object.keys(docSnapshot.data())) {
        this.user.set(key, docSnapshot.data()[key]);
      }

      // Set empty object if not set.
      if (!this.user.get("enabled_products")) {
        this.user.set("enabled_products", {});
      }

      this.user.set("uid", this.uid);

      let userId = `${this.uid}-${this.user.get("partner_id")}-${this.user.get(
        "brand_id"
      )}`;
      // New method.
      this.user.set("user_id", userId);
      window.user_id = userId;
      window.brand_id = this.user.get("brand_id");
      window.partner_id = this.user.get("partner_id");
    };

    onSnapshot(userDocumentSnapshot);
    // Attach the listener.
    userObject.getDocumentReference().onSnapshot((snap) => {
      onSnapshot(snap);
    });
    this.listenerAttached = true;
  }

  logout() {
    return firebase.auth().signOut();
  }

  /**
  Request the user reauthenticates
  Shows the ReauthenticationModal that's in containers/DefaultLayout

  Resolves when reauthentication succeeds. Rejects if reauthentication fails.

  */
  async requestReauthentication() {
    this.needsReauthentication = true;
    return new Promise((resolve, reject) => {
      var interval = setInterval(() => {
        if (this.hasReauthenticatedSuccess) {
          clearInterval(interval);
          this.needsReauthentication = false;
          this.hasReauthenticatedSuccess = false;
          resolve();
        }
        if (this.hasReauthenticatedError) {
          this.needsReauthentication = false;
          this.hasReauthenticatedError = false;
          reject();
        }
      }, 500);
    });
  }

  async reauthenticate(password) {
    var user = firebase.auth().currentUser;
    var credentials = firebase.auth.EmailAuthProvider.credential(
      this.user.get("email"),
      password
    );
    try {
      await user.reauthenticateWithCredential(credentials);

      // Update the token.
      //this.idToken = await firebase.auth().currentUser.getIdToken(true);
      //window.__idToken = this.idToken;

      this.hasReauthenticatedSuccess = true;
    } catch (e) {
      this.hasReauthenticatedError = true;
    }
  }
}

decorate(UserStore, {
  user: observable,
  needsReauthentication: observable
});
export default UserStore;
