/*
Always store things in KG and CM.

We only deal with inches, lbs, kg, and cm.

*/
class Measurement {
  static toKg(pounds, round = false) {
    let a = pounds * 0.45359237;
    if (round) {
      return Math.round(a * 100) / 100;
    }
    return a;
  }
  static fromKg(kilograms, round = false) {
    let a = kilograms / 0.45359237;
    if (round) {
      return Math.round(a * 100) / 100;
    }
    return a;
  }
  static toCm(inches, round = false) {
    let a = inches * 2.54;
    if (round) {
      return Math.round(a * 100) / 100;
    }
    return a;
  }
  static fromCm(centimeters, round = false) {
    let a = centimeters * 0.393701;
    if (round) {
      return Math.round(a * 100) / 100;
    }
    return a;
  }
}

export default Measurement;
