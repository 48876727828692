import React from "react";

import { FirestoreDocument } from "common";

import MealPlanDay from "api/MealPlanDay";

const MealPlanDayDocument = (props) => {
  const { id } = props;

  return (
    <FirestoreDocument collection="meal_plan_days" id={id}>
      {({ isLoading, data }) => {
        if (isLoading) {
          return props.children({ isLoading, data });
        }

        if (!data) {
          return props.children({ isLoading, data });
        }

        /*
        let meals = [].concat(data.meals);

        if (Array.isArray(data.meals) === false) {
          // old format. new format meals is an array.
          meals = [
            {
              display_name: "Breakfast",
              ...data.meals.breakfast
            },
            {
              display_name: "Lunch",
              ...data.meals.lunch
            },
            {
              display_name: "Dinner",
              ...data.meals.dinner
            }
          ];

          if (data.meals.snack) {
            meals.push({
              display_name: "Snack",
              ...data.meals.snack
            });
          }
        }
        */
        data.meals = MealPlanDay.getCorrectedMeals(data.meals);

        return props.children({ isLoading, data });
      }}
    </FirestoreDocument>
  );
};

export default MealPlanDayDocument;
