import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Alert
} from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import { inject, observer } from "mobx-react";

import { LoadingButton } from "common";

import User from "api/User";
import Audit from "api/Audit";
import Event from "api/Event";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import queryString from "qs";

import Log from "util/Log";
import timestamp from "util/timestamp";

const validator = require("email-validator");

const ERROR_NOT_FOUND = "ERROR_NOT_FOUND";
const ERROR_AUTH_BLOCKED = "ERROR_AUTH_BLOCKED";
const EMAIL_FORMAT_ERROR = "EMAIL_FORMAT_ERROR";

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return null;
}

class StartPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeForm: "login", // login or reset
      email: "",
      password: "",
      errorType: null,
      successMessage: null,
      loading: false
    };
  }

  componentDidMount() {
    const { search } = this.props.location;

    if (search) {
      // See if we have a client_id, which we need to auto-select.
      const email = getQueryVariable("email");
      if (email) {
        this.setState({
          email
        });
      }
    }
  }

  // Login or send reset email.
  async _doAction() {
    this.setState({
      loading: true,
      errorType: null
    });

    // Reset the auth failure flag so the user can retry.
    this.props.rootStore.authFail = false;

    const { email } = this.state;

    if (!email || email === "" || validator.validate(email) === false) {
      this.setState({
        loading: false,
        errorType: EMAIL_FORMAT_ERROR
      });
      return;
    }

    try {
      // Logout any previous user.
      firebase.auth().signOut();

      const result = await firebase
        .auth()
        .signInWithEmailAndPassword(email.toLowerCase(), "mealplan");
      console.log(result);
      new Audit("client").event("Login success");

      Event.send("login.success");

      console.log("Login", firebase.auth().currentUser.uid);

      // Update the login timestamp for the user.
      // Do this here so it's only set when the user click the "login" button.
      // Not every time they're authenticated.
      /*
      Disabled while migrating account.
      await new User(firebase.auth().currentUser.uid).update({
        last_login_at: timestamp()
      });
      */
    } catch (error) {
      let newState = {
        loading: false
      };

      console.log(error);

      if (error.code === "auth/user-not-found") {
        newState.errorType = ERROR_NOT_FOUND;
      } else if (error.code === "auth/wrong-password") {
        // Password has been changed from default.
        // So we use the legacy login form.
        window.location.href = "/login?email=" + encodeURIComponent(email);
        return;
      } else {
        // Another auth error. Probably blocked.
        newState.errorType = ERROR_AUTH_BLOCKED;
      }
      this.setState(newState);
      Event.send("login.fail", { error: error.code });
      Log(`Login error: ${email}`, error);
    }
  }

  render() {
    const { rootStore } = this.props;

    if (rootStore.appReady === false) {
      return null;
    }

    // Authenticated and there's no email set in the URL
    // or the email in the url matches the authenticated email.
    // This ensures that people who come to /start?email=EMAIL when the account is logged in as someone else -- allows them to authenticate under the new email.
    let query = queryString.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });

    if (query.refresh) {
      return <Redirect from="/start" to="/logout" />;
    }

    const email = getQueryVariable("email");

    if (
      (rootStore.authSuccess && !email) ||
      (rootStore.authSuccess && email === rootStore.authEmail)
    ) {
      return <Redirect from="/start" to="/" />;
    }

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col sm="6">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        this._doAction();
                      }}
                    >
                      {this.state.errorType ? (
                        <ErrorMessage
                          error={this.state.errorType}
                          email={this.state.email}
                        />
                      ) : null}
                      <h3>Access Your Account</h3>

                      <small>Email Address</small>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="email"
                          placeholder="Enter your email"
                          autoComplete="email"
                          value={this.state.email}
                          onChange={(e) => {
                            this.setState({
                              email: e.target.value
                            });
                          }}
                          style={{ fontSize: 18 }}
                        />
                      </InputGroup>
                      <center>
                        <LoadingButton
                          key={"loginButton"}
                          color="primary"
                          className="px-4"
                          onClick={this._doAction.bind(this)}
                          loading={this.state.loading}
                          type="submit"
                          size="lg"
                          block
                        >
                          Continue
                        </LoadingButton>
                      </center>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const ErrorMessage = (props) => {
  const { error, email } = props;

  let message = null;

  if (error === EMAIL_FORMAT_ERROR) {
    message = (
      <p>
        Your email is not formatted correctly. Please enter your email properly
        and try again.
      </p>
    );
  } else if (error === ERROR_NOT_FOUND) {
    message = (
      <span style={{ fontSize: 16 }}>
        <p className="mb-2">
          An account with that email address was not found. Try these steps:
        </p>
        <ol>
          <li>Check the spelling for any typos in your email.</li>
          <li>Is this the same email address you used to purchase?</li>
        </ol>
        <p>
          If you are sure that the email is correct and you used it to purchase,
          please{" "}
          <a href="https://www.ketocustomplan.com/contact-us">
            click here to contact support
          </a>{" "}
          for help.
        </p>
      </span>
    );
  } else if (error === ERROR_AUTH_BLOCKED) {
    message = (
      <>
        <p className="mb-2">The login has been blocked.</p>
        <p className="mb-2">
          Are you trying to access your account from work or school?
        </p>
        <p className="mb-2">
          Some workplaces block our system due to their high security settings.
        </p>
        <p className="mb-2">
          You can try and log in on your phone (disconnected from your work's
          network).
        </p>
        <p className="mb-2">
          You can also try and log in from your home computer.
        </p>
      </>
    );
  }

  return (
    <Alert color="warning">
      <h4 className="alert-heading">Login Error</h4>
      {message}
    </Alert>
  );
};

export default inject("rootStore")(observer(StartPage));
