import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import htmlToText from "html-to-text";

import OtherTickets from "./OtherTickets";

const NavHeader = (props) => {
  const [ticketSupportsHtml, setTicketSupportsHtml] = useState(null);

  const { page, zendeskClient } = props;

  useEffect(() => {
    const load = async () => {
      const supportsHTML =
        (await zendeskClient.get("ticket.via"))["ticket.via"].channel !==
        "fb_private_message";

      if (!supportsHTML) {
        setTicketSupportsHtml(false);
      }
    };
    load();

    return () => {};
  }, []);

  let userStyle = {};
  let userColStyle = {};
  let searchStyle = {};
  let searchColStyle = {};

  if (page === "notfound" || page === "customer") {
    userStyle = { fontWeight: "bold" };
    //userColStyle = { borderBottom: "3px solid #20a8d8" };
  } else if (page === "search") {
    searchStyle = { fontWeight: "bold" };
    searchColStyle = { borderBottom: "3px solid #20a8d8" };
  }

  return (
    <>
      {ticketSupportsHtml === false ? (
        <Row>
          <Col className="my-1">
            <center>
              <a
                href="#"
                onClick={async (e) => {
                  e.preventDefault();
                  const message = (await zendeskClient.get("comment.text"))[
                    "comment.text"
                  ];

                  // Zendesk adds "<p><br></p>" for line breaks. Don't want them.
                  let convertedMessage = message.replace(/<p><br><\/p>/g, "");
                  convertedMessage = convertedMessage.replace(/<br><br>/g, "");
                  console.log(convertedMessage);
                  convertedMessage = htmlToText.fromString(convertedMessage, {
                    hideLinkHrefIfSameAsText: true
                  });

                  zendeskClient.set("comment.text", convertedMessage);
                }}
              >
                Clean Macro Code
              </a>
            </center>
          </Col>
        </Row>
      ) : null}

      <Row className="px-2 mx-0 pt-2">
        <Col style={userColStyle} className="mb-1">
          <Link to="/zendesk" style={userStyle}>
            <span>
              <i className="fa fa-refresh" />
            </span>
          </Link>
        </Col>
        <Col style={userColStyle} className="mb-1">
          <Link
            to="/zendesk/resources"
            style={{ fontWeight: page === "resources" ? "bold" : null }}
          >
            Resources
          </Link>
        </Col>
        <Col style={searchColStyle}>
          <Link to="/zendesk/search" style={searchStyle}>
            Search
          </Link>
        </Col>
        {page !== "escalate" ? (
          <Col>
            <Link to="/zendesk/escalate" style={{ color: "red" }}>
              Escalate
            </Link>
          </Col>
        ) : null}
      </Row>
      <hr className="mt-0 mb-1" />
      <OtherTickets zendeskClient={zendeskClient} />
    </>
  );
};

export default NavHeader;
