import React, { useState, useEffect } from "react";

import { Card, CardBody, CardHeader, Button } from "reactstrap";
import { Loading } from "common";
import { ExclusionsSelector } from "components";
import User from "api/User";
import Backend from "util/Backend";

const ExclusionsCard = (props) => {
  const {
    accessKey,
    exclusions,
    onChange,
    hasPrevStep,
    onPrevStep,
    onNextStep
  } = props;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [partnerData, setPartnerData] = useState({});

  useEffect(() => {
    //const userDetails = User.getUserDetailsFromUserId(userId);

    const load = async () => {
      let response;
      try {
        response = await Backend.post("users/noauth_exclusions", {
          access_key: accessKey
        });
      } catch (e) {
        setError(true);
        setLoading(false);
        return;
      }
      setPartnerData(response.data.partner_exclusions);
      onChange([...response.data.exclusions, ...exclusions]);
      setLoading(false);
    };
    load();
  }, []);

  if (loading) {
    return (
      <Card>
        <CardBody>
          <center>
            <Loading />
          </center>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card>
      {hasPrevStep ? (
        <CardBody style={{ borderBottom: "1px solid #ccc" }}>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onPrevStep();
            }}
          >
            &lt; Back to profile
          </a>
        </CardBody>
      ) : null}
      <CardHeader>
        <span>Remove items</span>
      </CardHeader>
      <CardBody>
        <p>
          Click to remove the ingredients that you do not like (or don't have).
        </p>
        <br />
        <ExclusionsSelector
          onChange={onChange}
          exclusions={exclusions}
          strikethrough={true}
          disableHerbs={true}
          disableSpices={true}
          disabledExclusions={partnerData.disabled}
          hideDisabledExclusions={true}
          useIcon={true}
        />
        <br />
        <center>
          <Button color="primary" size="lg" onClick={onNextStep}>
            Save & Continue
          </Button>
        </center>
      </CardBody>
    </Card>
  );
};

export default ExclusionsCard;
