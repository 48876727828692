import React, { useState } from "react";
import { FirestoreCollection, Loading } from "common";
import { Row, Col } from "reactstrap";
import moment from "moment";
import Audit from "api/Audit";
import User from "api/User";

const MealPlanStatus = props => {
  const { user, partner, zendeskClient } = props;

  const userDetails = User.getUserDetailsFromUserId(user.id);

  return (
    <FirestoreCollection
      collection="meal_plans"
      filter={[
        ["uid", "==", userDetails.uid],
        ["partner_id", "==", partner.id],
        ["brand_id", "==", userDetails.brand_id]
      ]}
      orderBy="created_at:desc"
    >
      {({ isLoading, data }) => {
        if (isLoading) {
          return null;
        }

        if (!data) {
          // TODO: RENDER GETTING BY uid == user.id
          return "none";
        }

        data.sort((a, b) => (a.created_at > b.created_at ? 1 : -1));

        let planCount = 0;

        return (
          <>
            <small>Meal plans: {data.length < 1 ? "none" : ""}</small>
            <br />
            <ul style={{ margin: 0, padding: 0 }}>
              {data.map((mealPlan, idx) => {
                if (
                  mealPlan.status === "published" ||
                  mealPlan.status === "awaiting_publish"
                ) {
                  planCount++;
                }
                let planLink = `https://${props.partner.brand.domain_name}/client/meal_plans/${mealPlan.id}`;
                return (
                  <li key={idx}>
                    <small>
                      {mealPlan.status === "published" ||
                      mealPlan.status === "awaiting_publish" ? (
                        <span>Plan #{planCount} - </span>
                      ) : null}
                      {mealPlan.status}
                    </small>
                    <div style={{ marginTop: -5 }}>
                      <small>
                        <span style={{ paddingLeft: 20 }}>
                          {moment
                            .unix(mealPlan.created_at)
                            .format("MMM DD HH:mm")}
                        </span>{" "}
                        <a
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                            zendeskClient.invoke("instances.create", {
                              location: "modal",
                              url: planLink,
                              size: {
                                // optional
                                width: "80vw",
                                height: "80vh"
                              }
                            });
                          }}
                        >
                          view
                        </a>
                        {" | "}
                        <a href={planLink} target="_blank">
                          open
                        </a>
                      </small>
                    </div>
                  </li>
                );

                return (
                  <small>
                    {moment.unix(mealPlan.created_at).format("MMM DD YYYY")} -{" "}
                    {mealPlan.status}
                  </small>
                );
              })}
            </ul>
          </>
        );

        // Get an object that shows counts by status: { published: 2, in_progress: 1 }
        const obj = data.reduce((out, item) => {
          if (!out[item.status]) {
            out[item.status] = 0;
          }
          out[item.status]++;
          return out;
        }, {});

        return (
          <>
            <p className="mb-0">Meal plan summary:</p>
            {Object.entries(obj).map(([status, count], idx) => {
              return (
                <React.Fragment key={idx}>
                  <small className="pl-2">
                    {status}: {count}
                  </small>
                  <br />
                </React.Fragment>
              );
            })}
          </>
        );
        /*
        return data.map(({ created_at, status }) => {
          return (
            <>
              <small>Recent meal plan: {status}</small>
            </>
          );
        });
        */
      }}
    </FirestoreCollection>
  );
};

export default MealPlanStatus;
