/*
Render a child function with the current partner from the partnerStore.
This only works for partner or admin users
*/
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";

const CurrentUserPartner = inject("rootStore")(
  observer((props) => {
    const {
      rootStore: { partnerStore }
    } = props;
    const partner = toJS(partnerStore.partner);
    return props.children(partner);
  })
);
export default CurrentUserPartner;
