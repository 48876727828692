/**
 * {0: {...data}} => [{...data}]
 */
const toArray = (object) => {
  return Object.values(object);
};

/**
 * [{...data}] => {0: {...data}}
 */
const toObject = (array) => {
  const result = {};
  array.forEach((value, idx) => {
    result[idx] = value;
  });
  return result;
};

export default { toObject, toArray };
