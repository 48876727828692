import React from "react";
import { FormGroup, Input, Label } from "reactstrap";

const Checkbox = props => {
  const { id, checked, onChange, label } = props;

  return (
    <FormGroup check className="checkbox">
      <Input
        className="form-check-input"
        type="checkbox"
        id={id}
        name={id}
        checked={checked}
        onChange={e => {
          onChange(e.target.checked);
        }}
      />
      <Label check className={"form-check-label"} htmlFor={id}>
        {label}
      </Label>
    </FormGroup>
  );
};

export default Checkbox;
