import React from "react";

import { FirestoreCollection } from "common";

const ThreadList = (props) => {
  const { chatId, filter, limit, sort } = props;
  return (
    <FirestoreCollection
      collection={`chats/${chatId}/threads`}
      filter={filter}
      sort={sort}
      limit={limit}
    >
      {props.children}
    </FirestoreCollection>
  );
};

export default ThreadList;
