import React from "react";

import { FirestoreDocument } from "common";

const ChatDocument = (props) => {
  const { chatId } = props;

  return (
    <FirestoreDocument collection={`chats`} id={chatId}>
      {props.children}
    </FirestoreDocument>
  );
};

export default ChatDocument;
