import Backend from "util/Backend";
import Firestore from "util/Firestore";

import AlgoliaIndex from "api/AlgoliaIndex";

import { CONFIGMAP } from "partnerConfig";

import * as EmailValidator from "email-validator";

import Audit from "api/Audit";

import timestamp from "util/timestamp";

const randomstring = require("randomstring");

class User {
  constructor(userId) {
    this.userId = userId;
  }

  static getId({ uid, partner_id, brand_id }) {
    return `${uid}-${partner_id}-${brand_id}`;
  }

  /*
  Return details about the partner and brand for the given domain.
  */
  static getPartnerBrandDetailsFromDomain() {
    return CONFIGMAP[window.location.host];
  }

  /**
  Given a userId return {uid, partner_id, brand_id, and user_id}
  This handles new and old user_id formats.
  */
  static getUserDetailsFromUserId(userId) {
    // uid-partner-brand
    const splitPieces = userId.split("-");
    if (splitPieces.length < 2) {
      // Old userId.
      return {
        uid: splitPieces[0],
        partner_id: "7ilnrGcpcDBy8ps7gvwm",
        brand_id: "KCP",
        user_id: userId
      };
    }
    return {
      uid: splitPieces[0],
      partner_id: splitPieces[1],
      brand_id: splitPieces[2],
      user_id: userId
    };
  }

  static async create(doc) {
    const result = await Backend.post("users", doc);
    if (result.success) {
      return result.data.user_id;
    }
    throw result;
  }

  get() {
    return this.getDocumentReference().get();
  }

  /*
  getDocumentId() {
    if (this.partner_id && this.brand_id) {
      return `${this.uid}-${this.partner_id}-${this.brand_id}`;
    }
    return this.uid;
  }
  */

  /**
  See if a document exists for this specific partner brand.
  Only works for the new way of dealing with users (partner/brand id's)
  */
  /*
  async documentExists() {
    const result = await Backend.post(`users/documentExists`, {
      user_id: this.userId
    });
    if (result.success) {
      return result.data.exists;
    }
    throw "Error";
  }
  */

  /**
  Run this when users login to migrate their document.
  This adds a uid field to the user document so security rules work properly.
  */
  migrateUID() {
    return Backend.post(`users/migrateUID`, {
      user_id: this.userId
    });
  }

  static colRef() {
    return Firestore.db.collection("users");
  }

  getDocumentReference() {
    return User.colRef().doc(this.userId);
  }

  async update(doc) {
    await this.getDocumentReference().update(doc);

    try {
      await AlgoliaIndex.updateObject("users", this.userId);
    } catch (e) {}
  }

  /*
  async changePassword(newPassword) {
    await firebase.auth().currentUser.updatePassword(newPassword);
    await this.update({
      password_has_changed: true
    });
  }
  */

  async acceptDisclaimer() {
    new Audit("client", this.userId).event("Accepted disclaimer");

    return this.update({
      accepted_disclaimer: {
        is_accepted: true,
        accepted_at: timestamp()
      },
      needs_get_started_reminder: false
    });
  }

  async changeEmail(newEmail) {
    if (EmailValidator.validate(newEmail) === false) {
      throw new Error({
        error: true,
        code: "INVALID_EMAIL",
        message: "Invalid email address"
      });
    }

    // Update the email server side using firebase admin because we don't want a verification email sent.
    // Important: this must be done before changing the email in the user document.
    const response = await Backend.post(`users/${this.userId}/email`, {
      email: newEmail
    });

    if (response.error) {
      throw new Error({ error: true, message: response.message });
    }

    await this.update({
      email: newEmail
    });

    return response;
    //return firebase.auth().currentUser.updateEmail(newEmail);
  }

  deleteAccount() {
    return Backend.post(`users/${this.userId}/delete`);
  }

  // Reset the user's password to the default password.
  async resetPasswordToDefault() {
    const response = await Backend.get(`account/reset_password_to_default`, {
      user_id: this.userId
    });
    if (response.error) {
      throw new Error(response);
    }
    return response;
  }

  updateNoAuthAccessKey() {
    return this.update({
      noauth_access_key: randomstring.generate(12)
    });
  }

  /*
  // Information to login
  async sendLoginInfoEmail() {
    const response = await Backend.get(`account/send_account_information`, {
      uid: this.id
    });
    if (response.error) {
      throw { error: true, message: response.message };
    }
    return response;
  }

  // Invite to log into account
  async sendAccountInviteEmail() {
    const response = await Backend.get(`account/send_account_invite`, {
      uid: this.id
    });
    if (response.error) {
      throw { error: true, message: response.message };
    }
    return response;
  }

  // Send the password reset email for a specific client.
  async sendPasswordResetEmail() {
    const response = await Backend.get(`account/send_reset_password`, {
      uid: this.id
    });
    if (response.error) {
      throw { error: true, message: response.message };
    }
    return response;
  }



  // Send password reset email for a non logged in user.
  static async sendPasswordResetEmailForEmail(email) {
    const response = await Backend.get(`account/send_reset_password`, {
      email
    });
    if (response.error) {
      throw { error: true, message: response.message };
    }
    return response;
  }
  */
}

export default User;
