import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Alert
} from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import { inject, observer } from "mobx-react";

import { LoadingButton } from "common";
import queryString from "qs";
import Backend from "util/Backend";

import firebase from "@firebase/app";
import "@firebase/firestore";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password_one: "",
      password_two: "",
      errorMessage: null,
      successMessage: null,
      loading: false
    };
  }

  // Save the new password.
  async _savePassword() {
    this.setState({
      errorMessage: null,
      successMessage: null,
      loading: true
    });

    const { search } = this.props.location;

    if (!search) {
      this.setState({
        errorMessage: "Invalid",
        loading: false
      });
      return false;
    }

    // See if we have a key, which we need to reset the password.
    let query = queryString.parse(search, { ignoreQueryPrefix: true });
    if (!query.key) {
      this.setState({
        errorMessage: "Invalid",
        loading: false
      });
      return false;
    }

    if (this.state.password_one.length < 6) {
      this.setState({
        errorMessage: "Passwords must be longer than 6 characters",
        loading: false
      });
      return false;
    }

    if (this.state.password_one !== this.state.password_two) {
      this.setState({
        errorMessage: "Passwords don't match",
        loading: false
      });
      return false;
    }

    try {
      const response = await Backend.post("account/reset_password", {
        key: query.key,
        password_one: this.state.password_one,
        password_two: this.state.password_two
      });
      if (response.error) {
        throw response.error;
      }
    } catch (e) {
      this.setState({
        errorMessage: "Error updating password.",
        loading: false
      });
      return false;
    }

    this.setState({
      successMessage: (
        <span>
          Password updated! <Link to="/login">Log in here</Link>
        </span>
      ),
      loading: false
    });
    return true;
  }

  render() {
    const { rootStore } = this.props;

    if (rootStore.appReady === false) {
      return null;
    }

    if (rootStore.authSuccess) {
      // Resetting a password while logged in, redirect to account.
      return <Redirect to="/account" />;
    }

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col sm="6">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form
                      onSubmit={e => {
                        e.preventDefault();
                        this._savePassword();
                      }}
                    >
                      <h1>Change password</h1>
                      <p className="text-muted my-3">
                        Enter your new password.
                      </p>

                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="password"
                          placeholder="Password"
                          autoComplete="current-password"
                          value={this.state.password_one}
                          onChange={e => {
                            this.setState({
                              password_one: e.target.value
                            });
                          }}
                        />
                      </InputGroup>

                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="password"
                          placeholder="Password (again)"
                          autoComplete="current-password"
                          value={this.state.password_two}
                          onChange={e => {
                            this.setState({
                              password_two: e.target.value
                            });
                          }}
                        />
                      </InputGroup>

                      {!this.state.successMessage ? (
                        <Row>
                          <Col xs={6}>
                            <LoadingButton
                              color="primary"
                              className="px-4"
                              onClick={this._savePassword.bind(this)}
                              loading={this.state.loading}
                              type="submit"
                            >
                              Submit
                            </LoadingButton>
                          </Col>
                        </Row>
                      ) : null}
                      {this.state.errorMessage ? (
                        <Row className="mt-3">
                          <Col>
                            <Alert color="danger" className="mb-0">
                              {this.state.errorMessage}
                            </Alert>
                          </Col>
                        </Row>
                      ) : null}
                      {this.state.successMessage ? (
                        <Row className="mt-3">
                          <Col>
                            <Alert color="success" className="mb-0">
                              {this.state.successMessage}
                            </Alert>
                          </Col>
                        </Row>
                      ) : null}
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default inject("rootStore")(observer(ResetPassword));
