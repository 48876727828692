/**
* Record an audit event

type - string - user | meal_plan | recipe | ingredient - the type of audit.
id - string - firestore id to link this event to.

Automatically records the user who is responsible for this event.

await new Audit("client", firestore_id).event("event title", {
  relevant_data: true
});

*/

import Firestore from "util/Firestore";
import Backend from "util/Backend";
import timestamp from "util/timestamp";

const { detect } = require("detect-browser");
const browser = detect();

class Audit {
  constructor(type, id) {
    this.type = type;
    this.id = id;
  }

  async event(message, data) {
    const col = Firestore.db.collection("audit");

    const doc = {
      type: this.type,
      doc_id: this.id || window.user_id || "",
      user_id: window.user_id || "",
      created_at: timestamp(),
      message,
      data: {
        ...data,
        _browser: {
          name: browser.name,
          version: browser.version,
          os: browser.os
        }
      }
    };

    console.log("EVENT", doc);

    const docRef = await col.add(doc);
    const docId = docRef.id;

    await Backend.get("audit_ip", {
      id: docId
    });

    return docId;
  }
}

export default Audit;
