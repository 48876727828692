import React from "react";

const FormError = props => {
  let style = {};

  if (props.visible) {
    style = { display: "block" };
  }

  return (
    <div className="invalid-feedback" style={style}>
      {props.children}
    </div>
  );
};
export default FormError;
