class Image {
  constructor(gsImage) {
    this.gsImage = gsImage;
  }

  getResizeableURL(options) {
    let opts = [];
    Object.entries(options).forEach(([k, v]) => {
      opts.push(`${k}=${v}`);
    });

    return `https://mealplanner.io/cdn-cgi/image/${opts.join(
      ","
    )}/https://storage.googleapis.com/mealplan-platform2.appspot.com/${
      this.gsImage
    }`;
  }
}

export default Image;
