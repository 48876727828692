import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
// import { renderRoutes } from 'react-router-config';
import Loadable from "react-loadable";
import "./App.scss";

import Login from "views/pages/Login";
import Logout from "views/pages/Logout";
import Error from "views/pages/Error";
import ResetPassword from "views/pages/ResetPassword";
import Zendesk from "views/pages/Zendesk";
import PrintMealPlan from "views/client/mealplans/PrintHtml";
import PrintGroceryList from "views/client/mealplans/GroceryList";
import PrintWorkoutPlan from "views/client/workout_plans/PrintView";
import StartSimple from "views/pages/Start";
import NoAuthOnboarding from "views/pages/NoAuthOnboarding";

import ErrorBoundary from "./ErrorBoundary";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { Provider } from "mobx-react";
import RootStore from "./stores";

// Initialize Firebase
var config = {
  apiKey: "AIzaSyCjRoeUGmjxe8f_jN5AxxQexQ1bvu88tvc",
  authDomain: "mealplan-platform2.firebaseapp.com",
  databaseURL: "https://mealplan-platform2.firebaseio.com",
  projectId: "mealplan-platform2",
  storageBucket: "mealplan-platform2.appspot.com",
  messagingSenderId: "529923240531"
};
firebase.initializeApp(config);

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = Loadable({
  loader: () => import("./containers/DefaultLayout"),
  loading
});

class App extends Component {
  render() {
    return (
      <ErrorBoundary>
        <Provider rootStore={new RootStore()}>
          <BrowserRouter>
            <Switch>
              {/*<Route exact path="/login" name="Login Page" component={Login} />
            <Route exact path="/register" name="Register Page" component={Register} />
            <Route exact path="/404" name="Page 404" component={Page404} />
            <Route exact path="/500" name="Page 500" component={Page500} />*/}
              <Route path="/zendesk" name="Zendesk" component={Zendesk} />
              <Route path="/start" name="start" component={StartSimple} />
              <Route exact path="/login" name="Login" component={Login} />
              <Route exact path="/logout" name="Login" component={Logout} />
              <Route exact path="/error" name="error" component={Error} />
              <Route
                exact
                path="/reset_password"
                name="Reset Password"
                component={ResetPassword}
              />
              <Route
                exact
                path="/client/meal_plans/:id/print"
                name="Print Meal Plan"
                component={PrintMealPlan}
              />
              <Route
                exact
                path="/client/meal_plans/:id/groceries"
                name="Print Grocery List"
                component={PrintGroceryList}
              />
              <Route
                exact
                path="/client/workout_plans/:id/print"
                name="Print Workout Plan"
                component={PrintWorkoutPlan}
              />
              <Route
                exact
                path="/get-started"
                name="Onboarding"
                component={NoAuthOnboarding}
              />
              <Route path="/" name="Home" component={DefaultLayout} />
            </Switch>
          </BrowserRouter>
        </Provider>
      </ErrorBoundary>
    );
  }
}

export default App;
