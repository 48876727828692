import React, { useState, useEffect } from "react";
import { FormGroup, Label, Input, Alert, Button } from "reactstrap";
import axios from "axios";
import { CurrentPartnerUser } from "common";
import NavHeader from "views/pages/Zendesk/NavHeader";

import Backend from "util/Backend";
import timestamp from "util/timestamp";

const ESCALATE_MACRO_ID = 360153251511;

// basecamp
const PROJECT_ID = 15644965;
const TODO_LIST_IDS = {
  new: 2721182824,
  recipe: 2721183389,
  workout: 2721184313,
  gdpr: 2927258395,
  bizdev: 3193240869
};

const DAVID_ID = 28719440;
const SHAUNA_ID = 28734079;
const TOLU_ID = 30474945;

// Escalate to roni.
const RECIPE_ESCALATE_USER_ID = 30532143;

const TODO_ID_CUSTOM_FIELD_ID = 360032139692;

const ZendeskEscalation = (props) => {
  const [selectedRB, setSelectedRB] = useState(null);
  const [extraInfo, setExtraInfo] = useState("");
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const [error, setError] = useState(null);

  const { zendeskClient } = props;

  const submitEscalation = (e) => {
    e.preventDefault();
    if (selectedRB === null) {
      setError("You must select a reason");
      return;
    }

    const submit = async () => {
      setLoading(true);

      const ticketId = (await zendeskClient.get("ticket")).ticket.id;

      if (!ticketId) {
        setLoading(false);
        setError(
          "Error: Ticket ID not available. You can only escalate tickets that already exist."
        );
        return;
      }

      const todoData = {
        project_id: PROJECT_ID,
        list_id: TODO_LIST_IDS.new,
        content: `Ticket #${ticketId} - ${selectedRB}`, //todo title
        description: `Ticket: https://incx.zendesk.com/agent/tickets/${ticketId}

${extraInfo}
`
      };

      if (selectedRB === "recipe_problem") {
        todoData.assignee_ids = [RECIPE_ESCALATE_USER_ID];
        todoData.notify = true;
        todoData.list_id = TODO_LIST_IDS.recipe;
      }

      if (selectedRB === "exercise_problem") {
        // Assign to shauna
        todoData.assignee_ids = [SHAUNA_ID];
        todoData.notify = true;
        todoData.list_id = TODO_LIST_IDS.workout;
      }

      if (selectedRB === "gdpr") {
        todoData.assignee_ids = [TOLU_ID];
        todoData.notify = true;
        todoData.list_id = TODO_LIST_IDS.gdpr;
      }

      if (selectedRB === "bizdev") {
        todoData.assignee_ids = [DAVID_ID];
        todoData.notify = true;
        todoData.list_id = TODO_LIST_IDS.bizdev;
      }

      try {
        const result = await Backend.post("basecamp/create_todo", todoData);
        if (result.error) {
          throw result.message;
        }
        // Set the todo id in the custom field id.
        zendeskClient.set(
          `ticket.customField:custom_field_${TODO_ID_CUSTOM_FIELD_ID}`,
          result.data.id
        );
      } catch (e) {
        setLoading(false);
        setError("Error:", e);
        return;
      }
      zendeskClient.invoke("macro", ESCALATE_MACRO_ID);
      setLoading(false);
      setComplete(true);
    };
    submit();
  };

  if (complete) {
    return (
      <>
        <NavHeader zendeskClient={zendeskClient} page="escalate" />
        <span>Ticket escalated!</span>
      </>
    );
  }

  return (
    <>
      <NavHeader zendeskClient={zendeskClient} page="escalate" />
      <CurrentPartnerUser>
        {({ isLoading, data, error }) => {
          if (isLoading) {
            return <span>Loading...</span>;
          }
          if (error) {
            return <span>{error}</span>;
          }

          if (
            !data.basecamp ||
            !data.basecamp.oauth_token ||
            data.basecamp.expires_at < timestamp()
          ) {
            return (
              <>
                <span>
                  You need to authenticate basecamp before you can escalate.
                </span>
                <br />
                <a
                  href="https://myplan.ketocustomplan.com/manage/home"
                  target="_blank"
                >
                  Start Now
                </a>
              </>
            );
          }

          return (
            <>
              <FormGroup tag="fieldset">
                <small>Select reason for escalation</small>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="need_help"
                      checked={selectedRB === "need_help"}
                      onChange={(e) => {
                        setSelectedRB("need_help");
                      }}
                    />{" "}
                    I dont know what to do, help me
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="something_weird"
                      checked={selectedRB === "something_weird"}
                      onChange={(e) => {
                        setSelectedRB("something_weird");
                      }}
                    />{" "}
                    Something seems weird, needs second look
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="billing"
                      checked={selectedRB === "billing"}
                      onChange={(e) => {
                        setSelectedRB("billing");
                      }}
                    />{" "}
                    Billing: complicated issue
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="recipe_problem"
                      checked={selectedRB === "recipe_problem"}
                      onChange={(e) => {
                        setSelectedRB("recipe_problem");
                      }}
                    />{" "}
                    Recipe/Ingredient Problem
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="exercise_problem"
                      checked={selectedRB === "exercise_problem"}
                      onChange={(e) => {
                        setSelectedRB("exercise_problem");
                      }}
                    />{" "}
                    Exercise Problem
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="gdpr"
                      checked={selectedRB === "gdpr"}
                      onChange={(e) => {
                        setSelectedRB("gdpr");
                      }}
                    />{" "}
                    GDPR, Data, or Legal
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="bizdev"
                      checked={selectedRB === "bizdev"}
                      onChange={(e) => {
                        setSelectedRB("bizdev");
                      }}
                    />{" "}
                    Bizdev, Partnerships
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="other"
                      checked={selectedRB === "other"}
                      onChange={(e) => {
                        setSelectedRB("other");
                      }}
                    />{" "}
                    Other
                  </Label>
                </FormGroup>
              </FormGroup>
              <FormGroup>
                {selectedRB === "recipe_problem" ? (
                  <Alert color="info" style={{ padding: 0, marginBottom: 0 }}>
                    <small>
                      Verify the problem, and detail which week, day and recipe
                      there are issues with.
                    </small>
                  </Alert>
                ) : null}
                {selectedRB === "exercise_problem" ? (
                  <Alert color="info" style={{ padding: 0, marginBottom: 0 }}>
                    <small>
                      Explain which exercise there is an issue with.
                    </small>
                  </Alert>
                ) : null}
                <Input
                  type="textarea"
                  name="text"
                  id="exampleText"
                  placeholder="Add additional info here"
                  value={extraInfo}
                  onChange={(e) => {
                    setExtraInfo(e.target.value);
                  }}
                />
              </FormGroup>
              {error ? (
                <Alert color="danger" style={{ padding: 0 }}>
                  <small>{error}</small>
                </Alert>
              ) : null}
              {loading ? (
                <span>Loading...</span>
              ) : (
                <Button color="primary" onClick={submitEscalation} block>
                  SUBMIT
                </Button>
              )}
            </>
          );
        }}
      </CurrentPartnerUser>
    </>
  );
};

export default ZendeskEscalation;
