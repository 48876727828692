/*
Render the InstantSearch component from Algolia.

Props:
index - string - the index to search on.

*/
import React from "react";

import { InstantSearch } from "react-instantsearch-dom";

import SearchClient from "./SearchClient";

import * as css from "instantsearch.css/themes/algolia-min.css";

// prevent css from being removed via treeshaking optimization
if (css) {
}

const Search = props => {
  return (
    <SearchClient indexName={props.index}>
      {({ __searchClient, __indexName }) => {
        return (
          <InstantSearch
            {...props}
            searchClient={__searchClient}
            indexName={__indexName}
          >
            {props.children}
          </InstantSearch>
        );
      }}
    </SearchClient>
  );
};
export default Search;
