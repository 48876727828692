import React from "react";

import { Card, CardBody, CardHeader, Button } from "reactstrap";

import AccessLinkWarningAlert from "./AccessLinkWarningAlert";

const ExclusionsUpdateCard = (props) => {
  const { email, onNextStep } = props;

  return (
    <Card>
      <AccessLinkWarningAlert email={email} />
      <CardHeader>
        <span>Confirm your preferences</span>
      </CardHeader>
      <CardBody>
        <p>
          To make sure that everything is 100% personalized for your food
          preferences, we need to know which ingredients to remove. This will
          take 2-3 minutes.
        </p>
        <br />
        <center>
          <Button size="lg" color="primary" onClick={onNextStep}>
            Get Started
          </Button>
        </center>
      </CardBody>
    </Card>
  );
};

export default ExclusionsUpdateCard;
