/**

Page to print. Because this is not loaded as a child of containers/DefaultLayout, it needs to make sure the app is ready for auth purposes.

This lets it open in a new tab and work properly.

*/
import React, { Component } from "react";
import { Container, Card, CardBody } from "reactstrap";
import { inject, observer } from "mobx-react";
import { Template, FirestoreDocument, Loading } from "common";

const PrintViewPage = inject("rootStore")(
  observer(props => {
    const { rootStore } = props;
    const { userStore } = rootStore;

    if (rootStore.appReady === false) {
      return null;
    }

    return <PrintView {...props} />;
  })
);

class PrintView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { id } = this.props.match.params;

    return (
      <div className="app flex-row">
        <Container>
          <Template.PageBody>
            <Card style={{ border: 0 }}>
              <CardBody>
                <FirestoreDocument collection="workout_plans" id={id}>
                  {({ isLoading, data }) => {
                    if (isLoading) {
                      return <Loading />;
                    }

                    return data.program.map(
                      ({ type, exercise_id, seconds }, idx) => {
                        if (type === "rest") {
                          return (
                            <React.Fragment key={idx}>
                              <h1 key={idx}>Rest</h1>
                              <h2>{seconds} seconds</h2>
                              <br />
                              <br />
                            </React.Fragment>
                          );
                        }
                        return (
                          <FirestoreDocument
                            key={idx}
                            collection="data_exercises"
                            id={exercise_id}
                          >
                            {({ isLoading, data }) => {
                              if (isLoading) {
                                return <Loading />;
                              }
                              if (!data) {
                                return null;
                              }
                              return (
                                <>
                                  <h1>{data.name}</h1>
                                  <h2>{seconds} seconds</h2>
                                  <strong>Directions</strong>
                                  <ul>
                                    {data.steps.map(({ text }, idx) => {
                                      return <li key={idx}>{text}</li>;
                                    })}
                                  </ul>
                                  <br />
                                  <br />
                                </>
                              );
                            }}
                          </FirestoreDocument>
                        );
                      }
                    );
                  }}
                </FirestoreDocument>
              </CardBody>
            </Card>
          </Template.PageBody>
        </Container>
      </div>
    );
  }
}

export default PrintView;
