import React from "react";

const KEYS = {
  ENTER: 13,
  ESCAPE: 27,
  ARROW_UP: 38,
  ARROW_DOWN: 40,
  SPACEBAR: 32
};

class KeyDetector extends React.Component {
  componentDidMount() {
    document.addEventListener("keydown", this._handleKeyDown);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this._handleKeyDown);
  }

  _handleKeyDown = (event) => {
    if (this.props.isDisabled) {
      return;
    }
    if (event.keyCode === this.props.useKey) {
      this.props.onPress(event);
    }
  };

  render() {
    return null;
  }
}
const exportable = {
  Component: KeyDetector,
  ...KEYS
};

export default exportable;
