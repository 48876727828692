/**
 Display a video from GS.

Props:
gsPath - string - path in gcp storage.

 In development, files are added a prefix of DEV-
*/
import React from "react";
import { DEV } from "config";

class GSVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = { url: null };
  }

  componentDidMount() {
    this._load();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.gsPath !== this.props.gsPath) {
      this._load();
    }

    if (prevProps.paused !== this.props.paused) {
      if (this.props.paused) {
        // Pause.
        this.refs.videoRef.pause();
      } else {
        // Resume.
        this.refs.videoRef.play();
      }
    }
  }

  async _load() {
    if (!this.props.gsPath) {
      return;
    }

    let devPrefix = "";

    if (DEV) {
      // Add the prefix.
      devPrefix = "DEV-";
    }

    try {
      /*const url = await firebase
        .storage()
        .refFromURL(
          `gs://mealplan-platform2.appspot.com/${devPrefix}${this.props.src}`
        )
        .getDownloadURL();
        */
      this.setState({
        url: `https://storage.googleapis.com/mealplan-platform2.appspot.com/${devPrefix}${this.props.gsPath}`
      });
    } catch (e) {
      console.error(e);
      this.setState({
        url: null
      });
    }
  }

  render() {
    const { url } = this.state;

    const addProps = { ...this.props };

    delete addProps.emptyBlock;
    delete addProps.placeholder;
    delete addProps.gsPath;
    delete addProps.paused;

    if (!url) {
      if (this.props.emptyBlock && this.props.width) {
        return <div style={{ width: Number(this.props.width) }} />;
      }
      if (this.props.placeholder) {
        return <img {...addProps} src={this.props.placeholder} alt="" />;
      }
      return null;
    }

    return (
      <video ref="videoRef" {...addProps}>
        <source src={url} type="video/mp4" />
      </video>
    );
  }
}

export default GSVideo;
