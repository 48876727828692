import React from "react";

import { CurrentUser } from "common";

import MessageInputContext from "./MessageInputContext";

const PartnerUserControls = (props) => {
  //const { messageSettings, setMessageSettings } = props;

  return (
    <MessageInputContext.Consumer>
      {(messageInputContext) => {
        return (
          <CurrentUser>
            {(user) => {
              if (user.account_type !== "partner") {
                return null;
              }
              return (
                <>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      messageInputContext.update({
                        is_internal: !messageInputContext.value.is_internal
                      });
                      /*
                setMessageSettings({
                  is_internal: !messageSettings.is_internal
                });
                */
                    }}
                  >
                    Send{" "}
                    {messageInputContext.value.is_internal
                      ? "public"
                      : "internal"}{" "}
                    message
                  </a>
                </>
              );
            }}
          </CurrentUser>
        );
      }}
    </MessageInputContext.Consumer>
  );
};

export default PartnerUserControls;
