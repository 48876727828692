import React, { useState } from "react";

import { CurrentUser } from "common";

import Bubble from "./Bubble";
import MessageBubbleStaffModal from "./MessageBubbleStaffModal";

const MessageBubble = (props) => {
  const {
    chatId,
    threadId,
    clientHasViewed,
    currentUserIsStaff,
    showAsCurrentUser,
    message
  } = props;

  const {
    id,
    reply_to_id,
    user_id,
    text,
    is_internal,
    created_at,
    metadata,
    user,
    prev_message_user_is_different
  } = message;

  const [staffModalOpen, setStaffModalOpen] = useState(false);

  if (!text || text === "") {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: showAsCurrentUser ? "end" : "start"
      }}
    >
      <Bubble
        message={message}
        showAsCurrentUser={showAsCurrentUser}
        currentUserIsStaff={currentUserIsStaff}
        clientHasViewed={clientHasViewed}
        onClick={(e) => {
          e.preventDefault();
          if (!showAsCurrentUser) {
            // Clicking someone elses message.
            // Thats a reply.
            console.log("Reply!");
            return;
          }
          if (!currentUserIsStaff) {
            return;
          }
          // Staff user clicking a staff message.
          setStaffModalOpen(true);
        }}
      />
      {staffModalOpen ? (
        <MessageBubbleStaffModal
          chatId={chatId}
          threadId={threadId}
          message={message}
          onClose={() => {
            setStaffModalOpen(false);
          }}
          onSave={() => {
            setStaffModalOpen(false);
          }}
        />
      ) : null}
    </div>
  );
};

export default MessageBubble;
