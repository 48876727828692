import React from "react";

import MessageInputContext from "./MessageInputContext";

class MessageInputContextProvider extends React.Component {
  constructor(props) {
    super(props);

    this.update = (value) => {
      this.setState((state) => {
        return { value: { ...state.value, ...value } };
      });
    };

    this.state = {
      value: {},
      update: this.update
    };
  }

  render() {
    return (
      <MessageInputContext.Provider value={this.state}>
        {this.props.children}
      </MessageInputContext.Provider>
    );
  }
}

export default MessageInputContextProvider;
